import React from "react";
import { Link } from "react-router-dom";


const CallPopup = ({props}) => {
  return (
    <>
      <div className="call_us-container">
        <div className="content-box">
          <h3>Call us for unpublished deals</h3>
          <p>Phone-only fare may save you more</p>
          <p>Call our travel experts at:</p>
          <div className="phone d-flex">
            <img className="call-icon" src={'/assets/ficon/call-icon.svg'} alt="call-icon" />
            <a href={`tel:${props.phoneNo}`} className="call-us">{props.phoneNo}</a>
          </div>
        </div>
        <div className="call-center-girl">
          <img className="girl-image" src={'/assets/ficon/call-center-girl.png'} ailt="girl-img"/>
        </div>
      </div>
    </>
    );
};
export default CallPopup;