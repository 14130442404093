import React, { memo } from "react";
import { getCurrency } from "../../../../Utils/utils";
const HelpSection = ({ contact_phone }) => {
  return (
    <>
      <div className="custom-side help-section">
        <p>Need Help?</p>
        <div className="help-section-text">
          Our team of professional travel experts are ready to help.
        </div>
        <div className="help-section-text">Call us on:</div>
        <div className="phone d-flex _pb-15">
          <img
            className="phone-icon"
            src={"/assets/ficon/call-icon.svg"}
            alt="call-icon"
          />
          <a href={`tel:${contact_phone}`} className="help-number-text">
            {contact_phone}
          </a>
        </div>
      </div>
      <div className="custom-side help-section">
        <p>Why book with us?</p>
        <div className="row">
          <div className="col-md-6">
            <div className="book_with_us">
              <img
                src={"/assets/ficon/Union 1.png"}
                alt="no-fee"
                className="book-with-us-icon"
              />
              <div>Best Price Guaranteed</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="book_with_us">
              <img
                src={"/assets/ficon/images/atol.png"}
                alt="no-fee"
                className="book-with-us-icon"
              />
              <div>ATOL Protected</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="book_with_us">
              <img
                src={"/assets/ficon/images/refer-and-earn.png"}
                alt="no-fee"
                className="book-with-us-icon"
              />
              <div>Refer & Earn Unlimited</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="book_with_us">
              <img
                src={"/assets/ficon/Union 2.png"}
                alt="no-fee"
                className="book-with-us-icon"
              />
              <div>Discounted Fares</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="book_with_us">
              <img
                src={"/assets/ficon/images/happy-face.png"}
                alt="no-fee"
                className="book-with-us-icon"
              />
              <div>90% Happy Repeat Clients</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="book_with_us">
              <img
                src={"/assets/ficon/images/Book-img.png"}
                alt="no-fee"
                className="book-with-us-icon"
              />
              <div>Book with {getCurrency() === 'USD' ? '$' : '£'}50 per person</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(HelpSection);
