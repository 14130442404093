import React from 'react'
import { getAirlineName } from '../../../../Helper/helper'
import { showCapitlized } from '../helper'
import Baggage from './Baggage'
import ArrowTooltips from '../../../../Shared/Tooltip'

const AirportDetails = ({
  airLineName,
  gifCode,
  carrierCode,
  flightNumber,
  cabin,
  bagge,
  operatingCode,
  getOperatingAirlineName,
  requiredWard,
  addBaggageExists
}) => {
  return (
    <div className='airport-details-mobile'>
      <div style={{ display: "flex", alignItems: operatingCode ? "center" : "" }}>
        <div className="inner_logo_block">
          <div className="inner_logo_div">
            <ArrowTooltips
              text={getAirlineName(airLineName)}
            >
              <img
                src={`/assets/img/logos/${gifCode}.gif`}
                className="inner_logo"
                alt="outbound"
              />
            </ArrowTooltips>
          </div>
          {requiredWard && 
            <div
              className="airport theme-search-result-airport-name airline-airport-details"
              style={{ paddingTop: "3px" }}
            >
              {carrierCode} {flightNumber}
          </div>}
        </div>
        <div className="airport theme-search-result-airport-name airline-airport-details operating-code-and-cabin">
          {operatingCode !== "" ? (
            <p className="fit-content">
              Operated by{" "}
              {getOperatingAirlineName(
                operatingCode
              )}
            </p>
          ) : (
            <></>
          )}
          {showCapitlized(cabin)}
        </div>
      </div>
      <div>
        <Baggage bagge={addBaggageExists ? 1 : bagge} />
      </div>
    </div>
  )
}

export default AirportDetails