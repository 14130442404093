import React from "react";


const staticPopup = (props) => {
    return (
    <>
        <div className="static-popup bg-grad">
            <div className="static-label">
                UK Based
            </div>
            <div className="static-popup-content">
                {/* <i className="fa fa-phone offer-call-icon" /> */}
                <span className="save-text">{props.title}</span>
                {/* <a href={`tel:${props.contactNo}`} className="call-no-text">{props.contactNo}</a> */}
            </div>
        </div>
    </>
    );
};
export default staticPopup;