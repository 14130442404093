import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BannerUtils } from "../../Service/UtilsService";
import UserService from "../../Service/UserService";
import {notification} from "../../Shared/NotificationModal";

const ForgotPassword = (props) => {
  const userServ = new UserService();
  const [banner, setbanner] = useState({});
  const [email, setEmail] = useState({ email: "" });
  useEffect(() => {
    async function fetchBanner() {
      let result = await BannerUtils("forgotpassword");
      setbanner(result);
    }
    fetchBanner();
  }, []);

  const handleSubmit = async () => {
    try {
      const result = await userServ.forget_password(email);
      if (result) {
        notification("success",result.message);
      }
      props.history.push("/");
    } catch (err) {
      notification("error",err);
    }
  };
  return (
    <div class="theme-hero-area">
      <div class="theme-hero-area-bg-wrap">
        <div class="theme-hero-area-bg" style={{ backgroundImage: `url(${banner.bannerimage})` }}></div>
        <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
      </div>
      <div class="theme-hero-area-body">
        <div class="theme-page-section _pt-100 theme-page-section-xl">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-md-offset-4">
                <div class="theme-login theme-login-white">
                  <div class="theme-login-header">
                    <h1 class="theme-login-title">{banner.title}</h1>
                    <p class="theme-login-subtitle">{banner.subtitle}</p>
                  </div>
                  <div class="theme-login-box">
                    <div class="theme-login-box-inner">
                      <p class="theme-login-pwd-reset-text">
                        Enter the email associated with your account in the field below and we'll email you a link to
                        reset your password.
                      </p>
                      <div class="form-group theme-login-form-group">
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Email Address"
                          onChange={(e) => setEmail({ email: e.target.value })}
                        />
                      </div>
                      <button onClick={handleSubmit} class="btn btn-uc btn-dark btn-block btn-lg">
                        Reset Password
                      </button>
                      <Link class="theme-login-pwd-reset-back" to="/login">
                        {" "}
                        Remember the password?
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
