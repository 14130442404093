import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import TravellerService from "../../../Service/TravelerService";
import Button from "react-bootstrap/Button";
// import  Traveler  from "./TravelersAddEdit";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Container, Row, Col } from "react-bootstrap";
const Travelers = (props) => {
  let travellerServ = new TravellerService();
  const [redirect, setredirect] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [travelerId, settravelerId] = useState("");
  const [traveler, setTraveler] = useState([]);
  const [travellerAddEdit, settravellerAddEdit] = useState({
    title: "",
    userId: window.user.result.id || "",
    firstName: "",
    // lastName: "",
    dob: "",
    passengerType: "",
  });
  let schema = Yup.object({
    passengerType: Yup.string().required("Passenger Type is required Field"),
    name: Yup.string().required(" Name is required field"),
  });
  useEffect(() => {
    async function fetchData() {
      getlist();
      window.scroll(0, 0);
    }
    fetchData();
  }, []);

  async function getlist() {
    try {
      if (window.user) {
        const result = await travellerServ.getService(window.user.result.id);
        setTraveler(result);
      }
    } catch (error) {
      setTraveler("");
    }
  }

  async function gettravellerId(Id) {
    try {
      let result = await travellerServ.getTraveler(Id);
      settravelerId(result.id);
      settravellerAddEdit(result);
    } catch (err) {
      settravellerAddEdit({});
    }
  }

  const handleSubmitForm = (values, actions) => {
    values.userId = window.user.result.id;
    if (travelerId) {
      travellerServ.editService(values).then(
        (response) => {
          getlist();
          handleClose();
        },
        (error) => {
          toast.success(error);
          setredirect(false);
        }
      );
    } else {
      travellerServ
        .addService(values)
        .then((response) => {
          getlist();
          handleClose();
        })
        .catch((err) => {
          toast.success(err);
          setredirect(false);
        });
    }
  };

  function handleClose() {
    settravellerAddEdit("");
    setshowModal(false);
    // getlist()
  }
  const handleShow = (id) => {
    setshowModal(true);
    // settravelerId(values);
    gettravellerId(id);
  };
  const handleDelete = (id) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
      travellerServ.deleteService(id).then((response) => {
        getlist();
      });
    }
  };

  if (redirect === true) {
    return <Redirect to="/user/travelers" />;
  }
  const RandomColorGen = () => {};
  return (
    <>
      <div class="col-md-9-5 " style={{ marginTop: "30px" }}>
        <h1 class="theme-account-page-title">Travelers</h1>
        <div class="row row-col-gap" data-gutter="20">
          {traveler.map((td) => {
            let color = Math.floor(Math.random() * 16777215).toString(16);
            return (
              <div class="col-md-6 ">
                <div class="theme-account-traveler">
                  <i class="theme-account-traveler-icon" style={{ backgroundColor: `#${color}` }}>
                    {td.name.charAt(0)}
                  </i>
                  <div class="theme-account-traveler-body">
                    <>
                      <p class="theme-account-traveler-name">{td.name}</p>
                      <ul class="theme-account-traveler-details">
                        <li>Passenger type :{td.passengerType}</li>
                        <li> DOB:{td.dob}</li>
                        {/* <li>{td.country}</li> */}
                      </ul>
                      <ul class="theme-account-traveler-actions">
                        <li>
                          <a style={{ cursor: "pointer" }} onClick={() => handleShow(td.id)}>
                            Edit
                          </a>
                        </li>
                        <li>
                          <a style={{ cursor: "pointer" }} onClick={() => handleDelete(td.id)}>
                            Delete
                          </a>
                        </li>
                      </ul>
                    </>
                  </div>
                </div>
              </div>
            );
          })}
          <div class="col-md-6 ">
            <div
              class="theme-account-traveler"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleShow();
              }}
            >
              <div class="theme-account-traveler-link-mask"></div>
              <p class="theme-account-traveler-add-new">+ Add New Traveller</p>
            </div>
          </div>

          <Modal show={showModal} onHide={handleClose} style={{ opacity: 1 }}>
            <Modal.Header closeButton>
              <b>Traveller Details</b>
            </Modal.Header>
            <Container>
              <Row>
                <Col md={5}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={travellerAddEdit}
                    onSubmit={handleSubmitForm}
                    validationSchema={schema}
                    render={({ values, handleChange, handleSubmit, errors, handleBlur, touched }) => (
                      <form onSubmit={handleSubmit}>
                        <div>
                          <div className="theme-login-box-inner">
                            <div className="theme-login-form">
                              <div className="form-group theme-login-form-group">
                                <select
                                  name="title"
                                  className="form-control ng-untouched ng-pristine ng-valid"
                                  formcontrolname="title"
                                  placeholder="Title"
                                  onChange={handleChange}
                                  value={values.title}
                                >
                                  <option value=""> Title </option>
                                  <option value="mr"> Mr </option>
                                  <option value="miss"> Miss </option>
                                  <option value="mrs"> Mrs </option>
                                  <option value="ms"> Ms </option>
                                  <option value="master"> Master </option>
                                </select>
                                <ErrorMessage name="name">{(msg) => <div className="err">{msg}</div>}</ErrorMessage>
                              </div>
                              <div className="form-group theme-login-form-group">
                                <input
                                  name="name"
                                  className="form-control ng-untouched ng-pristine ng-invalid"
                                  formcontrolname="name"
                                  placeholder=" name"
                                  onChange={handleChange}
                                  value={values.name}
                                  onBlur={handleBlur}
                                  // isValid={touched.passengerType}
                                />
                                <ErrorMessage name="name">{(msg) => <div className="err">{msg}</div>}</ErrorMessage>
                              </div>
                              {/* <div className="form-group theme-login-form-group">
                                <input
                                  name="lastName"
                                  className="form-control ng-untouched ng-pristine ng-invalid"
                                  formcontrolname="lastName"
                                  placeholder="Last name"
                                  onChange={handleChange}
                                  value={values.lastName}
                                />
                                <ErrorMessage name="password">{(msg) => <div className="err">{msg}</div>}</ErrorMessage>
                              </div> */}
                              <div className="form-group theme-login-form-group">
                                <input
                                  name="dob"
                                  type="date"
                                  className="form-control ng-untouched ng-pristine ng-invalid"
                                  formcontrolname="DOB"
                                  placeholder="D.O.B"
                                  onChange={handleChange}
                                  value={values.dob}
                                />
                                <ErrorMessage name="dob">{(msg) => <div className="err">{msg}</div>}</ErrorMessage>
                              </div>
                              <div className="form-group theme-login-form-group">
                                <select
                                  className="form-control ng-untouched ng-pristine ng-invalid"
                                  formcontrolname="passengerType"
                                  placeholder="Passenger Type"
                                  onChange={handleChange}
                                  name="passengerType"
                                  value={values.passengerType}
                                  onBlur={handleBlur}
                                  // isValid={touched.passengerType}
                                >
                                  <option value=""> Passenger Type* </option>
                                  <option value="adult"> Adult </option>
                                  <option value="child"> Child </option>
                                  <option value="infant"> Infant </option>
                                </select>
                                <ErrorMessage name="passengerType">
                                  {(msg) => <div className="err">{msg}</div>}
                                </ErrorMessage>
                              </div>
                              <button type="submit" className="btn btn-uc btn-dark btn-block btn-lg" type="submit">
                                Create Account
                              </button>
                            </div>
                          </div>
                          <div className="theme-login-box-alt"></div>
                        </div>
                      </form>
                    )}
                  />
                </Col>
              </Row>
              <ToastContainer />
            </Container>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default Travelers;
