import React, { useState, useContext, useEffect } from "react";
import PageService from "../../Service/PageService";
import Context from "../../Context/Context";
import { BannerUtils } from "../../Service/UtilsService";
import {Link} from "react-router-dom";
import {leftContentBar} from "../../Helper/helper";
const Pg = (props) => {
  let PageContext = useContext(Context);
  let pgServ = new PageService();
  let Banner = { bannerimage: "" };
  
  let [menu, setMenu] = useState([]);
  
  let page = { title: "", content: "" };

  useEffect(() => {
    setMenu(Object.values(PageContext.pagesBannersList));
  }, [PageContext.pagesBannersList])



  useEffect(() => {
    async function fetchData() {
      if(PageContext.pagesBannersList[props.match.params.pgname]){
        page = PageContext.pagesBannersList[props.match.params.pgname];
        if(PageContext.pagesBannersList[props.match.params.pgname].banners){
          Banner = PageContext.pagesBannersList[props.match.params.pgname].banners[0];
        }
      }
      
      window.scroll(0, 0, { behavior: "smooth" });
      // const result = await pgServ.getPage(props.match.params.pgname).then((result) => {
      //   if (result && result.data) {
      //       // setPage(result.data); 
      //       setPage(result.data.page);
      //       setBanner(result.data.banner); 
      //   } else {
      //     setPage({ title: "Sorry page not found", content: "Page you are looking for does not exists." });
      //   }
      // });
      // const resultBaner = await BannerUtils(props.match.params.pgname);
      // setBanner(resultBaner);
    }
    fetchData();
  }, [props.match.params.pgname]);
  if(PageContext.pagesBannersList[props.match.params.pgname]){
    page = PageContext.pagesBannersList[props.match.params.pgname];
    if(PageContext.pagesBannersList[props.match.params.pgname].banners){
      Banner = PageContext.pagesBannersList[props.match.params.pgname].banners[0];
    }
  }
  return (
    <div>
      <div className="theme-hero-area front hero-area-bg _pv-mob-50">
{/*         <div className="theme-hero-area-bg-wrap">
          <div className="theme-hero-area-bg" style={{ backgroundImage: `url(${Banner.bannerimage})` }}></div>
          <div className="theme-hero-area-mask theme-hero-area-mask-half"></div>
          <div className="theme-hero-area-inner-shadow"></div>
        </div> */}
{/*         <div className="theme-hero-area-body">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1 theme-page-header-abs">
                <div className="theme-page-header theme-page-header-lg">
                  <h1 className="theme-page-header-title">{page.title} </h1>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          <div className="theme-hero-area-body">
            <div className="container">
              <div className="theme-hero-area-conatct-bg-wrap">
                <div className="contact-us-title">
                  <div className="title-head">{page.title}</div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="theme-page-section _pb-30 theme-page-section-gray theme-page-section-xl">
        <div className="container">
          <div className="row _mh-30 _mh-mob-0">
          {/*  <div className="col-md-3 theme-custom-sidebar">
            {menu.map((el, i) => {
              return (
                <>
                  <div className="sidebar-menu-item">
                    <Link key={el.id} to={`/pg/${el.name}`}>{el.title}</Link>
                  </div>
                </>
              );
            })}
              <div className="sidebar-menu-item">
                <a target='_blank' href="https://esta.cbp.dhs.gov/esta">Apply for ESTA</a>
              </div>
            </div>*/}
            {leftContentBar}
            <div className="col-md-9 _m-a">
              <div className="theme-blog-post theme-blog-scetion" dangerouslySetInnerHTML={{ __html: page.content }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Pg;
