import React, { useState } from "react";
import styled from "styled-components";

import PassengersNumberInput from "./PassengersNumberInput";

const StyledDiv = styled.div`
  display: inline-block;
  width: 100%;
  border: "1px solid #0093d2";
  color: #000;
  font-family: Arial;
`;

const PassengersInput = ({
  className,
  adult,
  child,
  infant,
  totalTravelers,
  changeAdultEvent,
  changeChildEvent,
  changeInfantEvent,
  ...props
}) => {
  const [passengers, setPassengers] = useState({ adult: adult, child: child, infant: infant });
  function changeAdult(value) {
    setPassengers({ ...passengers, adult: value });
    changeAdultEvent(value);
  }
  function changeChild(value) {
    setPassengers({ ...passengers, child: value });
    changeChildEvent(value);
  }
  function changeInfant(value) {
    setPassengers({ ...passengers, infant: value });
    changeInfantEvent(value);
  }

  return (
    <StyledDiv>
      <div className="row _pb-10 _pt-10">
        <div className="col-xs-7">
          Adults
        </div>
        <div className="col-xs-5">
          <PassengersNumberInput value={passengers.adult || 0} disablePlus={totalTravelers >= 15 ? true: false} changeEvent={changeAdult} min={1} max={9} />
        </div>
      </div>
      <div className="row _pb-10">
        <div className="col-xs-7">
          Children (2 - 11 Yrs)
        </div>
        <div className="col-xs-5">
          <PassengersNumberInput value={passengers.child || 0} disablePlus={totalTravelers >= 15 ? true: false} changeEvent={changeChild} min={0} max={9} />
        </div>
      </div>
      <div className="row _pb-10">
        <div className="col-xs-7">
          Infants (under 2 Yrs)
        </div>
        <div className="col-xs-5">
          <PassengersNumberInput value={passengers.infant || 0} disablePlus={totalTravelers >= 15 ? true: false} changeEvent={changeInfant} min={0} max={9} />
        </div>
      </div>
      {totalTravelers >= 15 && (
        <div style={{ color: "red", background: "white",textAlign: "center" }}>
          Total travellers can not exceed 15.
        </div>
      )}
    </StyledDiv>
  );
};
export default PassengersInput;
