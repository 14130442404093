import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import userService from "../../../Service/UserService";

export default class Prefrences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        password: "",
        confirmPassword: "",
        oldPassword: "",
        id: window.user.result.id
      },
      formErrors: {
        oldPassword: null,
        password: null,
        confirmPassword: null
      }
    };
    this.userServ = new userService();
  }

  handleChange = e => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    // handle change event except language field
    formObj = {
      ...form,
      [name]: value
    };

    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};
      if (name === "password" || name === "confirmPassword") {
        let refValue = this.state.form[
          name === "password" ? "confirmPassword" : "password"
        ];
        const errorMsg = this.validateField(name, value, refValue);
        formErrorsObj = { ...formErrors, [name]: errorMsg };
        if (!errorMsg && refValue) {
          formErrorsObj.confirmPassword = null;
          formErrorsObj.password = null;
        }
      } else {
        const errorMsg = this.validateField(
          name,
          name === "language" ? this.state.form["language"] : value
        );
        formErrorsObj = { ...formErrors, [name]: errorMsg };
      }
      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "password":
        // refValue is the value of Confirm Password field
        if (!value) errorMsg = "Please enter Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password does not match.";
        break;
      case "confirmPassword":
        // refValue is the value of Password field
        if (!value) errorMsg = "Please enter Confirm Password.";
        else if (refValue && value !== refValue)
          errorMsg = "Password and Confirm Password does not match.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;
      if (x === "password" || x === "confirmPassword") {
        refValue = form[x === "password" ? "confirmPassword" : "password"];
      }
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    }
    this.userServ
      .changePassword(form)
      .then(response => {
        toast.success(response.message);
      })
      .catch(err => {
        toast.error(err);
      });
  };

  render() {
    const { form, formErrors } = this.state;
    return (
      <div class="col-md-9-5 " style = {{marginTop: "30px"}}>
        <h1 class="theme-account-page-title">Prefrences</h1>
        <div class="row">
          <div class="col-md-9 ">
            <div class="theme-account-preferences">
              <div class="theme-account-preferences-item">
                <div class="row">
                  <div class="col-md-3 ">
                    <h5 class="theme-account-preferences-item-title">
                      Email Address
                    </h5>
                  </div>
                  <div class="col-md-7 ">
                    <p class="theme-account-preferences-item-value">
                      {window.user.result.email}
                    </p>
                  </div>
                </div>
              </div>
              <div class="theme-account-preferences-item">
                <div class="row">
                  <div class="col-md-3 ">
                    <h5 class="theme-account-preferences-item-title">
                      Password
                    </h5>
                  </div>
                  <div class="col-md-7 ">
                    <p class="theme-account-preferences-item-value">********</p>
                    <div class="collapse" id="ChangePassword">
                      <div class="theme-account-preferences-item-change">
                        <p class="theme-account-preferences-item-change-description">
                          It's a good idea to use a strong password
                          <br />
                          that you're not using elsewhere
                        </p>
                        <div class="form-group theme-account-preferences-item-change-form">
                          <label>Current Password</label>
                          <div className="">
                            <input
                              className="form-control"
                              type="password"
                              name="oldPassword"
                              value={form.oldPassword}
                              onChange={this.handleChange}
                              onBlur={this.handleChange}
                            />
                            {formErrors.oldPassword && (
                              <span className="err">
                                {formErrors.oldPassword}
                              </span>
                            )}
                          </div>
                          {/* <input class="form-control" type="password" /> */}
                          <label>New Password</label>
                          <div className="">
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              value={form.password}
                              onChange={this.handleChange}
                              onBlur={this.handleChange}
                            />
                            {formErrors.password && (
                              <span className="err">{formErrors.password}</span>
                            )}
                          </div>
                          {/* <input class="form-control" type="password" /> */}
                          <label>Re-type New Password</label>
                          <div className="">
                            <input
                              className="form-control"
                              type="password"
                              name="confirmPassword"
                              value={form.confirmPassword}
                              onChange={this.handleChange}
                              onBlur={this.handleChange}
                            />
                            {formErrors.confirmPassword && (
                              <span className="err">
                                {formErrors.confirmPassword}
                              </span>
                            )}
                          </div>
                          {/* <input class="form-control" type="password" /> */}
                        </div>
                        <div class="theme-account-preferences-item-change-actions">
                          <button
                            onClick={this.handleSubmit.bind(this)}
                            class="btn btn-sm btn-primary"
                            href="#"
                          >
                            Save changes
                          </button>
                          <a
                            class="btn btn-sm btn-default"
                            href="#ChangePassword"
                            data-toggle="collapse"
                            aria-expanded="false"
                            aria-controls="ChangePassword"
                          >
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 ">
                    <a
                      class="theme-account-preferences-item-change-link"
                      href="#ChangePassword"
                      data-toggle="collapse"
                      aria-expanded="false"
                      aria-controls="ChangePassword"
                    >
                      <i class="fa fa-pencil"></i>edit
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
