import React, { useContext } from "react";
import Context from "../../Context/Context";
import { getCurrency } from "../../Utils/utils";
const Holiday = () => {
  let BookedContext = useContext(Context);
  console.log("BookedContext", BookedContext, BookedContext.SettingList);
  return (
    <div className="theme-page-section theme-page-section-xl theme-page-section-gray holiday-container">
      {/* <h1 className="theme-payment-success-title">holiday-packages</h1> */}
      <h3>Packages to suit your interests and budget</h3>
      <p>
        Book your stay now and enjoy world-class hospitality and best-in-class
        services.
      </p>
      <div className="cards-container">
        <div className="h-card">
          <img
            src="https://api.flightstravel.co.uk/uploads/blogImage/1633355794994_dubai-expo.jpg"
            alt="Avatar"
          />
          <div className="container">
            <h4>Great Family Holiday</h4>
            <p>
              The perfect family holiday isn’t always easy to come by, but
              that’s where Flights Travel comes in. Whether you’re looking for
              kids’ clubs, water parks, or activities for teens, we have
              holidays for everyone. Discover the rainforests and the depths of
              the Caribbean where you'll be up to your elbows in rare and exotic
              wildlife.
            </p>
            {/* <p>
              Looking for a magical holiday for kids big and small? You can
              always rely on a good Disney trip. If you want something close to
              home, you can head for Disneyland Paris which is less than two
              hours away. Thrill seekers can take it up a notch and jet off to
              Orlando's Walt Disney World and Universal Studios for even more
              attractions, parks, and things to do.
            </p> */}
            <a href="/contact">Enquire us about All Family Holidays</a>
          </div>
        </div>
        <div className="h-card">
          <img
            src="https://api.flightstravel.co.uk/uploads/galleryImage/1604006947389_maldives_honeymoon.jpg"
            alt="Avatar"
          />
          <div className="container">
            <h4>All Inclusive Resorts</h4>
            <p>
              All Inclusive holidays are second to none. Almost everything you
              need can be taken care of in advance. You can say goodbye to
              running up a super hotel tab and hello to food, drink, and
              sometimes even entertainment at your leisure.
            </p>
            {/* <p>
              Wander the souks of Turkey and get your haggle on, knowing you can
              afford to splash the cash a little bit more thanks to your
              savings-savvy All Inclusive booking. Or, if you’re taking a
              fun-filled break in the purpose-built playground that is Abu
              Dhabi, you’ll have a bit extra tucked away to spend on
              once-in-a-lifetime experiences, like a day trip to Ferrari World.
              If you’ve never been on an All Inclusive holiday before, it’s time
              to give it a try.
            </p> */}
            <a href="/contact">Enquire us about All Inclusive Resorts</a>
          </div>
        </div>
        <div className="h-card">
          <img
            src="https://api.flightstravel.co.uk/uploads/galleryImage/1586107768969_Barbados.jpg"
            alt="Avatar"
          />
          <div className="container">
            <h4>Honeymoons</h4>
            <p>
              Honeymoon is the perfect opportunity for couples to unwind, go
              sightseeing and spend all-important quality time together. From
              the narrow canal ways of Venice, to the majestic architecture in
              Budapest, there are endless romantic places to make new memories.
            </p>
            <a href="/contact">Enquire us about All Honeymoons Packages</a>
          </div>
        </div>
        <div className="h-card">
          <img
            src="https://api.flightstravel.co.uk/uploads/galleryImage/1604007291016_Jakarta.jpg"
            alt="Avatar"
          />
          <div className="container">
            <h4>Luxury Holidays</h4>
            <p>
              Travel to Victoria Falls aboard a vintage-style train, follow a
              private guide in Kyoto’s geisha district, unwind in a luxury beach
              resort in Thailand... On a tailor-made luxury holiday with us, you
              can indulge in the finest options your way.
            </p>
            <a href="/contact">Enquire us about All Luxury Holidays</a>
          </div>
        </div>
      </div>
      <div className="theme-page-section-blue">
        We’ve got over 300,000 hotels for you to explore at SUPER low prices,
        with 5,000 All Inclusive options to boot spanning from Benidorm to
        Barbados and Majorca to the Maldives. Spread the cost and pay monthly
        with low deposits starting from just{" "}
        {getCurrency() === "USD" ? "$" : "£"}105pp. Plus, our cheap holidays are
        ATOL protected, so you won’t have to worry about a thing!
      </div>
      <div className="last-section">
        <div className="why-us-section">
          <h4>Why to book your holiday packages with Us -</h4>
          <ul>
            <li>Bespoke Holiday Specialists</li>
            <li>Fully ATOL protected</li>
            <li>Experienced Travel Professionals</li>
            <li>Price Match Guaranteed</li>
            <li>Book with Confidence - Money is Fully Financially Protected</li>
          </ul>
        </div>
        <div className="contact-section">
          <ul>
            <li>
              <i class="fa fa-phone" aria-hidden="true"></i>
              <div className="_ml-10">
                <p className="call-below-text">Call US</p>
                <a href={`tel:${BookedContext.SettingList.contact_phone}`}>
                  {BookedContext.SettingList.contact_phone}
                </a>
              </div>
            </li>
            <li>
              <i class="fa fa-envelope-o" aria-hidden="true"></i>
              <div className="_ml-10">
                <p className="call-below-text">Have a query</p>
                <a href="mailto: info@flightsTravel.co.uk">
                  info@flightsTravel.co.uk
                </a>
              </div>
            </li>
            <li>
              <i class="fa fa-whatsapp" aria-hidden="true"></i>
              <div className="_ml-10">
                <p className="call-below-text">Whatsapp</p>
                <a
                  href="https://api.whatsapp.com/send?phone=447557646504&amp;text=&amp;source=&amp;data="
                  class="contactus-color"
                  target="_blank"
                >
                  07557 64 65 04
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Holiday;
