import React from "react";

const TravellerDetail = () => {
     return (
         <>
            <h4 className="traveller-form-heading">Contact Information</h4>
            <form className="traveller-form">
                <div className="form-group _pb-10">
                    <label for="email" className="label-text">Email:</label>
                    <input type="email" className="form-control" id="email" placeholder="Enter email" name="email" />
                </div>
                <div className="form-group _pb-10">
                    <label for="contact" className="label-text">Contact Number:</label>
                    <input type="number" className="form-control" id="contact" placeholder="Enter number" name="contact" />
                </div>
            </form>  
            <hr />
            <h4 className="traveller-form-heading">Adult 1</h4> 
            <div className="text-secondary">
              <small>
                Please provide contact details aligned with Card which will be used for payment, these details will be
                populated on Sagepay's payment website. However you are free to change these details on Sagepay's
                payment website.
              </small>
            </div>
            <form className="traveller-form _pt-10">
                <label for="title" className="label-text">Title:</label>
                <div className="form-group _pb-10">
                    <label className="radio-inline">
                        <input type="radio" name="optradio" />Mr
                    </label>
                    <label className="radio-inline">
                        <input type="radio" name="optradio" />Mrs
                    </label>
                    <label className="radio-inline">
                        <input type="radio" name="optradio" />Miss
                    </label>
                    <label className="radio-inline">
                        <input type="radio" name="optradio" />Ms
                    </label>
                </div>
                <div className="form-group _pb-10">
                    <label for="name" className="label-text">Full name:</label>
                    <input type="text" className="form-control" id="name" placeholder="Enter full name" name="name" />
                </div>
                <div className="form-group _pb-10">
                    <label for="dob" className="label-text">Date of Birth:</label>
                    <input type="date" className="form-control" id="dob" placeholder="Enter date of birth" name="dob" />
                </div>
                <div className="form-group _pb-10">
                    <label for="address" className="label-text">Address:</label>
                    <input type="text" className="form-control" id="address" placeholder="Enter address" name="address" />
                </div>
                <div className="form-group _pb-10">
                    <label for="city" className="label-text">City:</label>
                    <input type="text" className="form-control" id="city" placeholder="Enter city" name="city" />
                </div>
                <div className="form-group _pb-10">
                    <label for="postal_code" className="label-text">Postal code:</label>
                    <input type="text" className="form-control" id="postal_code" placeholder="Enter postal code" name="postal_code" />
                </div>
                <div className="form-group _pb-10">
                    <label for="country" className="label-text">Select country:</label>
                    <select className="form-control" id="country">
                        <option>America</option>
                        <option>India</option>
                        <option>London</option>
                        <option>NewYork</option>
                    </select>
                </div>

            </form> 
         </>
     )
}

export default TravellerDetail;
