import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import usePrevious from "./usePrevious";
import useTimer from "./useTimer";

const useRefreshModal = ({ flights = [], fetch }) => {
  const [showAdvertise, setShowAdvertise] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  // const = useTi
  const [count, counting, startTimer, clearTimer] = useTimer({
    mount: false,
    time: 1200,
  });

  const oldCounting = usePrevious(counting);

  const confirmRefresh = () => {
    window.location.reload()
  };

  const closeRefreshModal = () => {
    setRefreshModal(false);
    clearTimer();
  };

  useEffect(() => {
    //setTimeout(() => setShowAdvertise(true), 5000);
    clearTimer();
    startTimer();
  }, [flights]);

  useEffect(() => {
    if (oldCounting === true && counting === false) {
      setRefreshModal(false);
    }
  }, [counting]);

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  return {
    refreshModal,
    showAdvertise,
    confirmRefresh,
    closeRefreshModal,
  };
};

export default useRefreshModal;
