import React, { useContext, useEffect, useRef, useState, memo } from "react";
import { withRouter } from "react-router-dom";
import PassengersInput from "../../DefaultComponent/PassengersInput";
import FlightService from "../../Service/FlightService";
import Popup from "reactjs-popup";
import {
  NumberParam,
  QueryParamProvider,
  StringParam,
  useQueryParams,
} from "use-query-params";
import SearchContext from "../../Context/Context";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "react-dates/initialize";
import {
  DateRangePicker,
  isInclusivelyAfterDay,
  SingleDatePicker,
} from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { notification } from "../../Shared/NotificationModal";
import FlightLoaderCommon from "../../DefaultComponent/FlightLoaderCommon";
import { getQueryData } from "../../Helper/helper";
import qs from "qs";
import Select from 'react-select';
import { CABIN_OPTIONS } from "../SearchResult/New/constants";

const FlightSearchBar = (props) => {
  let SearchContxt = useContext(SearchContext);

  const [objQP, setObjQP] = useQueryParams({
    from: StringParam,
    from1: StringParam,
    to: StringParam,
    to1: StringParam,
    depart: StringParam,
    arrival: StringParam,
    adult: NumberParam,
    child: NumberParam,
    infant: NumberParam,
    trip: NumberParam,
    cabin: NumberParam,
  });

  const [SearchForm, SetSearchForm] = useState({
    from: "London - LHR",
    from1: "",
    to: "",
    to1: "",
    depart: "",
    arrival: "",
    adult: 1,
    child: 0,
    cabin: CABIN_OPTIONS[0].value,
    infant: 0,
    trip: 0,
    utm_campaign: "localSearch",
  });
  let dipRef = useRef("");
  let arrRef = useRef("");
  let dDateRef = useRef("");
  let aDateRef = useRef("");
  let travelRef = useRef("");
  const [inputToVal, setInputToValue] = useState("");
  const [inputReturnToVal, setInputReturnToValue] = useState("");
  const [FlightLoading, setFlightLoading] = useState("false");
  const [inputVal, setInputValue] = useState("");
  const [inputReturnFromVal, setInputReturnFromVal] = useState("");
  const [airPortCodeList, setAirPortCodeList] = useState([]);
  const [toTabEvent, setToTabEvent] = useState(false);
  const [fromTabEvent, setFromTabEvent] = useState(false);
  const [initialApiCall, setInitialAPICall] = useState(true);
  const [date, setDate] = useState(null);
  const [date2, setDate2] = useState(null);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [monthCount, setMonthCount] = useState(2);

  let flightServ = new FlightService();
  useEffect(() => {
    if (initialApiCall || props.location.state) {
      const { pathname, search } = props.location;
      setMonthCount(window.innerWidth > 769 ? 2 : 1);
      setInitialAPICall(false);
      async function getActualUrl() {
        setFlightLoading("true");
        const result = await flightServ.getActualUrl({
          mirror_url: pathname + search,
        });
        setFlightLoading("false");
        let actual_url =
          result && result.actual_url && result.actual_url.split("?")?.[1];
        actual_url = Object.fromEntries(new URLSearchParams(actual_url));

        if (actual_url) {
          SetSearchForm(actual_url);
          SearchContxt.fetchFlightList(actual_url, true, false);
          props.history.replace(result.mirror_url);
          props.SetSearchForm(actual_url);
          SearchContxt.submitSearch(actual_url);
          updateBarValues(actual_url);
        }
      }

      if (pathname.includes("/book")) {
        getActualUrl();
      }
      updateBarValues();
      window.addEventListener(
        "resize",
        () => {
          setMonthCount(window.innerWidth > 769 ? 2 : 1);
        },
        { passive: true }
      );
    }
  }, [props.location.state && props.location.pathname.includes("/book")]);

  const updateBarValues = async (data) => {
    const obj = data || objQP;
    const result = SearchContxt.searchReasult;
    if (obj.trip === 1) {
      obj.arrival = ""; //removing the arrival date from query string
      setEndDate(undefined);
    }
    // if(obj.trip === 2) {
    //   obj.IsMulti = 1
    // }
    if (obj.from && obj.to && obj.from.includes("-") && obj.to.includes("-")) {
      SetSearchForm(obj);
      setInputValue(obj.from);
      setInputToValue(obj.to);
      if (obj.from1 && obj.to1) {
         setInputReturnFromVal(obj.from1)
         setInputReturnToValue(obj.to1)
      }
    } else if (
      result.from &&
      result.to &&
      result.from.includes("-") &&
      result.to.includes("-")
    ) {
      setInputValue(result.from);
      if (result.from1 && result.to1) {
        setInputReturnFromVal(result.from1)
        setInputReturnToValue(result.to1)
      }
      if (result.to) {
        setInputToValue(result.to);
        setStartDate(moment(result.depart));
        setEndDate(moment(result.arrival));
      } else {
        window.$("#toId").focus();
      }
      SetSearchForm(result);
    } else {
      setInputValue(result.from);
      if (result.from1) {
        setInputReturnFromVal(result.from1)
      }
      if (obj.from && obj.to && !obj.from.includes("-")) {
        SetSearchForm(obj);
        Promise.all([
          flightServ.getAirportCode(obj.from),
          flightServ.getAirportCode(obj.to),
        ]).then((res) => {
          const fromAirport = res[0]?.[0];
          const toAirport = res[1]?.[0];
          if (fromAirport) {
            setInputValue(`${fromAirport.city_name} - ${fromAirport.code}`);
          } else if (obj.from === "DTT") {
            setInputValue(`${"Detroit, MI"} - ${obj.from}`);
          } else {
            setInputValue(obj.from);
          }
          if (toAirport) {
            setInputToValue(`${toAirport.city_name} - ${toAirport.code}`);
          } else if (obj.to === "DTT") {
            setInputToValue(`${"Detroit, MI"} - ${obj.to}`);
          } else {
            setInputToValue(obj.to);
          }
        });

        if (obj.from1 && obj.to1 && !obj.from1.includes("-")) {
          Promise.all([
            flightServ.getAirportCode(obj.from1),
            flightServ.getAirportCode(obj.to1)
          ]).then((res) => {
            const fromAirport = res[0]?.[0];
            const toAirport = res[1]?.[0];
            if (fromAirport) {
              setInputReturnFromVal(`${fromAirport.city_name} - ${fromAirport.code}`);
            } else if (obj.from === "DTT") {
              setInputReturnFromVal(`${"Detroit, MI"} - ${obj.from1}`);
            } else {
              setInputReturnFromVal(obj.from1);
            }
            if (toAirport) {
              setInputReturnToValue(`${toAirport.city_name} - ${toAirport.code}`);
            } else if (obj.to1 === "DTT") {
              setInputReturnToValue(`${"Detroit, MI"} - ${obj.to1}`);
            } else {
              setInputReturnToValue(obj.to1);
            }
          });
        }
      } else if (result.from && result.to && !result.from.includes("-")) {
        Promise.all([
          flightServ.getAirportCode(result.from),
          flightServ.getAirportCode(result.to),
        ]).then((res) => {
          const fromAirport = res[0]?.[0];
          const toAirport = res[1]?.[0];
          SetSearchForm(result);
          if (fromAirport) {
            setInputValue(`${fromAirport.city_name} - ${fromAirport.code}`);
          } else if (result.from === "DTT") {
            setInputValue(`${"Detroit, MI"} - ${result.from}`);
          } else {
            setInputValue(result.from);
          }
          if (toAirport) {
            setInputToValue(`${toAirport.city_name} - ${toAirport.code}`);
          } else if (result.to === "DTT") {
            setInputToValue(`${"Detroit, MI"} - ${result.to}`);
          } else {
            setInputToValue(result.to);
          }
          setStartDate(moment(result.depart));
          setEndDate(result.arrival ? moment(result.arrival) : undefined);
        });

        if (result.from1 && result.to1 && !result.from1.includes("-")) {
          Promise.all([
            flightServ.getAirportCode(result.from1),
            flightServ.getAirportCode(result.to1),
          ]).then((res) => {
            const fromAirport = res[0]?.[0];
            const toAirport = res[1]?.[0];
            SetSearchForm(result);
            if (fromAirport) {
              setInputReturnFromVal(`${fromAirport.city_name} - ${fromAirport.code}`);
            } else if (result.from1 === "DTT") {
              setInputReturnFromVal(`${"Detroit, MI"} - ${result.from1}`);
            } else {
              setInputReturnFromVal(result.from1);
            }
            if (toAirport) {
              setInputReturnToValue(`${toAirport.city_name} - ${toAirport.code}`);
            } else if (result.to1 === "DTT") {
              setInputReturnToValue(`${"Detroit, MI"} - ${result.to1}`);
            } else {
              setInputReturnToValue(result.to1);
            }
          });
        }
      }
    }
    if (obj.depart) {
      setStartDate(moment(obj.depart));
    }
    if (obj.arrival) {
      setEndDate(obj.arrival ? moment(obj.arrival) : undefined);
    }
    if (window.location.search) {
      return;
    }
  };

  useEffect(() => {
    if (props.location.pathname === "/") {
      SearchContxt.isFromFlightDetails(false)
      props.history.replace("/");
    } else {
      if (objQP.from && objQP.to && !SearchContxt.fromFlightDetails) {
        SetSearchForm(objQP);
        SearchContxt.fetchFlightList(objQP);
      } else if (
        SearchContxt.searchReasult.from &&
        SearchContxt.searchReasult.to
      ) {
        SetSearchForm(SearchContxt.searchReasult);
      }
    }
  }, [objQP]);

  // useEffect(() => {
  //     // const urlParams = new URLSearchParams(location.search);
  //     // const cabinQuery = urlParams.get("cabin");
  //         setObjQP(props.location.search)
  // }, [props.location.search])

  const clearData = () => {
    setAirPortCodeList([]);
    setToTabEvent(false);
    setFromTabEvent(false);
    if (!inputVal) {
      setInputValue("");
    }
    if (!inputToVal) {
      setInputToValue("");
    }
    if (!inputReturnFromVal) {
      setInputReturnFromVal("");
    }
    if (!inputReturnToVal) {
      setInputReturnToValue("");
    }
  };

  const handleSearch = async (e, values, to = false, returnFrom = false, returnTo = false) => {
    let { value } = e.target;
    if (to) {
      setInputToValue(value);
    } else if (returnFrom) {
      setInputReturnFromVal(value)
    } else if (returnTo) {
      setInputReturnToValue(value)
    } else {
      setInputValue(value);
    }
    if (value.length > 2) {
      let result = await flightServ.getAirportCode(value);
      setAirPortCodeList(result);
    } else if (value.length < 3) {
      setAirPortCodeList([]);
    }
  };

  useEffect(() => {
    const myElement = document.getElementById("toId");
    myElement.addEventListener("keydown", (e) => {
      if (e.keyCode === 9 || e.key === "Tab") {
        setToTabEvent(!toTabEvent);
      }
    });
    return () => myElement.removeEventListener("keydown", () => false);
  }, []);

  useEffect(() => {
    const myElement = document.getElementById("fromId");
    myElement.addEventListener(
      "keydown",
      (e) => {
        if (e.keyCode === 9 || e.key === "Tab") {
          setFromTabEvent(!fromTabEvent);
        }
      },
      { passive: true }
    );
    return () => myElement.removeEventListener("keydown", () => false);
  }, []);

  const handleArrivalValue = (e, option) => {
    if (typeof option !== "string") {
      if (document.activeElement.tagName === "INPUT" || toTabEvent) {
        if (option) {
          if (SearchForm.trip === 2 && window.innerWidth <= 600) {
            window.$("#returnFromId").focus();
          } else {
            setDate("startDate");
          }
          setInputToValue(`${option.city_name} - ${option.code}`);
          SetSearchForm((prev) => ({
            ...prev,
            to: `${option.city_name} - ${option.code}`,
          }));
        } else {
          setInputToValue("");
          SetSearchForm((prev) => ({ ...prev, to: "" }));
        }
      }
    }
  };

  const handleReturnArrivalValue = (e, option) => {
    if (typeof option !== "string") {
      if (document.activeElement.tagName === "INPUT") {
        if (option) {
          if (SearchForm.trip === 2 && window.innerWidth <= 600) {
            setDate("startDate");
          } else {
            setDate2("startDate2");
          }
          setInputReturnToValue(`${option.city_name} - ${option.code}`);
          SetSearchForm((prev) => ({
            ...prev,
            to1: `${option.city_name} - ${option.code}`,
          }));
        } else {
          setInputReturnToValue("");
          SetSearchForm((prev) => ({ ...prev, to1: "" }));
        }
      }
    }
  };

  const handleFromValue = (option) => {
    if (typeof option !== "string") {
      if (document.activeElement.tagName === "INPUT" || fromTabEvent) {
        if (option) {
          window.$("#toId").focus();
          setInputValue(`${option.city_name} - ${option.code}`);
          SetSearchForm((prev) => ({
            ...prev,
            from: `${option.city_name} - ${option.code}`,
          }));
        } else {
          setInputValue("");
          SetSearchForm((prev) => ({ ...prev, from: "" }));
        }
      }
    }
  };

  const handleReturnFromValue = (option) => {
    if (typeof option !== "string") {
      if (document.activeElement.tagName === "INPUT") {
        if (option) {
          window.$("#returnToId").focus();
          setInputReturnFromVal(`${option.city_name} - ${option.code}`);
          SetSearchForm((prev) => ({
            ...prev,
            from1: `${option.city_name} - ${option.code}`,
          }));
        } else {
          setInputReturnFromVal("");
          SetSearchForm((prev) => ({ ...prev, from1: "" }));
        }
      }
    }
  };

  const updateDepartValue = (value) => {
    SetSearchForm((prev) => ({ ...prev, depart: value }));
  };
  const updateArrivalValue = (value) => {
    SetSearchForm((prev) => ({ ...prev, arrival: value }));
  };
  const focusInput = (id) => {
    let setfocusOn2 = true;
    if (dipRef.current.getInput().value && arrRef.current.getInput().value) {
      if (
        !window.$(".datePickerStart").val() &&
        !window.$(".datePickerEnd").val()
      ) {
        let sdate = moment(
          new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
          "ddd, DD MMM"
        ).format("ddd, DD MMM");
        let edate = moment(
          new Date(Date.now() + 14 * 24 * 60 * 60 * 1000),
          "ddd, DD MMM"
        ).format("ddd, DD MMM");
        SetSearchForm((prev) => ({ ...prev, arrival: edate, depart: sdate }));
        setfocusOn2 = false;
      }
    }
    if (id == 0) {
      dipRef.current.focus();
    }
    if (id == 1) {
      arrRef.current.focus();
    }
    if (id == 2) {
      dDateRef.current.focus();
    }
    if (id == 3) {
      let endDate = window.$(".datePickerEnd");
      let startDate = window.$(".datePickerStart");
      if (
        endDate.data("DateTimePicker").date() &&
        startDate.data("DateTimePicker").date() &&
        endDate
          .data("DateTimePicker")
          .date()
          .diff(startDate.data("DateTimePicker").date()) <= 0
      ) {
        endDate
          .data("DateTimePicker")
          .date(startDate.data("DateTimePicker").date().add(7, "days"));
      }
      aDateRef.current.focus();
    }
    if (id == 4) {
      return travelRef.current.TriggerEl.click();
    }
  };

  useEffect(() => {
    datePickerFn();
  }, []);
  useEffect(() => {
    datePickerFn();
  }, [SearchForm.trip]);

  const datePickerFn = () => {
    function datePickers() {
      let showStartDt = false;
      let showEndDt = false;
      window
        .$(".datePickerStart")
        .datetimepicker({
          format: "ddd, DD MMM",
        })
        .on("dp.show", function (e) {
          showStartDt = false;
          let dt = moment();
          window.$(".datePickerStart").data("DateTimePicker").minDate(dt);
          showStartDt = true;
        })
        .on("dp.change", function (e) {
          updateDepartValue(e.target.value);
          if (showStartDt == true) {
            showStartDt = false;
            if (document.getElementById("flight-option-2").checked) {
              focusInput(4);
            } else if (document.getElementById("flight-option-1").checked) {
              focusInput(3);
            }
          }
        });

      window.$(".datePickerEnd").datetimepicker({ format: "ddd, DD MMM", }).on("dp.show", function (e) {
        showEndDt = false;
        let dt = window.$(".datePickerStart").data("DateTimePicker").date();
        if (!dt) {
          dt = moment();
        }
        window.$(".datePickerEnd").data("DateTimePicker").minDate(dt.add(1, "day"));
        showEndDt = true;
      }).on("dp.change", function (e) {
        updateArrivalValue(e.target.value);
        if (showEndDt == true) {
          showEndDt = false;
          focusInput(4);
        }
      });
    }

    datePickers();
    const datePLaceholders =
      SearchForm.trip !== 1
        ? [
            `<span class="date-placeholder">Depart date</span>`,
            `<span class="date-placeholder">Return date</span>`,
          ]
        : [`<span class="date-placeholder">Depart date</span>`];
    const dateInput = document.querySelectorAll(
      `${
        SearchForm.trip === 0 ? ".DateRangePickerInput" : ".SingleDatePicker"
      } .DateInput`
    );
    const clendarIcon = `<i class="theme-search-area-section-icon fa fa-calendar calendar-icon" />`;
    const placeholder = (i) => `<span>${datePLaceholders[i]}</span>`;
    dateInput.forEach((input, i) => {
      input.insertAdjacentHTML("afterBegin", clendarIcon);
      input.insertAdjacentHTML("afterBegin", placeholder(i));
    });
  };

  let options = airPortCodeList.filter((option) => option._type !== "city");
  const cityOptions = airPortCodeList.filter(
    (option) => option._type === "city"
  );
  let departureOptn = [];
  cityOptions.map((city) => {
    departureOptn.push(city);
    const filteredOption = options.filter(
      ({ city_code }) => city.city_code === city_code
    );
    departureOptn = [...departureOptn, ...filteredOption];
  });
  let remaining = options.filter(
    ({ city_code: cityCode }) =>
      !cityOptions.map(({ city_code }) => city_code).includes(cityCode)
  );
  remaining = remaining.map((item) => ({ ...item, onlyAirport: true }));
  departureOptn = [...departureOptn, ...remaining];
  const checkAirport = () => {
    if (SearchForm.to === SearchForm.from) {
      notification("error", "Departure and Arrival must be different!");
      return false;
    }
    return true;
  };

  const checkDetials = () => {
    let check = true;
    if (SearchForm.adult < 1) {
      check = false;
      window.$("#travllers-btn").addClass("mark-red");
    }
    if (!SearchForm.depart) {
      check = false;
      window.$("#depart-date").addClass("mark-red");
    }
    if (SearchForm.trip === 0 && !SearchForm.arrival) {
      check = false;
      window.$("#depart-date").addClass("mark-red");
    }
    if (SearchForm.trip === 2 && !SearchForm.arrival) {
      check = false;
      window.$("#return-to-date").addClass("mark-red");
    }
    if (!SearchForm.from || !inputVal.includes("-")) {
      check = false;
      window.$("#from-airport").addClass("mark-red");
    }
    if (SearchForm.trip === 2 && (!SearchForm.from1 || !inputReturnFromVal.includes("-"))) {
      check = false;
      window.$("#return-from-airport").addClass("mark-red");
    }
    if (SearchForm.trip === 2 && (!SearchForm.to1 || !inputReturnToVal.includes("-"))) {
      check = false;
      window.$("#return-to-airport").addClass("mark-red");
    }
    if (!SearchForm.to || !inputToVal.includes("-")) {
      check = false;
      window.$("#to-airport").addClass("mark-red");
    }
    if(SearchForm.infant > SearchForm.adult) {
      check = false;
      window.$("#travllers-btn").addClass("mark-red");
    }
    // if(!check)
    //   alert("Please fill all fileds to view flight results.")
    // }
    return check;
  };

  //passsenger count
  let pList = [SearchForm.adult, SearchForm.child, SearchForm.infant]
    .filter(Boolean)
    .reduce((acc, curr) => parseInt(acc) + parseInt(curr), 0);

  const handleDateInputChange = (date, isSingle = false) => {
    if (isSingle) {
      if (date.focused) {
        setDate(date);
      } else {
        setDate(null);
        if (SearchForm.trip === 2 && window.innerWidth <= 600) {
          setDate2("startDate2");
        }
      }
    } else {
      setDate(date);
    }
  };

  const handleDateInputChange2 = (date, isSingle = false) => {
    if (isSingle) {
      if (date.focused) {
        setDate2(date);
      } else {
        setDate2(null);
      }
    } else {
      setDate2(date);
    }
  };

  const onDateRangePicker = (startDate, endDate, isSingle = false) => {
    setStartDate(startDate);
    if (SearchForm.trip !== 2) {
      setEndDate(endDate);
    } else if (SearchForm.arrival && startDate && moment(SearchForm.arrival).isBefore(moment(startDate).format("YYYY-MM-DD"))) {
      setEndDate(null);
      window.$("#return-to-date").addClass("mark-red");
    }
    if (isSingle) {
      setDate(null);
    }
    if (startDate && endDate && date === "endDate") {
      setDate(null);
    }
    SetSearchForm((prev) => ({
      ...prev,
      depart: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
      arrival: endDate && SearchForm.trip !== 2 ? moment(endDate).format("YYYY-MM-DD") :
        SearchForm.arrival && startDate && !moment(SearchForm.arrival).isBefore(moment(startDate).format("YYYY-MM-DD")) ?
          moment(SearchForm.arrival).format("YYYY-MM-DD")
          : undefined,
    }));
  };

  const onDateRangePicker2 = (endDate) => {
    setEndDate(endDate);
    SetSearchForm((prev) => ({
      ...prev,
      arrival: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
    }));
  };

  const isOutsideRange = (day) => {
    const startDate = moment();
    const endDate = moment().subtract(1, "days").add(1, 'year');
    return !day.isBetween(startDate, endDate, 'day', '[]');
  };

  const isOutsideRangeMultiCity = (day) => {
    if (startDate) {
      return !isInclusivelyAfterDay(day, startDate) || isInclusivelyAfterDay(day, moment().add(1, 'year'))
    } else {
      const startDate = moment();
      const endDate = moment().subtract(1, "days").add(1, 'year');
      return !day.isBetween(startDate, endDate, 'day', '[]');
    }               
  }

  const closeTraveler = () => {
    travelRef.current.TriggerEl.click();
  }

  return (
    <>
      <FlightLoaderCommon show={FlightLoading} />
      <div className="theme-search-area-options _m-auto">
        <div className="col-md-12 ">
          {/*           <label className="search-lbl">
            <input
              type="radio"
              name="trip"
              id="flight-option-1"
              value="0"
              checked={SearchForm.trip == 0 ? true : false}
              onChange={(e) => {
                SetSearchForm((prev) => ({ ...prev, trip: 0 }));
              }}
            />
            Round Trip
          </label> */}
          {/*
          <label className="search-lbl">
          <input
              className="custom-radio"
              type="radio"
              name="trip"
              id="flight-option-2"
              value="1"
              checked={SearchForm.trip == 1 ? true : false}
              onChange={(e) => {
                SetSearchForm((prev) => ({ ...prev, trip: 1, arrival: "" }));
              }}
            />
            One Way
          </label> */}

          <div className="custom-radio-wrap">
            <form>
              <div className="form-group" style={{ display: "flex" }}>
                <div
                  className="search-lbl _mr-10"
                  onClick={() => {
                    SetSearchForm((prev) => ({ 
                      ...prev,
                      trip: 0,
                      // IsMulti: ""
                    }));
                  }}
                >
                  <input
                    type="radio"
                    name="custom-radio-btn"
                    name="trip"
                    id="flight-option-1"
                    value="0"
                    checked={SearchForm.trip == 0 ? true : false}
                  />
                  <label className="custom-radio" for="flight-option-1" />
                  <span className="label-text">Round Trip</span>
                </div>
                <div
                  className="search-lbl _mr-10"
                  onClick={() => {
                    setEndDate(undefined);
                    SetSearchForm((prev) => ({
                      ...prev,
                      trip: 1,
                      arrival: "",
                      // IsMulti: ""
                    }));
                  }}
                >
                  <input
                    type="radio"
                    name="custom-radio-btn"
                    name="trip"
                    id="flight-option-2"
                    value="1"
                    checked={SearchForm.trip === 1}
                  />
                  <label className="custom-radio" for="flight-option-2" />
                  <span className="label-text">One Way</span>
                </div>
                <div
                  className="search-lbl"
                  onClick={() => {
                    SetSearchForm((prev) => ({
                      ...prev,
                      trip: 2,
                      // IsMulti: 1,
                    }));
                  }}
                >
                  <input
                    type="radio"
                    name="trip"
                    id="flight-option-3"
                    value="2"
                    checked={SearchForm.trip === 2}
                  />
                  <label className="custom-radio" for="flight-option-3" />
                  <span className="label-text">Multi-City</span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="theme-search-area-form form-search" id="hero-search-form">
        <div className="row" data-gutter="none">
          <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 _mv-10">
            <div className="row" data-gutter="none">
              <div
                className="col-xs-6 col-sm-6 col-md-6 flying-from-input"
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#fff",
                }}
              >
                {/*<i className="lin lin-plane depart-icon"/>*/}
                <img
                  className="depart-icon"
                  src={"/assets/ficon/images/depart.png"}
                  alt="depart"
                />
                <div
                  style={{ width: "100%", paddingLeft: "8px" }}
                  className="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-no-border theme-search-area-section-curved"
                >
                  <div
                    style={{ borderLeft: "none" }}
                    className="theme-search-area-section-inner"
                  >
                    <span
                      style={{ left: "unset" }}
                      className="input-placeholder"
                    >
                      Flying from
                    </span>
                    {/* <i className="theme-search-area-section-icon lin lin-plane depart-icon"></i> */}
                    <Autocomplete
                      autoSelect
                      autoComplete
                      noOptionsText={"Type 3 letters to search..."}
                      filterOptions={(options) => options}
                      onClose={clearData}
                      classes={{
                        noOptions: inputVal ? "no-option-class" : "",
                      }}
                      popupIcon={false}
                      id="fromId"
                      options={departureOptn}
                      inputValue={inputVal}
                      getOptionLabel={(option) =>
                        `${option.city_name.split(",")[0]} (${option.code})` ||
                        `${option.title.split(",")[0]} (${option.code})`
                      }
                      includeInputInList
                      onChange={(e, value) => handleFromValue(value)}
                      renderOption={(option) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft:
                              option._type === "city" || option.onlyAirport
                                ? "-10px"
                                : "10px",
                          }}
                        >
                          <img
                            src={
                              option._type === "city"
                                ? "/assets/ficon/building.png"
                                : "/assets/ficon/flight.png"
                            }
                            alt="img-flight"
                            height="25px"
                            width="25px"
                            style={{
                              mixBlendMode: "multiply",
                              marginRight: "10px",
                            }}
                          />
                          {option._type === "city" ? (
                            <span className="search-option-text">
                              <span className="search-option-name">{`${option.city_fullname}`}</span>
                              <span className="search-option-tag">
                                {" "}
                                (All Airports)
                              </span>
                            </span>
                          ) : (
                            (
                              <span className="search-option-text">
                                <span className="search-option-name">{`${option.name}`}</span>
                                <span className="search-option-menu">
                                  {" "}
                                  {`${option.code}`}
                                </span>
                              </span>
                            ) || option.title
                          )}
                        </div>
                      )}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            value={inputVal}
                            id={"fromId"}
                            onChange={(e) => handleSearch(e)}
                            variant="filled"
                            onFocus={() => {
                              window.$("#from-airport").removeClass("mark-red");
                            }}
                            onBlur={(e) => {
                              handleFromValue(e.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <span className="err-msg" id="from-airport">
                    Please enter valid departure airport
                  </span>
                </div>
              </div>
              <div
                className="col-xs-6 col-sm-6 col-md-6 flying-to-input"
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#fff",
                }}
              >
                <img
                  className="depart-icon"
                  src={"/assets/ficon/images/arrival.png"}
                  alt="depart"
                />
                <div
                  style={{ width: "100%", paddingLeft: "8px" }}
                  className="theme-search-area-section second theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-no-border theme-search-area-section-curved"
                >
                  <div
                    className="theme-search-area-section-inner"
                    style={{ borderLeft: "none" }}
                  >
                    <span
                      className="input-placeholder"
                      style={{ left: "unset" }}
                    >
                      Flying to
                    </span>
                    <Autocomplete
                      autoSelect
                      autoComplete
                      noOptionsText={"Type 3 letters to search..."}
                      filterOptions={(options) => options}
                      onClose={clearData}
                      popupIcon={false}
                      id={"toId"}
                      classes={{
                        noOptions: inputToVal ? "no-option-class" : "",
                      }}
                      options={departureOptn}
                      inputValue={inputToVal}
                      getOptionLabel={(option) => {
                        return (
                          `${option.city_name.split(",")[0]} (${
                            option.code
                          })` ||
                          `${option.title.split(",")[0]} (${option.code})`
                        );
                      }}
                      includeInputInList
                      disablePortal
                      onChange={handleArrivalValue}
                      renderOption={(option) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft:
                              option._type === "city" || option.onlyAirport
                                ? "-10px"
                                : "10px",
                          }}
                        >
                          <img
                            src={
                              option._type === "city"
                                ? "/assets/ficon/building.png"
                                : "/assets/ficon/flight.png"
                            }
                            alt="img-flight"
                            height="25px"
                            width="25px"
                            style={{
                              mixBlendMode: "multiply",
                              marginRight: "10px",
                            }}
                          />
                          {option._type === "city" ? (
                            <span className="search-option-text">
                              <span className="search-option-name">{`${option.city_fullname}`}</span>
                              <span className="search-option-tag">
                                {" "}
                                (All Airports)
                              </span>
                            </span>
                          ) : (
                            (
                              <span className="search-option-text">
                                <span className="search-option-name">{`${option.name}`}</span>
                                <span className="search-option-menu">
                                  {" "}
                                  {`${option.code}`}
                                </span>
                              </span>
                            ) || option.title
                          )}
                        </div>
                      )}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            value={inputToVal}
                            on
                            onChange={(e) => handleSearch(e, null, true)}
                            variant="filled"
                            onFocus={() => {
                              window.$("#to-airport").removeClass("mark-red");
                            }}
                            onBlur={(e) => {
                              handleArrivalValue(e, e.target.value);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                  <span className="err-msg" id="to-airport">
                    Please enter valid arrival airport
                  </span>
                </div>
              </div>
            </div>
            {SearchForm.trip === 2 &&
            <div className="_mt-15">
              <div className="row" data-gutter="none">
                <div
                  className="col-xs-6 col-sm-6 col-md-6 flying-from-input"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#fff",
                  }}
                >
                  <img
                    className="depart-icon"
                    src={"/assets/ficon/images/depart.png"}
                    alt="depart"
                  />
                  <div
                    style={{ width: "100%", paddingLeft: "8px" }}
                    className="theme-search-area-section first theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-no-border theme-search-area-section-curved"
                  >
                    <div
                      style={{ borderLeft: "none" }}
                      className="theme-search-area-section-inner"
                    >
                      <span
                        style={{ left: "unset" }}
                        className="input-placeholder"
                      >
                        Returning from
                      </span>
                      <Autocomplete
                        autoSelect
                        autoComplete
                        noOptionsText={"Type 3 letters to search..."}
                        filterOptions={(options) => options}
                        onClose={clearData}
                        classes={{
                          noOptions: inputReturnFromVal ? "no-option-class" : "",
                        }}
                        popupIcon={false}
                        id="returnFromId"
                        options={departureOptn}
                        inputValue={inputReturnFromVal}
                        getOptionLabel={(option) =>
                          `${option.city_name.split(",")[0]} (${option.code})` ||
                          `${option.title.split(",")[0]} (${option.code})`
                        }
                        includeInputInList
                        onChange={(e, value) => handleReturnFromValue(value)}
                        renderOption={(option) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft:
                                option._type === "city" || option.onlyAirport
                                  ? "-10px"
                                  : "10px",
                            }}
                          >
                            <img
                              src={
                                option._type === "city"
                                  ? "/assets/ficon/building.png"
                                  : "/assets/ficon/flight.png"
                              }
                              alt="img-flight"
                              height="25px"
                              width="25px"
                              style={{
                                mixBlendMode: "multiply",
                                marginRight: "10px",
                              }}
                            />
                            {option._type === "city" ? (
                              <span className="search-option-text">
                                <span className="search-option-name">{`${option.city_fullname}`}</span>
                                <span className="search-option-tag">
                                  {" "}
                                  (All Airports)
                                </span>
                              </span>
                            ) : (
                              (
                                <span className="search-option-text">
                                  <span className="search-option-name">{`${option.name}`}</span>
                                  <span className="search-option-menu">
                                    {" "}
                                    {`${option.code}`}
                                  </span>
                                </span>
                              ) || option.title
                            )}
                          </div>
                        )}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              value={inputReturnFromVal}
                              id={"returnFromId"}
                              onChange={(e) => handleSearch(e, null, null, true)}
                              variant="filled"
                              onFocus={() => {
                                window.$("#return-from-airport").removeClass("mark-red");
                              }}
                              onBlur={(e) => {
                                handleReturnFromValue(e.target.value);
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                    <span className="err-msg" id="return-from-airport">
                      Please enter valid return from airport
                    </span>
                  </div>
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-md-6 flying-to-input"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "#fff",
                  }}
                >
                  <img
                    className="depart-icon"
                    src={"/assets/ficon/images/arrival.png"}
                    alt="depart"
                  />
                  <div
                    style={{ width: "100%", paddingLeft: "8px" }}
                    className="theme-search-area-section second theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-no-border theme-search-area-section-curved"
                  >
                    <div
                      className="theme-search-area-section-inner"
                      style={{ borderLeft: "none" }}
                    >
                      <span
                        className="input-placeholder"
                        style={{ left: "unset" }}
                      >
                        Returning to
                      </span>
                      <Autocomplete
                        autoSelect
                        autoComplete
                        noOptionsText={"Type 3 letters to search..."}
                        filterOptions={(options) => options}
                        onClose={clearData}
                        popupIcon={false}
                        id={"returnToId"}
                        classes={{
                          noOptions: inputReturnToVal ? "no-option-class" : "",
                        }}
                        options={departureOptn}
                        inputValue={inputReturnToVal}
                        getOptionLabel={(option) => {
                          return (
                            `${option.city_name.split(",")[0]} (${
                              option.code
                            })` ||
                            `${option.title.split(",")[0]} (${option.code})`
                          );
                        }}
                        includeInputInList
                        disablePortal
                        onChange={handleReturnArrivalValue}
                        renderOption={(option) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft:
                                option._type === "city" || option.onlyAirport
                                  ? "-10px"
                                  : "10px",
                            }}
                          >
                            <img
                              src={
                                option._type === "city"
                                  ? "/assets/ficon/building.png"
                                  : "/assets/ficon/flight.png"
                              }
                              alt="img-flight"
                              height="25px"
                              width="25px"
                              style={{
                                mixBlendMode: "multiply",
                                marginRight: "10px",
                              }}
                            />
                            {option._type === "city" ? (
                              <span className="search-option-text">
                                <span className="search-option-name">{`${option.city_fullname}`}</span>
                                <span className="search-option-tag">
                                  {" "}
                                  (All Airports)
                                </span>
                              </span>
                            ) : (
                              (
                                <span className="search-option-text">
                                  <span className="search-option-name">{`${option.name}`}</span>
                                  <span className="search-option-menu">
                                    {" "}
                                    {`${option.code}`}
                                  </span>
                                </span>
                              ) || option.title
                            )}
                          </div>
                        )}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              value={inputReturnToVal}
                              on
                              onChange={(e) => handleSearch(e, null, null, null, true)}
                              variant="filled"
                              onFocus={() => {
                                window.$("#return-to-airport").removeClass("mark-red");
                              }}
                              onBlur={(e) => {
                                handleReturnArrivalValue(e, e.target.value);
                              }}
                            />
                          );
                        }}
                      />
                    </div>
                    <span className="err-msg" id="return-to-airport">
                      Please enter valid return to airport
                    </span>
                  </div>
                </div>
              </div>
              </div>
            }
          </div>
          <div className="col-xs-12 custom-col-xs-6 col-sm-6 col-md-5 col-lg-3 _mv-10">
            <div className="middle-search-bar">
              <div className="row" data-gutter="none">
                <div className="col-xs-12 col-sm-12 col-md-12">
                  <div className="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-no-border theme-search-area-section-curved">
                    <div className="theme-search-area-section-inner">
                      {SearchForm.trip === 0 ? (
                        <DateRangePicker
                          readOnly
                          showInputIcon
                          isOutsideRange={isOutsideRange}
                          startDate={startDate}
                          startDatePlaceholderText={""}
                          startDateId="your_unique_start_date_id"
                          endDatePlaceholderText={""}
                          endDate={endDate}
                          endDateId="your_unique_end_date_id"
                          onDatesChange={({ startDate, endDate }) =>
                            onDateRangePicker(startDate, endDate)
                          }
                          focusedInput={date}
                          onFocusChange={handleDateInputChange}
                          displayFormat="ddd, DD MMM"
                          minimumNights={1}
                          numberOfMonths={monthCount}
                          keepOpenOnDateSelect
                          screenReaderInputMessage={" "}
                          onFocus={() => {
                            window.$("#depart-date").removeClass("mark-red");
                          }}
                        >
                          <i className=" lin lin-plane depart-icon" />
                        </DateRangePicker>
                      ) : (
                        <SingleDatePicker
                          date={startDate}
                          onDateChange={(value) =>
                            onDateRangePicker(value, null, true)
                          }
                          focused={date}
                          isFocused={!!date}
                          showInputIcon
                          placeholder="Depart Date"
                          onFocusChange={(focused) =>
                            handleDateInputChange(focused, true)
                          }
                          numberOfMonths={1}
                          isOutsideRange={isOutsideRange}
                          displayFormat="ddd, DD MMM"
                          screenReaderInputMessage="Depart Date"
                          onFocus={() => {
                            window.$("#depart-date").removeClass("mark-red");
                          }}
                        />
                      )}
                    </div>
                    <span className="err-msg" id="depart-date">
                      {!startDate
                        ? "Please enter valid departure date"
                        : SearchForm.trip === 0 && !endDate
                        ? "Please enter valid arrival date"
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              {SearchForm.trip === 2 &&
                <div className="_mt-15">
                <div className="row" data-gutter="none">
                  <div className="col-xs-12 col-sm-12 col-md-12">
                    <div className="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-no-border theme-search-area-section-curved">
                      <div className="theme-search-area-section-inner">
                        <SingleDatePicker
                          date={endDate}
                          onDateChange={(value) =>
                            onDateRangePicker2(value)
                          }
                          focused={date2}
                          isFocused={!!date2}
                          showInputIcon
                          placeholder="Return Date"
                          onFocusChange={(focused) =>
                            handleDateInputChange2(focused, true)
                          }
                          numberOfMonths={1}
                          isOutsideRange={isOutsideRangeMultiCity}    
                          displayFormat="ddd, DD MMM"
                          screenReaderInputMessage="Return Date"
                          onFocus={() => {
                            window.$("#return-to-date").removeClass("mark-red");
                          }}
                          initialVisibleMonth={() => startDate || moment()}
                        />
                      </div>
                      <span className="err-msg" id="return-to-date">
                        {SearchForm.trip === 2 && !endDate ? "Please enter valid arrival date" : ""}
                      </span>
                    </div>
                  </div>
                </div>
                </div>
              }
            </div>
          </div>
          <div 
            className={`col-xs-12 ${SearchForm.trip === 2 ? "custom-col-xs-6 col-sm-6 col-md-5 col-lg-3": "custom-col-xs-3 col-sm-3 col-md-2 col-lg-2"} _mv-10`}
          >
            <div className="row" data-gutter="none">
              <div className="col-xs-12 col-md-12 ">
                <div
                  className="theme-search-area-section theme-search-area-section-fade-white theme-search-area-section-mr theme-search-area-section-no-border theme-search-area-section-curved quantity-selector"
                  data-increment="Passengers"
                >
                  <div className="theme-search-area-section-inner">
                    {/* <span className="input-placeholder">Passengers & Cabin Class</span> */}
                    {/*<i className="theme-search-area-section-icon lin lin-people"></i>*/}
                    <img
                      className="theme-search-area-section-icon traveller-icon"
                      src={"/assets/ficon/friend.svg"}
                      alt="depart"
                    />
                    <Popup
                      ref={travelRef}
                      trigger={
                        <button
                          type="button"
                          className="theme-search-area-section-input traveller-btn"
                          style={{ color: "#404040", textAlign: "left" }}
                          onFocus={() => {
                            window.$("#travllers-btn").removeClass("mark-red");
                          }}
                        >
                          <div style={{ lineHeight: 1 }}>
                            <div>{pList} {pList > 1 ? "Travellers" : "Traveller"}</div>
                            <div className="cabin-btn">
                              {SearchForm?.cabin ? CABIN_OPTIONS.find(x => x.value === SearchForm.cabin).label : ""}
                            </div>
                          </div>
                        </button>
                      }
                      position="bottom center"
                      closeOnDocumentClick
                    >
                      <label className="passenger-label">Passengers</label>
                      <PassengersInput
                        totalTravelers={pList}
                        adult={SearchForm.adult}
                        child={SearchForm.child}
                        infant={SearchForm.infant}
                        changeAdultEvent={(val) => {
                          SetSearchForm((prev) => ({ ...prev, adult: val }));
                        }}
                        changeChildEvent={(val) => {
                          SetSearchForm((prev) => ({ ...prev, child: val }));
                        }}
                        changeInfantEvent={(val) => {
                          SetSearchForm((prev) => ({ ...prev, infant: val }));
                        }}
                        className=""
                      />
                      <div className="cabin-section">
                        <div className="_pt-10">
                        <label className="passenger-label">Cabin Class</label>
                        <Select
                          placeholder="Select Cabin"
                          defaultValue={SearchForm?.cabin ? CABIN_OPTIONS.find(x => x.value === SearchForm.cabin) : CABIN_OPTIONS[0]}
                          onChange={(val) => {
                            SetSearchForm((prev) => ({ ...prev, cabin: val.value }))
                          }}
                          value={SearchForm?.cabin ? CABIN_OPTIONS.find(x => x.value === SearchForm.cabin) : CABIN_OPTIONS[0]}
                          options={CABIN_OPTIONS}
                          menuPlacement="auto"
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              border: "1px solid #8596a3",
                              borderRadius: "4px",
                              color: "#555"
                            }),
                            option: (styles) => {
                              return {
                                ...styles,
                                padding: "3px"
                              };
                            },
                            menuList: (provided, state) => ({
                              ...provided,
                              paddingTop: 0,
                              paddingBottom: 0,
                           }),
                          }}
                        />
                        </div>
                      </div>
                      <button className="traveler-done" onClick={closeTraveler}>
                        Done
                      </button>
                    </Popup>
                  </div>
                </div>
                <span style={{ left: "16px" }} className="err-msg" id="travllers-btn">
                 Infants cannot be more than adults
                </span>
              </div>
            </div>

            {SearchForm.trip === 2 && <div className="row _mt-15">
                <div className="col-xs-6 custom-col-xs-12 col-sm-12 col-md-4 col-lg-12">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (checkDetials()) {
                        if (checkAirport()) {
                          setObjQP(SearchForm);
                          SearchContxt.isFromFlightDetails(false)
                          SearchContxt.submitSearch(SearchForm);
                          // SearchContxt.fetchFlightList(SearchForm);
                          if (props.handleSearchClick) {
                            props.handleSearchClick();
                          }
                          if (props.history) {
                            const data = getQueryData(window.location.search);
                            const newData = {
                              ...data,
                              from:
                                data && data.from
                                  ? data.from.includes("-")
                                    ? data.from.slice(data.from.indexOf("- ") + 2)
                                    : data.from
                                  : undefined,
                              to:
                                data && data.to
                                  ? data.to.includes("-")
                                    ? data.to.slice(data.to.indexOf("- ") + 2)
                                    : data.to
                                  : undefined,
                              from1:
                                  data && data.from1
                                    ? data.from1.includes("-")
                                      ? data.from1.slice(data.from1.indexOf("- ") + 2)
                                      : data.from1
                                    : undefined,
                                to1:
                                  data && data.to1
                                    ? data.to1.includes("-")
                                      ? data.to1.slice(data.to1.indexOf("- ") + 2)
                                      : data.to1
                                    : undefined,    
                            };
                            props.history.replace(
                              "/search?" + qs.stringify(newData)
                            );
                          }
                        }
                      }
                    }}
                    className="theme-search-area-submit _mt-0 _tt-uc theme-search-area-submit-no-border theme-search-area-submit-curved"
                  >
                    {/* <i className="theme-search-area-submit-icon">Search</i> */}
                    <span className="_desk-h">Search</span>
                  </button>
                </div>
              </div>
            }

          </div>
          {SearchForm.trip !== 2 &&
            <div className="col-xs-12 custom-col-xs-3 col-sm-3 col-md-12 col-lg-2 _mv-10">
              <div className="row">
                <div className="col-xs-6 custom-col-xs-12 col-sm-12 col-md-4 col-lg-12 _m-a search-button">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (checkDetials()) {
                        if (checkAirport()) {
                          setObjQP(SearchForm);
                          setInputReturnFromVal("")
                          setInputReturnToValue("")
                          SearchContxt.isFromFlightDetails(false)
                          SearchContxt.submitSearch(SearchForm);
                          // SearchContxt.fetchFlightList(SearchForm);
                          if (props.handleSearchClick) {
                            props.handleSearchClick();
                          }
                          if (props.history) {
                            const data = getQueryData(window.location.search);
                            const newData = {
                              ...data,
                              from:
                                data && data.from
                                  ? data.from.includes("-")
                                    ? data.from.slice(data.from.indexOf("- ") + 2)
                                    : data.from
                                  : undefined,
                              to:
                                data && data.to
                                  ? data.to.includes("-")
                                    ? data.to.slice(data.to.indexOf("- ") + 2)
                                    : data.to
                                  : undefined,
                              from1: undefined,
                              to1: undefined
                            };
                            props.history.replace(
                              "/search?" + qs.stringify(newData)
                            );
                          }
                        }
                      }
                    }}
                    className="theme-search-area-submit _mt-0 _tt-uc theme-search-area-submit-no-border theme-search-area-submit-curved"
                  >
                    {/* <i className="theme-search-area-submit-icon">Search</i> */}
                    <span className="_desk-h">Search</span>
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </>
  );
};
export default withRouter(memo(FlightSearchBar));
