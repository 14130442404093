import React, { useContext, useEffect, useState } from "react";
import RoundTrip from "../../SearchResult/RoundTrip";
import Context from "../../../Context/Context";
import { TripSummary } from "../../FlightDetails/TripSummary";
import SingleTrip from "../../SearchResult/SingleTrip";
import FlightService from "../../../Service/FlightService";
import SearchResult from "../../SearchResult/SearchResult";
import { getCurrency } from "../../../Utils/utils";
import _ from "lodash";

const BookingFlightDetail = (props) => {
  const [airportName, setAirportName] = useState([]);
  const [airlineName, setAirlineName] = useState([]);
  const [pricingInfo, setPricingInfo] = useState([]);
  const [flightDetail, setFlightDetail] = useState([]);
  const [adultFare, setAdultFare] = useState([]);
  const [childFare, setChildFare] = useState([]);
  const [infantFare, setInfantFare] = useState([]);

  const flightServ = new FlightService();
  let id = props.match.params.id;
  const bookedContext = useContext(Context);
  useEffect(() => {
    flightServ.bookingHistoryDetail(id).then(async (res) => {
      let airdata = JSON.parse(res.detail);
      let flightDetail = JSON.parse(res.body).flightDetail;

      const airportCodes = new Set();
      const airlineCodes = new Set();
      flightDetail.outwardSegment.map((leg) => {
        airportCodes.add(leg.arrival.iataCode);
        airportCodes.add(leg.departure.iataCode);
        airlineCodes.add(leg.carrierCode);
      });

      flightDetail.inwardSegment.map((leg) => {
        airportCodes.add(leg.arrival.iataCode);
        airportCodes.add(leg.departure.iataCode);
        airlineCodes.add(leg.carrierCode);
      });

      const airportCodeResult = await flightServ.ListgetAirportCode([
        ...airportCodes,
      ]);
      const airlineCodeResult = await flightServ.getAirLineCode([
        ...airlineCodes,
      ]);
      setAirlineName(airlineCodeResult);
      setAirportName(airportCodeResult);
      setFlightDetail(flightDetail);
    });
  }, []);
  return (
    <div class="bg-light py-4">
      <div class="container py-4">
        <div class="row">
          <div class="col-sm-8">
            <div class="flight-pax">
              <h4>Flights details</h4>
              {(flightDetail.length || flightDetail.id) && (
                <SearchResult
                  airportNames={airportName}
                  airlineNames={airlineName}
                  // fareRule={fareRule}
                  flightList={{
                    data: flightDetail ? [flightDetail] : [],
                    oneWay: flightDetail && flightDetail.type === "SingleTrip",
                  }}
                  {...props}
                />
              )}
              {/* {bookedContext.flightList.oneWay ? (
                <SingleTrip
                  {...props}
                  airlineName={bookedContext.bookedFlightDetail.airlineName}
                  flightLists={bookedContext.bookedFlightDetail}
                  airportName={airportName}
                />
              ) : (
                <RoundTrip
                  {...props}
                  airlineName={bookedContext.bookedFlightDetail.airlineName}
                  flightLists={bookedContext.bookedFlightDetail}
                  airportName={airportName}
                />
              )} */}

              <div id="paySection"></div>
            </div>
          </div>
          <div class="col-sm-4">
            <TripSummary
              // pricingInfo={pricingInfo}
              adultFare={flightDetail?.AdultFares}
              childFare={flightDetail?.ChildFares}
              infantFare={flightDetail?.InfantFares}
              totalPrice={flightDetail?.totalFare?.amount}
             currency={getCurrency()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingFlightDetail;
