import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import base64Img from "base64-img";

import "react-toastify/dist/ReactToastify.css";

import { RegisterUtils } from "../../Service/UtilsService";
import userService from "../../Service/UserService";

export default class Registration extends Component {
  constructor() {
    super();
    this.state = {
      form: {
        name: "",
        email: "",
        password: "",
        confirmPassword: ""
      },
      formErrors: {
        name: null,
        email: null,
        password: null,
        confirmPassword: null
      },
      banner: {
        bannerimage: ""
      }
    };
    this.userServ = new userService();
  }

  componentDidMount() {
    window.scroll(0, 0);
    let ths = this;
    async function fetchData() {
      const result = await RegisterUtils();
      if (sessionStorage.getItem("registration")) {
        result.bannerimage = sessionStorage.getItem("registration");
        ths.setState({ banner: result });
      } else {
        base64Img.requestBase64(result.bannerimage, (err, res, body) => {
          sessionStorage.setItem("registration", body);
          result.bannerimage = sessionStorage.getItem("registration");
          ths.setState({ banner: result });
        });
      }
    }
    fetchData();
  }

  handleChange = e => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    // handle change event except language field
    formObj = {
      ...form,
      [name]: value
    };

    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};
      if (name === "password" || name === "confirmPassword") {
        let refValue = this.state.form[name === "password" ? "confirmPassword" : "password"];
        const errorMsg = this.validateField(name, value, refValue);
        formErrorsObj = { ...formErrors, [name]: errorMsg };
        if (!errorMsg && refValue) {
          formErrorsObj.confirmPassword = null;
          formErrorsObj.password = null;
        }
      } else {
        const errorMsg = this.validateField(name, name === "language" ? this.state.form["language"] : value);
        formErrorsObj = { ...formErrors, [name]: errorMsg };
      }
      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "name":
        if (!value) errorMsg = "Please enter Name.";
        break;
      case "email":
        if (!value) errorMsg = "Please enter Email.";
        else if (
          !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        )
          errorMsg = "Please enter valid Email.";
        break;
      case "password":
        // refValue is the value of Confirm Password field
        if (!value) errorMsg = "Please enter Password.";
        else if (refValue && value !== refValue) errorMsg = "Password and Confirm Password does not match.";
        break;
      case "confirmPassword":
        // refValue is the value of Password field
        if (!value) errorMsg = "Please enter Confirm Password.";
        else if (refValue && value !== refValue) errorMsg = "Password and Confirm Password does not match.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map(x => {
      let refValue = null;
      if (x === "password" || x === "confirmPassword") {
        refValue = form[x === "password" ? "confirmPassword" : "password"];
      }
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    }
    // if (form) {
    //   delete form.confirmPassword;
    // }

    this.userServ
      .addUser(form)
      .then(response => {
        toast.success(response.message);
        setTimeout(() => {
          this.props.history.push("/login");
        }, 1000);
      })
      .catch(err => {
        toast.error(err.toString());
      });
  };

  render() {
    const { form, formErrors } = this.state;
    return (
      <div className="theme-hero-area">
        <div className="theme-hero-area-bg-wrap">
          <div
            className="theme-hero-area-bg"
            style={{ backgroundImage: `url(${this.state.banner.bannerimage})` }}
          ></div>
          <div className="theme-hero-area-mask theme-hero-area-mask-strong"></div>
        </div>
        <div className="theme-hero-area-body">
          <div className="theme-page-section _pt-100 theme-page-section-xl">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-md-offset-4">
                  <div className="theme-login theme-login-white">
                    <div className="theme-login-header">
                      <h1 className="theme-login-title">{this.state.banner.title}</h1>
                      <p className="theme-login-subtitle">{this.state.banner.subtitle}</p>
                    </div>
                    <div className="theme-login-box">
                      <div className="theme-login-box-inner">
                        <form className="theme-login-form">
                          <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              value={form.name}
                              onChange={this.handleChange}
                              onBlur={this.handleChange}
                              placeholder="Name"
                            />
                            {formErrors.name && <span className="err">{formErrors.name}</span>}
                          </div>
                          <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              value={form.email}
                              onChange={this.handleChange}
                              onBlur={this.handleChange}
                              placeholder="Email"
                            />
                            {formErrors.email && <span className="err">{formErrors.email}</span>}
                          </div>
                          <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              value={form.password}
                              onChange={this.handleChange}
                              onBlur={this.handleChange}
                              placeholder=" Password"
                            />
                            {formErrors.password && <span className="err">{formErrors.password}</span>}
                          </div>
                          <div className="form-group theme-login-form-group">
                            {/* <label>
                              Confirm Password:
                              <span className="asterisk">*</span>
                            </label> */}
                            <input
                              className="form-control"
                              type="password"
                              name="confirmPassword"
                              value={form.confirmPassword}
                              onChange={this.handleChange}
                              onBlur={this.handleChange}
                              placeholder="Confirm Password"
                            />
                            {formErrors.confirmPassword && <span className="err">{formErrors.confirmPassword}</span>}
                          </div>
                          <button
                            className="btn btn-uc btn-dark btn-block btn-lg"
                            onClick={this.handleSubmit}
                            type="button"
                          >
                            Create Account
                          </button>
                        </form>
                      </div>
                      <div className="theme-login-box-alt">
                        <p>
                          Already have an account? &nbsp;
                          {/* <a href="login-2.html">Si gn in.</a> */}
                          <Link to="/login">Sign in.</Link>
                        </p>
                      </div>
                    </div>
                    <p className="theme-login-terms">
                      By signing up you accept our
                      <a href="#"> terms of use</a>
                      <br />
                      and
                      <a href="#"> privacy policy</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<ToastContainer />*/}
      </div>
    );
  }
}

// export default App;

{
  /* <div className="signup-box">
        <p className="title">Sign up</p>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                Name:<span className="asterisk">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="name"
                value={form.name}
                onChange={this.handleChange}
                onBlur={this.handleChange}
              />
              {formErrors.name && (
                <span className="err">{formErrors.name}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Email:<span className="asterisk">*</span>
              </label>
              <input
                className="form-control"
                type="text"
                name="email"
                value={form.email}
                onChange={this.handleChange}
                onBlur={this.handleChange}
              />
              {formErrors.email && (
                <span className="err">{formErrors.email}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Password:<span className="asterisk">*</span>
              </label>
              <input
                className="form-control"
                type="password"
                name="password"
                value={form.password}
                onChange={this.handleChange}
                onBlur={this.handleChange}
              />
              {formErrors.password && (
                <span className="err">{formErrors.password}</span>
              )}
            </div>
            <div className="form-group">
              <label>
                Confirm Password:<span className="asterisk">*</span>
              </label>
              <input
                className="form-control"
                type="password"
                name="confirmPassword"
                value={form.confirmPassword}
                onChange={this.handleChange}
                onBlur={this.handleChange}
              />
              {formErrors.confirmPassword && (
                <span className="err">{formErrors.confirmPassword}</span>
              )}
            </div>
          </div>
        </div>
      </div> */
}
