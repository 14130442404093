import React, { useState, useEffect } from "react";
import usePrevious from "./usePrevious";

const useScrollPopup = ({ loading }) => {
  const [isScrollForPopup, setIsScrollForPopup] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const startPopupListener = () => {
    setTimeout(() => window.addEventListener(
      "scroll",
      (e) => {
        if (!isScrollForPopup) {
          let element = document.querySelector(`#flight-div div.theme-search-results-item:nth-child(6)`);
          element =
            element || document.querySelector(`#flight-div div.theme-search-results-item:last-child`);
          if (element) {
            if (element.offsetTop <= window.scrollY) setIsScrollForPopup(true);
          }
        }
      },
      { passive: true }
    ), 500)
    setShowPopup(false);
  };

  const oldLoading = usePrevious(loading);
  useEffect(() => {
    if (loading == false && oldLoading == true && showPopup) {
      setTimeout(() => startPopupListener(), 1000);
    }
  }, [loading]);

  return {
    isScrollForPopup,
    setIsScrollForPopup,
  };
};

export default useScrollPopup;
