const getMinMaxPrice = (data) => {
  const forLoop = () => {
    let IataCode = [];
    let initial = parseInt(data[0]?.AdultFares.perAdultFare);
    let min = initial,
      max = initial;

    for (let i = 0; i < data?.length; i++) {
      let value = parseInt(data[i]?.AdultFares.perAdultFare);
      min = value < min ? value : min;
      max = value > max ? value : max;

      let { inwardSegment, outwardSegment } = data[i] || {};

      for (let el2 of inwardSegment) {
        IataCode.push(el2.arrival.iataCode);
        IataCode.push(el2.departure.iataCode);
      }
      for (let el2 of outwardSegment) {
        IataCode.push(el2.arrival.iataCode);
        IataCode.push(el2.departure.iataCode);
      }
    }
    let uniqIataCodes = [...new Set(IataCode)];
    return [min, max, uniqIataCodes];
  };

  const [forLoopMin, forLoopMax, IataCodes] = forLoop();

  return { min: forLoopMin, max: forLoopMax, IataCodes };
};

const getMinPriceOnAirlines = async (flightContext, flightService) => {
  const { iataAirlineCodes } = flightContext;
  if (!flightContext.data.length) return false; // no meaning to find airlines if data lenth is zero
  return new Promise((resolve, reject) => {
    flightService.getAirLineCode(iataAirlineCodes).then((res) => {
      let newAirlines = [];
      let newAirlineNames = [];

      res.forEach((el) => {
        newAirlines.push(el.iata);
        newAirlineNames.push({
          name: el.name,
          iata: el.iata,
          minprice: 0,
        });
      });

      flightContext.data.forEach((el, i) => {
        // updating airlineNames  with minprice
        newAirlineNames = newAirlineNames.map((anElm) => {
          if (
            el.iataAirlineCodes.length > 0 &&
            el.iataAirlineCodes.includes(anElm.iata)
          ) {
            if (
              !anElm.minprice ||
              anElm.minprice > el.AdultFares.perAdultFare
            ) {
              anElm.minprice = el.AdultFares.perAdultFare;
            }
          }
          return anElm;
        });
      });

      resolve({
        airlines: newAirlines,
        airlineNames: newAirlineNames,
      });
    });
  });
};

const getMinPriceOnStops = (
  data,
  stops = [],
  oldStopData = [
    { value: "nonstop", label: "Direct", minprice: 0 },
    { value: "1stop", label: "1 Stop", minprice: 0 },
    // { value: "2stop", label: "1 + Stops", minprice: 0 },
  ]
) => {
  stops = stops?.length === 0 ? ["nonstop", "1stop", "2stop"] : stops;
  let stopsOnAllFlight = [
    { value: "nonstop", label: "Direct", minprice: 0 },
    { value: "1stop", label: "1 Stop", minprice: 0 },
    // { value: "2stop", label: "1 + Stops", minprice: 0 },
  ];
  data.forEach((el) => {
    stopsOnAllFlight = stopsOnAllFlight.map((anElm, i) => {
      if (
        (el.outwardStop === 0 && anElm.value === "nonstop") ||
        (el.outwardStop === 1 && anElm.value === "1stop") ||
        (el.outwardStop === 2 && anElm.value === "2stop") ||
        stops.length == 0
      ) {
        if (
          Number(anElm.minprice) === 0 ||
          anElm.minprice > el.AdultFares.perAdultFare
        ) {
          oldStopData[i].minprice = el.AdultFares.perAdultFare;
        }
      }
      return oldStopData[i];
    });
  });
  return stopsOnAllFlight;
};

const isSameObj = (a = {}, b = {}) => {
  return JSON.stringify(a || {}) === JSON.stringify(b || {});
};

const parsePrice = (value) => {
  const val = Number(value);
  if (!Number.isNaN(val)) {
    return parseInt(Number(val));
  }
  return 0;
};

const showCapitlized = (str = "") => {
  return str
    ?.split("_")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
    .join(" ");
};

export {
  getMinMaxPrice,
  getMinPriceOnStops,
  getMinPriceOnAirlines,
  isSameObj,
  parsePrice,
  showCapitlized,
};
