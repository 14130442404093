import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import logincontext from "../Context/Context";
import userService from "../Service/UserService";

import callcon from "./images/call-icon.svg";

export default function FlightLoader(props) {
  const userServ = new userService();
  const [show, setShow] = useState(false);
  let context = useContext(logincontext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const pathname = window.location.pathname;
      setShow(pathname === "/search" ? true : false);
    }
  }, []);

  useEffect(() => {
    if (props.show === "true") {
      handleShow();
    } else if (props.show === "false") {
      handleClose();
    }
  }, [props]);

  const { toFromData = {} } = props;

  return (
    <>
      <Modal
        dialogClassName="flightloader"
        centered
        show={show}
        animation={false}
      >
        <Container>
          <Row
            style={{
              flexWrap: "nowrap",
              height: "100%",
              width: "100%",
              margin: 0,
            }}
          >
            {/*    <Col md={8} style={{marginTop: 15, paddingLeft: 50}}>
                            <div className="loader-logo">
                                <img
                                    src={context.SettingList && context.SettingList.main_logo}
                                    alt="Flights Travel"
                                    title="Flights Travel"
                                />
                            </div>
                            <div style={{marginRight: 30}}>
                                <h2>Call us now to get the best fares</h2>
                                <div style={{display: "flex"}}>
                                    <img
                                        src={callcon}
                                        className="PhoneImage-module_tada__1Xx31"
                                    />
                                    <h2 style={{marginLeft: 10, fontWeight: "bold"}}>
                                        <a
                                            style={{color: "#ff7235"}}
                                           href={`tel:${context.SettingList.contact_phone}`}>
                                        {context.SettingList.contact_phone}</a>
                                    </h2>
                                </div>
                            </div>
                            <div>
                                <h3>Searching for our cheapest fares From</h3>
                                <h3>
                                    <span style={{color: "#ff7235"}}>
                                        {`${context.searchReasult.from} `}
                                    </span>
                                    to
                                    <span style={{color: "#ff7235"}}>
                                        {` ${context.searchReasult.to}.`}
                                    </span>
                                </h3>
                                <h5 className="margin-top-20">
                                    Departure Date : &nbsp;
                                    <span style={{color: "#ff7235"}}>
                                        {context.searchReasult.depart}
                                    </span>
                                </h5>
                                <h5 className="margin-top-10">
                                    Arrival Date : &nbsp;
                                    <span style={{color: "#ff7235"}}>
                                        {context.searchReasult.arrival}
                                    </span>
                                </h5>
                                <h4 className="margin-top-20">It will take few more seconds</h4>
                            </div>
                            <div className="loader"><img src={"/assets/ficon/Progress-Loading.gif"}/></div>
                            <div className="col-md-12 flightBulletContainer" style={{marginBottom: 50}}>
                                <div className="col-md-2 flightloadingbulletpoints">
                                    <div style={{cursor: "pointer"}} className="steps-bullet">
                                        <img src={'/assets/ficon/pay (1).png'} className="fa fa-check check-icon"/>
                                        <p
                                            style={{
                                                display: "inline-table"
                                            }}>No fee on Debit/Credit Cards</p>
                                    </div>
                                </div>
                                <div className="col-md-2 flightloadingbulletpoints">
                                    <div style={{cursor: "pointer"}} className="steps-bullet">
                                        <img src={'/assets/ficon/money.png'} className="fa fa-check check-icon"/>
                                        <p style={{display: "inline-table"}}>Lowest Price Guaranteed</p>
                                    </div>
                                </div>
                                <div className="col-md-2 flightloadingbulletpoints">
                                    <div style={{cursor: "pointer"}} className="steps-bullet">
                                        <img src={'/assets/ficon/safety.png'} className="fa fa-check check-icon"/>
                                        <p
                                            style={{display: "inline-table"}}>100% Financial protection
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-2 flightloadingbulletpoints">
                                    <div style={{cursor: "pointer"}} className="steps-bullet">
                                        <img src={'/assets/ficon/booking.png'} className="fa fa-check check-icon"/>
                                        <p style={{display: "inline-table"}}>
                                            90% Happy repeat customers</p>
                                    </div>
                                </div>*/}
            {/*   <div class="getmedeals ">
                                <h5>
                                    Be the first to know when fares drop. This will not interrupt your search.
                                </h5>
                                <div style={{display: "flex"}}>
                                    <input
                                        className="form-control getmedealsemail"
                                        type="text"
                                        name="email"
                                        placeholder="Email Address"
                                    />
                                    <button
                                        type="button"
                                        className="btn btn-uc btn-primary-inverse btn-block getmedealsbutton">
                                        Get me deals
                                    </button>
                                </div>
                                <div
                                    className="col-md-12 theme-login-form-group"
                                    style={{marginBottom: "0px",paddingLeft: 0}}
                                >
                                    <p
                                        className="form-control SignupModalConditions"
                                        style={{fontSize: "14px",paddingLeft: 0}}
                                    >
                                        By signing up you accept our <a href="/pg/terms_conditions" target="_blank">terms
                                        of use</a> and <a href="/pg/privacy_policy" target="_blank">privacy policy.</a>
                                    </p>
                                </div>
                            </div>*/}
            {/*</div>*/}
            {/*</Col>*/}
            {/*  <Col md={8} style={{textAlign: "center"}} className="big-image-loader">
                            <img style={{width: "75%", height: "100%", padding: 50}}
                                 src="/assets/ficon/flight_loader_big_image.png"/>
                        </Col>*/}
            <div className="loader-call-us-section">
              <div className="loader-logo">
                <img
                  src={context.SettingList && context.SettingList.main_logo}
                  alt="Flights Travel"
                  title="Flights Travel"
                />
              </div>
              <div>
                <h3>
                  {props.bookNow
                    ? "Checking availibility of your flight"
                    : "Searching for our cheapest fares"}{" "}
                  <div style={{ marginTop: "10px" }}>
                    <span>from &nbsp;</span>
                    <span style={{ color: "#ff7235", fontWeight: 600 }}>
                      {context.searchReasult.from}{" "}
                    </span>
                    to
                    <span style={{ color: "#ff7235", fontWeight: 600 }}>
                      {" "}
                      {context.searchReasult.to}
                    </span>
                  </div>
                </h3>
                {/* <h3 className="margin-top-20 font-16">
                                    Departure Date : &nbsp;
                                    <span style={{color: "#ff7235"}}>
                                        {context.searchReasult.depart}
                                    </span>
                                </h3>
                                <h3 className="margin-top-10 font-16">
                                    Arrival Date : &nbsp;
                                    <span style={{color: "#ff7235"}}>
                                        {context.searchReasult.arrival}
                                    </span>
                                </h3>*/}
                <h4 className="margin-top-20">It will take few more seconds</h4>
              </div>
              <div className="loader">
                <img src={"/assets/ficon/Progress-Loading.gif"} alt="loading" />
              </div>
              <div className="col-md-12 flightBulletContainer">
                <div className="col-md-4 flightloadingbulletpoints">
                  <div style={{ cursor: "pointer" }} className="steps-bullet">
                    <img
                      src={"/assets/ficon/pay (1).png"}
                      className="fa fa-check check-icon"
                      alt="check-icon"
                    />
                    <p
                      style={{
                        display: "inline-table",
                      }}
                    >
                      No fee on Debit/Credit Cards
                    </p>
                  </div>
                </div>
                <div className="col-md-4 flightloadingbulletpoints">
                  <div style={{ cursor: "pointer" }} className="steps-bullet">
                    <img
                      src={"/assets/ficon/money.png"}
                      className="fa fa-check check-icon"
                      alt="check-icon"
                    />
                    <p style={{ display: "inline-table" }}>
                      Lowest Price Guaranteed
                    </p>
                  </div>
                </div>
                <div className="col-md-4 flightloadingbulletpoints">
                  <div style={{ cursor: "pointer" }} className="steps-bullet">
                    <img
                      src={"/assets/ficon/safety.png"}
                      className="fa fa-check check-icon"
                      alt="check-icon"
                    />
                    <p style={{ display: "inline-table" }}>
                      100% Financial protection
                    </p>
                  </div>
                </div>
                <div className="col-md-4 flightloadingbulletpoints">
                  <div style={{ cursor: "pointer" }} className="steps-bullet">
                    <img
                      src={"/assets/ficon/booking.png"}
                      className="fa fa-check check-icon"
                      alt="check-icon"
                    />
                    <p style={{ display: "inline-table" }}>
                      90% Happy repeat customers
                    </p>
                  </div>
                </div>
                <div className="col-md-4 flightloadingbulletpoints">
                  <div style={{ cursor: "pointer" }} className="steps-bullet">
                    <img
                      src={"/assets/ficon/salary.png"}
                      className="fa fa-check check-icon"
                      alt="check-icon"
                    />
                    <p style={{ display: "inline-table" }}>
                      Refer and Earn Unlimited
                    </p>
                  </div>
                </div>
                <div className="col-md-4 flightloadingbulletpoints">
                  <div style={{ cursor: "pointer" }} className="steps-bullet">
                    <img
                      src={"/assets/ficon/healthcare.png"}
                      className="fa fa-check check-icon"
                      alt="check-icon"
                    />
                    <p
                      style={{
                        display: "inline-table",
                        minWidth: 185,
                        textAlign: "left",
                      }}
                    >
                      Your health our priority
                    </p>
                  </div>
                </div>
              </div>
              <Col md={24}>
                <Col md={1} />
                <Col md={2}>
                  Your holiday is protected by ATOL and TTA Trust Model.
                </Col>
                <Col md={8}>
                  <ul
                    className="footer-logo-link-ul footer-loader-logos"
                    style={{ justifyContent: "space-around" }}
                  >
                    <li>
                      <img
                        src={"/assets/ficon/atol_logo1.png"}
                        alt="no-fee"
                        className="atol"
                      />
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      <a
                        href="https://www.thetravelnetworkgroup.co.uk/verify_member"
                        target="_blank"
                      >
                        <img
                          src={"/assets/ficon/white_images/TTA_1.png"}
                          alt="no-fee"
                          className="tta"
                        />
                      </a>
                    </li>
                    <li style={{ paddingTop: "10px" }}>
                      <a
                        href="https://www.flynowpaylater.com/en/travellers"
                        target="_blank"
                      >
                        <img
                          src={"/assets/ficon/white_images/FNPL-logo2.png"}
                          className="flynow"
                          alt="flynow"
                        />
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md={1} />
              </Col>
            </div>
          </Row>
          {/*<ToastContainer/>*/}
          <img
            className="loader-bottom-bg"
            src={"/assets/ficon/loader-bottom-bg.png"}
            alt="loader"
          />
        </Container>
      </Modal>
    </>
  );
}
