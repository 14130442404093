import React, { memo, useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { isSameObj } from "./New/helper";
import usePrevious from "./New/Hooks/usePrevious";
import RoundTrip from "./RoundTrip";
import SingleTrip from "./SingleTrip";
import Context from "../../Context/Context";

const CABINS = {
  1: "First",
  2: "Business",
  3: "Premium Economy",
  4: "Economy",
};

const SearchResult = ({ airlineNames = [], airportNames = [], ...props }) => {
  let FlightContext = useContext(Context);
  const [airlines, setAirlines] = useState({});
  const [airports, setAirports] = useState({});
  const [filter, setFilter] = useState(false)
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const cabinQuery = urlParams.get("cabin");
  // const oldAirports = usePrevious(airportNames);
  // const oldAirlines = usePrevious(airlineNames);

  useEffect(() => {
    const tempAirPort = {};
    /* eslint-disable no-unused-expressions */
    airportNames.forEach((x) => (tempAirPort[x.iata] = x));
    if (!isSameObj(tempAirPort, airports)) {
      setAirports(tempAirPort);
    }
  }, [airportNames]);

  useEffect(() => {
    const tempAirLine = {};
    airlineNames.forEach((x) => (tempAirLine[x.iata] = x));
    if (!isSameObj(tempAirLine, airlines)) {
      setAirlines(tempAirLine);
    }
  }, [airlineNames]);

  const getAirlineName = (code, key = "name") => {
    return airlines[code] && airlines[code][key];
  };

  const getAirportName = (code, key = "airport_name") => {
    return airports[code] && airports[code][key];
  };
  const tripProps = {
    ...props,
    getAirlineName,
    getAirportName,
    flightClass: CABINS[cabinQuery],
    airlineName: airlineNames,
    airportName: airportNames,
    flightLists: props.flightList,
  };

  useEffect(() => {
    if (FlightContext?.flightList?.searchLoader === false && filter && props?.location?.pathname === "/search") {
      setFilter(false)
    }
  }, [FlightContext.flightList.searchLoader])

  useEffect(() => {
    if (!props.flightList?.data?.length && FlightContext?.flightList?.data?.length && props?.location?.pathname === "/search") {
      setFilter(true)
    }
    return () => setFilter(false)
  }, [props.flightList?.data?.length])

  if (!FlightContext?.flightList?.data?.length && props?.location?.pathname === "/search") {
    return (
      <div className="no-flights-result">
        <h4>Sorry, there aren't any flights that match your filters</h4>
      </div>
    );
  }

    if (!props?.flightList?.data?.length && filter && props?.location?.pathname === "/search") {
      return (
        <div className="no-flights-result">
          <h4>Sorry, there aren't any flights that match your filters</h4>
        </div>
      );
    }

  
  if (props.flightList?.data?.length === 0) {
    return (
      <div className="no-flights-result">
          <h4>Please wait......</h4>
      </div>
    );
  } else
    return props.flightList.oneWay ? (
      <SingleTrip {...tripProps} />
    ) : (
      <RoundTrip {...tripProps} />
    );
};

// export default memo(SearchResult, (newProps, oldProps) =>
//   isSame(newProps, oldProps)
// );
export default memo(SearchResult, (prevProps, nextProps) => {
  const {
    airlineNames: oldAirlineNames,
    airportNames: oldAirportNames,
    flightList: oldFlightList,
  } = prevProps;

  return (
    isSameObj(oldAirlineNames, nextProps.airlineNames) &&
    isSameObj(oldAirportNames, nextProps.airportNames) &&
    isSameObj(oldFlightList, nextProps.flightList)
  );
});
