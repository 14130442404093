import React from "react";

const PayOnlineSuccess = () => {
  return (
    <div class="theme-page-section theme-page-section-xl theme-page-section-gray">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-md-offset-4">
            <div class="theme-payment-success">
              <div class="theme-payment-success-header">
                <i class="fa fa-check-circle theme-payment-success-header-icon"></i>
                <h1 class="theme-payment-success-title">Payment Successful</h1>
                <p class="theme-payment-success-subtitle">Your tickets will be with you shortly.
                  <br/>Please contact our agents at &nbsp;
                  <a href='02080904904'>
                      <i className="fa fa-phone" style={{ cursor: "pointer" }}></i>  02080904904
                  </a> &nbsp;  for any enquiries.
                </p>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PayOnlineSuccess;
