import util from "../Utils/utils";

export let HomeUtils = async () => {
  try {
    let body = {
      home: "home",
    };
    let response = await util.sendApiRequest("/utils/home", "POST", true, body);
    return response;
  } catch (err) {
    return err;
  }
};
export let LoginUtils = async () => {
  try {
    let response = await util.sendApiRequest(
      "/banner/front/login",
      "GET",
      true
    );
    return response;
  } catch (err) {
    return err;
  }
};
export let RegisterUtils = async () => {
  try {
    let response = await util.sendApiRequest(
      "/banner/front/signup",
      "GET",
      true
    );
    return response;
  } catch (err) {
    return err;
  }
};
export let FlightSearchUtils = async () => {
  try {
    let response = await util.sendApiRequest(
      "/banner/front/flightsearch",
      "GET",
      true
    );
    return response;
  } catch (err) {
    return err;
  }
};
export let BannerUtils = async (pagename) => {
  // single banner fetch
  try {
    let response = await util.sendApiRequest(
      "/banner/front/" + pagename,
      "GET",
      true
    );
    return response;
  } catch (err) {
    return err;
  }
};

export let GalleryImageUtils = async (tabId) => {
  try {
    let response = await util.sendApiRequest(
      "/utils/home/" + tabId,
      "GET",
      true
    );
    return response;
  } catch (err) {
    return err;
  }
};
export let footerSettingUtils = async () => {
  try {
    let response = await util.sendApiRequest("/utils/footer/", "GET", true);
    return response;
  } catch (err) {
    return err;
  }
};
export let EmergencyMessageUtils = async () => {
  try {
    let response = await util.sendApiRequest(
      "/utils/emergencymessage/",
      "GET",
      true
    );
    return response;
  } catch (err) {
    return err;
  }
};
//  pagges banner utils
export let PagesBannerUtils = async (pagename) => {
  try {
    let response = await util.sendApiRequest(
      "/staticpage/pg/getAll",
      "GET",
      true
    );

    return response;
  } catch (err) {
    return err;
  }
};

// Pay Online
export let QuickPayment = async (data) => {
  try {
    return util.sendApiRequest("/flight/quick-payment", "POST", true, data);
  } catch (err) {
    return err;
  }
};

//fetch setting list

export function listSetting(data, start, perpage) {
  const setting = Object.keys(data).reduce((object, key) => {
    if (data[key] !== "") {
      object[key] = data[key];
    }
    return object;
  }, {});
  return util
    .sendApiRequest(
      "/setting/list/" + start + "/" + perpage,
      "POST",
      true,
      setting
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
}

// export function CampaignUtils(utm_campaign) {
//   try {
//     let response = util.sendApiRequest(
//       "/campaign/" + utm_campaign,
//       "GET",
//       true
//     );
//     return response;
//   } catch (err) {
//     return err;
//   }
// }
