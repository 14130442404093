import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GalleryImageUtils } from "../../Service/UtilsService";
import Context from "../../Context/Context";
import FlightSearchBar from "../SearchBar/FlightSearchBar";
import ReactHtmlParser from "react-html-parser";
import Iframe from "react-iframe";
import CallModal from "../Popup/CallModal";
import { Register } from "../../DefaultComponent/SignUp";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let timer = 0;
const Home = (props) => {
  let HomeContext = useContext(Context);
  const [counter, setCounter] = useState(0);
  const [show, setShow] = useState(false);

  let top_banner, middle_banner, card_banner;
  const [GalleryImage, setGalleryImage] = useState({ count: 0, rows: [] });

  if (HomeContext && HomeContext.home.banner) {
    [top_banner, card_banner, middle_banner] = HomeContext.home.banner.rows.map(
      (el) => {
        return el;
      }
    );
  }
  if (HomeContext.home.topDeal.count > 0) {
    setTimeout(() => {
      window.$(".owl-carousel").each(function () {
        const $carousel = window.$(this);
        $carousel.owlCarousel &&
          $carousel.owlCarousel({
            slideBy: $carousel.data("slideby"),
            center: $carousel.data("center"),
            loop: $carousel.data("loop"),
            margin: $carousel.data("margin"),
            autoHeight: false,
            autoplay: $carousel.data("autoplay"),
            autoplayTimeout: $carousel.data("autoplay-timeout"),
            navText: [
              '<span class="fa fa-angle-left _fs-xxl" />',
              '<span class="fa fa-angle-right _fs-xxl" />',
            ],
            responsive: {
              0: {
                items: 1,
                nav: $carousel.data("nav"),
              },
              600: {
                items: 2,
                nav: $carousel.data("nav"),
              },
              992: {
                items: 3,
                nav: $carousel.data("nav"),
              },
              1200: {
                items: $carousel.data("items"),
                dots: false,
                nav: $carousel.data("nav"),
              },
            },
          });
      });
    }, 700);
  }
  useEffect(() => {
    if (HomeContext.home.galleryTab.rows.length) {
      handleTabChange(null, HomeContext.home.galleryTab.rows[0].id);
    }
  }, [HomeContext.home.galleryTab.rows]);

  const handleTabChange = async (e, tabId) => {
    if (e && e.target) {
      e.target.scrollIntoView({
        block: "center",
        inline: "center",
        behavior: "smooth",
      });
    }
    let result = await GalleryImageUtils(tabId);
    setGalleryImage(result);
  };

  useEffect(() => {
    const rootElement = document.getElementById("root");
    let interval = setTimeout(() => {
      if (counter === 0 && timer === 0) {
        setCounter(counter + 1);
      }
    }, 10000);

    function intervalValues() {
      clearTimeout(interval);
      if (counter === 0 && timer === 0) {
        interval = setTimeout(() => {
          setCounter(counter + 1);
        }, 20000);
      }
    }

    rootElement.addEventListener("click", () => {
      setCounter(0);
      intervalValues();
    });
    rootElement.addEventListener("keypress", () => {
      intervalValues();
    });

    return () => {
      clearTimeout(interval);
    };
  }, []);

  useMemo(() => {
    if (counter === 1) {
      timer = 1;
    }
  }, [counter]);

  const notify = () =>
    toast("Wow so easy!", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  if (counter === 1) {
    window.$("#toId").blur();
    window.$(".p11.register-btn").focus();
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        onOpen={true}
      >
        {notify}
      </ToastContainer>

      {/* Main */}
      {/*scroll={window.scrollY}*/}
      {/* {counter === 1 ?
                <CallModal
                    phoneNo={HomeContext.SettingList.contact_phone}
                    price={HomeContext.SettingList.discounted_price}
                    counter={counter === 1}
                 /> : null} */}
      {/* {counter === 1 ?
      <CallModal scroll={window.scrollY} /> : null} */}
      {/* {show ? (
        <Register
          type="signup"
          show={show}
          handleClose={() => setShow(false)}
        />
      ) : null} */}
      <div className="theme-hero-area hero-desk">
        <div className="theme-hero-area-bg-wrap">
          <div
            className="theme-hero-area-bg"
            style={{
              backgroundImage: `url(${top_banner ? top_banner.bannerimage : ""
                })`,
            }}
          />
          <div
            className="theme-hero-area-grad-mask theme-hero-area-grad-mask-bold overlay"
          /*             style={{
                                    background: HomeContext.home.setting.values.home_main_background_gradient,
                                  }} */
          />
          <div
            className="blur-area"
            data-bg-area="#"
            data-blur-area="#hero-search-form"
            data-blur="20"
          />
        </div>

        <div className="theme-hero-area-body _pos-desk-v-c _w-f _pv-mob-90">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="theme-search-area theme-search-area-stacked theme-search-area-white">
                  <div className="theme-search-area-header _ta-c fix-h-title">
                    <h1 className="theme-search-area-title theme-search-area-title-lg">
                      {top_banner ? top_banner.title : ""}
                    </h1>
                    {/* <p className="theme-search-area-subtitle" style={{ fontSize: "19px", color: "#fff" }}>
                        {top_banner ? top_banner.subtitle : ""}
                      </p> */}
                  </div>
                  <FlightSearchBar history={props.history} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer bullet points */}
      <div className="container">
        {/*<div className="theme-inline-slider-item">
              <div onClick={ () => {HomeContext.handelModel({ type: "signup", value: true })}} style={{cursor: "pointer"}} className="bulletpoint-card">
                <i className="fa fa-user-plus bulletpoint-icons"></i>
                <div>
                  <p
                    className=""
                    style={{ display: "inline-table", width: "91%", paddingTop: "5px", margin: "0" }}
                  >
                    {HomeContext.home.setting.values.home_bullet_point_1}
                  </p>
                  <span
                    className=""
                    style={{ display: "inline-table", width: "91%", color:"#ca5f00" }}
                  >
                    {HomeContext.home.setting.values.home_bullet_point_1}
                  </span>
                </div>
              </div>
            </div> */}

        <div className="theme-inline-slider row bulletpoints" data-gutter="20">
          <div
            className="owl-carousel owl-carousel-nav-white"
            data-items="4"
            data-loop="true"
            data-nav="false"
            data-autoplay="true"
            style={{ zIndex: "0" }}
          >
            <div className="theme-inline-slider-item">
              <div className="bulletpoint-card">
                <img
                  src={"/assets/ficon/pay (1).png"}
                  alt="no-fee"
                  className="bulletpoint-icon"
                />

                {/* <i className="fa fa-credit-card bulletpoint-icons"></i> */}
                {/*                   <div style={{ display: "inline-table", paddingTop: "5px", margin: "0" }}>
                    {HomeContext.home.setting.values.home_bullet_point_2}
                    </div> */}
                <span>
                  <p
                    style={{
                      margin: "1rem 0",
                      fontWeight: "600",
                    }}
                  >
                    {HomeContext.home.setting.values.home_bullet_point_2}
                  </p>
                </span>
              </div>
            </div>
            <div className="theme-inline-slider-item">
              <div className="bulletpoint-card">
                <img
                  src={"/assets/ficon/money.png"}
                  alt="no-fee"
                  className="bulletpoint-icon"
                />
                {/* <i className="fa fa-gbp bulletpoint-icons"></i> */}
                {/*                 <div>
                  <p style={{ display: "inline-table", paddingTop: "5px", margin: "0"}}>
                  {HomeContext.home.setting.values.home_bullet_point_3}
                  </p>
                </div> */}
                <span>
                  <p
                    style={{
                      margin: "1rem 0",
                      fontWeight: "600",
                    }}
                  >
                    {HomeContext.home.setting.values.home_bullet_point_3}
                  </p>
                </span>
              </div>
            </div>
            <div className="theme-inline-slider-item">
              <div className=" bulletpoint-card">
                <img
                  src={"/assets/ficon/safety.png"}
                  alt="no-fee"
                  className="bulletpoint-icon"
                />
                {/* <i className="fa fa-shield bulletpoint-icons"></i> */}
                {/*                 <div>
                  <p style={{ display: "inline-table", paddingTop: "5px", margin: "0"}}>
                  {HomeContext.home.setting.values.home_bullet_point_4}
                  </p>
                </div> */}
                <span>
                  <p
                    style={{
                      margin: "1rem 0",
                      fontWeight: "600",
                    }}
                  >
                    {HomeContext.home.setting.values.home_bullet_point_4}
                  </p>
                </span>
              </div>
            </div>
            <div className="theme-inline-slider-item">
              <div className="bulletpoint-card">
                <img
                  src={"/assets/ficon/booking.png"}
                  alt="no-fee"
                  className="bulletpoint-icon"
                />
                {/* <i className="fa fa-lock bulletpoint-icons"></i> */}
                {/*                 <div>
                  <p style={{ display: "inline-table", paddingTop: "5px", margin: "0"}}>
                  {HomeContext.home.setting.values.home_bullet_point_5}
                  </p>
                </div> */}
                <span>
                  <p
                    style={{
                      margin: "1rem 0",
                      fontWeight: "600",
                    }}
                  >
                    {HomeContext.home.setting.values.home_bullet_point_5}
                  </p>
                </span>
              </div>
            </div>
            <div className="theme-inline-slider-item">
              <div className="bulletpoint-card">
                <img
                  src={"/assets/ficon/images/refer-and-earn2.png"}
                  alt="no-fee"
                  className="bulletpoint-icon"
                />
                <span>
                  <p
                    style={{
                      margin: "1rem 0",
                      fontWeight: "600",
                    }}
                  >
                    Refer & Earn Unlimited
                  </p>
                </span>
              </div>
            </div>
            <div className="theme-inline-slider-item">
              <div className="bulletpoint-card">
                <img
                  src={"/assets/ficon/images/atol-tta.png"}
                  alt="no-fee"
                  className="bulletpoint-icon"
                />
                <span>
                  <p
                    style={{
                      margin: "1rem 0",
                      fontWeight: "600",
                    }}
                  >
                    ATOL + TTA Trust Account Model
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Fly Now Pay Later */}
      <div className="theme-page-section theme-page-section-gray">
        <div className="container ">
          <Link className="nounderline" to="/pg/fly_now_pay_later">
            <div className="home-page-card flyNowPayLater">
              <div className="card mb-4">
                <div className="row _m-0">
                  <div className="col-sm-3 custom-col-xs-3 col-xs-5 fly_now">
                    <img
                      alt="fly-now"
                      height="50"
                      src={HomeContext.home.setting.values.fly_now_logo}
                    />
                  </div>
                  <div className="col-sm-7 custom-col-xs-7 col-xs-7 col-sm-6 text-primary">
                    <h4 className="d-inline">
                      <span>
                        {HomeContext.home.setting.values.fly_now_body}
                      </span>
                    </h4>
                  </div>
                  <div className="col-sm-2 custom-col-xs-2 col-xs-2 text-primary fly-now-arrow">
                    <span className="float-right">
                      <i className="fa fa-2x fa-angle-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      {/* Todays Hot deals */}
      <div className="theme-page-section theme-page-section-gray">
        <div className="container">
          <div className="theme-page-section-header">
            <h5 className="theme-page-section-title" style={{ color: "#333" }}>
              {HomeContext.home.setting.values.home_slider1_title}
            </h5>
            <p
              className="theme-page-section-subtitle"
              style={{ color: "#333" }}
            >
              {HomeContext.home.setting.values.home_slider1_subtitle}
            </p>
          </div>
          <div className="theme-inline-slider row _m-0" data-gutter="20">
            <div
              className="owl-carousel owl-carousel-nav-white top-deal"
              data-items="4"
              data-loop="true"
              data-nav="false"
            >
              {HomeContext.home.topDeal.rows.map((td) => (
                <div
                  // onClick={() => {
                  //   td.link && props.history.push(td.link);
                  // }}
                  key={td.id}
                  className="theme-inline-slider-item"
                >
                  <div className="banner _h-45vh _br-4 banner-animate banner-animate-zoom-in cursor">
                    <div
                      className="banner-bg"
                      style={{
                        backgroundImage: "url(" + td.topDealImage + ")",
                      }}
                    />
                    <div
                      className="banner-caption banner-caption-bottom"
                      style={{
                        padding: "7px 15px",
                        backgroundColor: "#00102b",
                      }}
                    >
                      <h5 className="banner-title">
                        {td.title}{" "}
                        <span className="banner-subtitle _fw-n">
                          {td.description}
                        </span>
                      </h5>
                      <Link className="getprice" to={`${td.link}`}>
                        Get prices{" "}
                        <i
                          className="fa fa-angle-right getprice-arrow"
                          aria-hidden="true"
                        ></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Inspiration For Your Next Trip */}
      <div className="theme-page-section theme-page-section-gray">
        <div className="container">
          <div className="theme-page-section-header">
            <h5 className="theme-page-section-title" style={{ color: "#333" }}>
              {HomeContext.home.setting.values.home_gallery_title}
            </h5>
          </div>
          <div className="tabbable">
            <ul
              id="next-trip-id"
              className="nav nav-tabs nav-line nav-center nav-default nav-mob-inline nav-mob-tabs"
              role="tablist"
              style={{ borderBottom: "1px solid #ddd" }}
            >
              {HomeContext.home.galleryTab.rows.map((el, i) => {
                return (
                  <li
                    key={el.id}
                    onClick={(e) => {
                      handleTabChange(e, el.id);
                    }}
                    className={i == 0 ? "active" : null}
                    role="presentation"
                    id={el.id}
                    style={{ textAlign: "-webkit-center" }}
                  >
                    <a
                      className="inspiration-icon-label"
                      aria-controls={`TravelRoute-${i + 1}`}
                      role="tab"
                      data-toggle="tab"
                      href={`#TravelRoute-${i + 1}`}
                      aria-expanded={i == 0 ? true : false}
                    >
                      <i
                        // _op-06
                        className={`nav-icon nav-icon-center inspiration-icon-box  inspiration-feature-icon feature-icon feature-icon-primary-inverse feature-icon-box  feature-icon-round fa ${el.fontAwesome}`}
                        style={{
                          marginBottom: "21px",
                          margin: "0px 0px 2px 0px",
                        }}
                      ></i>
                      {el.title}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className="tab-content _pt-20">
              <div
                className="tab-pane active"
                id="TravelRoute-1"
                role="tab-panel"
              >
                <div
                  className="row row-col-gap _m-0 sm-w-full"
                  data-gutter="20"
                >
                  {GalleryImage.rows.map((el, i) => {
                    return (
                      <div
                        key={el.id}
                        className={`col-md-${el.sequence == 2 || el.sequence == 3 ? 4 : 4
                          }`}
                      >
                        <div className="banner _h-40vh _br-3 banner-animate banner-animate-very-slow banner-animate-zoom-in">
                          <Link to={`${el.link}`}>
                            <div
                              className="banner-bg"
                              style={{
                                backgroundImage: `url(${el.image})`,
                              }}
                            ></div>
                            <div className="banner-caption _pt-120 banner-caption-bottom banner-caption-grad">
                              <div className="banner-bottom-container">
                                <h5 className="banner-title">
                                  {el.title}{" "}
                                  <span className="banner-subtitle">
                                    {el.subtitle}
                                  </span>
                                </h5>
                                <Link className="getprice" to={`${el.link}`}>
                                  Get prices{" "}
                                  <i
                                    className="fa fa-angle-right getprice-arrow"
                                    aria-hidden="true"
                                  />
                                </Link>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us */}
      <div className="theme-page-section theme-page-section-gray">
        {/*         <div className="theme-hero-area-bg-wrap">
          <div
            className="theme-hero-area-bg"
            style={{
              backgroundImage: `url(${card_banner ? card_banner.bannerimage : ""})`,
            }}
          ></div>
          <div className="theme-hero-area-grad-mask theme-hero-area-grad-mask-bold"></div>
          <div
            className="theme-hero-area-mask theme-hero-area-mask-half"
            style={{
              background: HomeContext.home.setting.values.home_cards_gradient,
            }}
          ></div>
        </div>
 */}
        <div className="theme-hero-area-body">
          <div className="feature-card">
            <div className="container">
              <div className="theme-page-section-header">
                <h5
                  className="theme-page-section-title"
                  style={{ color: "#333" }}
                >
                  {HomeContext.SettingList.home_cards_why_us}
                </h5>
              </div>
              <div className="row _m-0">
                {HomeContext.home.homecards.rows.slice(0, 4).map((td) => (
                  <div
                    key={td.id}
                    className="col-xs-12 custom-col-xs-6 col-sm-6 col-md-3 _pl-8 _pr-8 feature-list"
                  >
                    <div
                      className="feature _br-4 feature-wrap-fade-white feature-center _pt-20 _pb-10"
                      style={{ display: "block", blockSize: "100%" }}
                    >
                      <div className="d-flex _p-0 _mb-10">
                        <div className="feature-icon-circle">
                          <div
                            className={`feature-card-icon ${td.fontAwesome}`}
                          ></div>
                        </div>
                        <h5 className="feature-title">{td.title}</h5>
                      </div>
                      <div className="feature-caption">
                        <div
                          className="feature-subtitle"
                          style={{
                            textAlign: "left",
                            marginLeft: "-16px",
                            color: "#000",
                          }}
                        >
                          {ReactHtmlParser(td.subTitle)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Holiday Packages */}
      <div className="theme-page-section theme-page-section-gray">
        <div className="container">
          <div className="theme-page-section-header">
            <h5 className="theme-page-section-title" style={{ color: "#333" }}>
              Holiday Offers and Promotions
            </h5>
          </div>
          <div className="theme-inline-slider row _m-0" data-gutter="20">
            <div
              className="owl-carousel owl-carousel-nav-white top-deal"
              data-items="4"
              data-loop="true"
              data-nav="false"
            >
              <div
                onClick={() => {
                  props.history.push("/holiday");
                }}
                key={"h-1"}
                className="theme-inline-slider-item"
              >
                <div className="banner _h-45vh _br-4 banner-animate banner-animate-zoom-in cursor">
                  <div
                    className="banner-bg"
                    style={{
                      backgroundImage:
                        "url('https://api.flightstravel.co.uk/uploads/galleryImage/1604006106963_dubai.jpg')",
                    }}
                  />
                  <div
                    className="banner-caption banner-caption-bottom"
                    style={{ padding: "7px 15px", backgroundColor: "#00102b" }}
                  >
                    <h5 className="banner-title">Family Holiday </h5>
                    <Link
                      className="getprice"
                      to={`$https://www.flightstravel.co.uk/contact`}
                    >
                      Enquire us{" "}
                      <i
                        className="fa fa-angle-right getprice-arrow"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  props.history.push("/holiday");
                }}
                key={"h-2"}
                className="theme-inline-slider-item"
              >
                <div className="banner _h-45vh _br-4 banner-animate banner-animate-zoom-in cursor">
                  <div
                    className="banner-bg"
                    style={{
                      backgroundImage:
                        "url('https://api.flightstravel.co.uk/uploads/galleryImage/1604006947389_maldives_honeymoon.jpg')",
                    }}
                  />
                  <div
                    className="banner-caption banner-caption-bottom"
                    style={{ padding: "7px 15px", backgroundColor: "#00102b" }}
                  >
                    <h5 className="banner-title">All Inclusive Resorts </h5>
                    <Link
                      className="getprice"
                      to={`https://www.flightstravel.co.uk/contact`}
                    >
                      Enquire us{" "}
                      <i
                        className="fa fa-angle-right getprice-arrow"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  props.history.push("/holiday");
                }}
                key={"h-3"}
                className="theme-inline-slider-item"
              >
                <div className="banner _h-45vh _br-4 banner-animate banner-animate-zoom-in cursor">
                  <div
                    className="banner-bg"
                    style={{
                      backgroundImage:
                        "url('https://api.flightstravel.co.uk/uploads/galleryImage/1586107768969_Barbados.jpg')",
                    }}
                  />
                  <div
                    className="banner-caption banner-caption-bottom"
                    style={{ padding: "7px 15px", backgroundColor: "#00102b" }}
                  >
                    <h5 className="banner-title">
                      Honeymoons <span className="banner-subtitle _fw-n"></span>
                    </h5>
                    <Link
                      className="getprice"
                      to={`https://www.flightstravel.co.uk/contact`}
                    >
                      Enquire us{" "}
                      <i
                        className="fa fa-angle-right getprice-arrow"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  props.history.push("/holiday");
                }}
                key={"h-4"}
                className="theme-inline-slider-item"
              >
                <div className="banner _h-45vh _br-4 banner-animate banner-animate-zoom-in cursor">
                  <div
                    className="banner-bg"
                    style={{
                      backgroundImage:
                        "url('https://api.flightstravel.co.uk/uploads/galleryImage/1604007291016_Jakarta.jpg')",
                    }}
                  />
                  <div
                    className="banner-caption banner-caption-bottom"
                    style={{ padding: "7px 15px", backgroundColor: "#00102b" }}
                  >
                    <h5 className="banner-title">Luxury Holidays </h5>
                    <Link
                      className="getprice"
                      to={`https://www.flightstravel.co.uk/contact`}
                    >
                      Enquire us{" "}
                      <i
                        className="fa fa-angle-right getprice-arrow"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Direct Deals From Top Airlines */}
      <div className="theme-page-section theme-page-section-gray">
        <div className="container">
          <div className="theme-page-section-header">
            <h5 className="theme-page-section-title" style={{ color: "#333" }}>
              {HomeContext.home.setting.values.home_slider2_title}
            </h5>
            <p
              className="theme-page-section-subtitle"
              style={{ color: "#333" }}
            >
              {HomeContext.home.setting.values.home_slider2_subtitle}
            </p>
          </div>
          <div className="theme-inline-slider row _m-0" data-gutter="20">
            <div
              className="owl-carousel owl-carousel-nav-white top-airlines"
              data-items="4"
              data-loop="true"
              data-nav="false"
            >
              {HomeContext.home.directDeal.rows.map((td) => (
                <div
                  onClick={() => {
                    td.link && props.history.push(td.link);
                  }}
                  key={td.id}
                  className="theme-inline-slider-item"
                >
                  <div className="theme-blog-item topdeal theme-blog-item-white">
                    {/* <a
                      className="theme-blog-item-link"
                      href="blog-post.html"
                    ></a> */}
                    <div className="banner _h-410px banner-animate banner-animate-very-slow banner-animate-zoom-in">
                      <div
                        className="banner-bg"
                        style={{
                          backgroundImage: "url(" + td.directDealImage + ")",
                        }}
                      ></div>
                      <div className="banner-caption banner-caption-bottom banner-caption-">
                        <h5 className="theme-blog-item-title">{td.title}</h5>
                        <div
                          className="theme-blog-item-desc noH"
                          dangerouslySetInnerHTML={{ __html: td.description }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Sign Up Boxed */}
      <div className="theme-hero-area">
        <div className="theme-page-section theme-page-section-gray">
          <div className="container">
            <a
              onClick={() => {
                if (!HomeContext.isLogin || !window.user) {
                  setShow(true);
                }
              }}
              className="nounderline"
            >
              <div
                className="home-page-card flyNowPayLater"
                style={{
                  backgroundImage:
                    "url(" +
                    (middle_banner ? middle_banner.bannerimage : "") +
                    ")",
                }}
                data-parallax="true"
              >
                <div className="card _pv-20 save_upto-overlay">
                  <div className="theme-hero-text theme-hero-text-white theme-hero-text-center">
                    <div className="theme-hero-text-header">
                      <h2 className="theme-hero-text-title save-upto-text">
                        {middle_banner ? middle_banner.title : ""}
                      </h2>
                      <h2 className="theme-hero-text-newsubtitle save-upto-subtext">
                        {middle_banner ? middle_banner.subtitle : ""}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* testimonial*/}
      <div className="  theme-page-section-blue">
        <div className="container">
          {/*           <div className="theme-page-section-header theme-page-section-header-white">
          <h5 className="theme-page-section-title">Testimonial</h5>
            <p className="theme-page-section-subtitle">Hello</p>
          </div> */}
          <div className="theme-inline-slider row" data-gutter="20">
            <div
              className="owl-carousel owl-carousel-nav-white"
              data-items="3"
              data-loop="true"
              data-nav="false"
              data-autoplay="true"
            >
              {HomeContext.home.testimonial.rows.map((td) => (
                <div key={td.id} className="theme-inline-slider-item">
                  <div className="theme-blog-item-white">
                    <div className="banner">
                      <div className="banner-caption testimonial-background">
                        <div className="row">
                          <div className="col-md-12 text-center">
                            <div>
                              {[...Array(5).keys()].map((el) => {
                                return (
                                  <i
                                    key={el}
                                    className={
                                      td.rating > el
                                        ? "fa fa-star star-padding   fa-lg "
                                        : "fa fa-star  fa-lg star2"
                                    }
                                    aria-hidden="true"
                                  ></i>
                                );
                              })}
                            </div>
                            <div>
                              <p className="testi-time">{td.name}</p>
                            </div>

                            <div className="col-md-12">
                              <h5>{td.title}</h5>
                              <p>{td.content}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Home);
