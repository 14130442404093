import util from "../Utils/utils";

export default  class FaqService{

    getFaq(id) {
        return util.sendApiRequest("/faq/"+id,"GET",true).then(
            (response)=>{
                return response;  
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }

    listFaq(data, start, perpage) {
        const faq = Object.keys(data).reduce((object, key) => {
          if (data[key] !== "") {
            object[key] = data[key];
          }
          return object;
        }, {});
        return util
          .sendApiRequest(
            "/faq/list/" + start + "/" + perpage,
            "POST",
            true,
            faq
          )
          .then(response => {
            return response;
          })
          .catch(err => {
            throw err;
          });
      }

    // listFaq(start,length,searchTxt,searchField) {
    //     let url = "/faq/list/"+start+"/"+length;
    //     if(searchTxt && searchField){
    //      url = "/faq/list/"+start+"/"+ length+"/"+searchTxt+"/"+searchField.toLowerCase();
    //     }
        
    //      return util.sendApiRequest(url, "GET",true).then(
    //          (response)=>{
    //              return response;
    //          },
    //          (error)=>{
    //              throw new Error(error);
    //          },
    //      );
    //  }
}