import React, { useEffect, useState } from "react";
import { EmergencyMessageUtils } from "../../Service/UtilsService";

const Message = (props) => {
  return (
    <section style={{ backgroundColor: "#eee", marginBottom: "-3rem" }}>
      <div class="container">
        <h5 class="text-danger">{/* <i class="fa fa-info-circle"></i> Our phones are busy{" "} */}</h5>
        <span class="text-danger"></span>{" "}
        <span dangerouslySetInnerHTML={{ __html: props.message && props.message.main_message }}></span>
      </div>
    </section>
  );
};
export default Message;
