import React, { memo } from "react";
import { getAirlineCode, getAirportName } from "../../../../Helper/helper";
import moment from "moment";
import FlightSearchBar from "../../../SearchBar/FlightSearchBar";


const TravelingDetail = ({
  searchResult,
  fromAirport,
  toAirport,
  buttonText,
  getTotalTravellers,
  setButtonText,
  handleSearchClick,
  ...props
}) => {
  const { from, to, arrival, depart } = searchResult;
  const onSearchClick = (args) => {
    handleSearchClick(args)
    const el = document.getElementById('collapseExample')
    el.classList.remove('in')
    setButtonText(true)
  }

  return (
    <div className="theme-hero-area front hero-area-bg _pt-20">
      <div className="theme-hero-area-body">
        <div className="container">
          <div className="theme-search-area theme-search-area-white">
            <div className="theme-search-area-header _mb-20 d-flex">
              {/*                   <h1 className="theme-search-area-title theme-search-area-title-sm">
            {SearchContxt.flightList.data.length
              ? `${SearchContxt.flightList.data.length} Flights to  ${toFlight}`
              : null}{" "}
          </h1> */}
              <div className="flight-search-title-departure">
                <div className="d-flex" style={{ alignItems: "center" }}>
                  {/* <span className="airport-name">{from}</span> */}
                  <span>{fromAirport ? fromAirport.cityName : from === "DTT" ? "Detroit, MI" : getAirportName(from)}</span>
                  <span className="flight-code _ml-5">({getAirlineCode(from)})</span>
                </div>
                <span className="flight-date">
                  {depart && moment(depart).format("ddd, DD MMM YYYY")}
                </span>
              </div>
              {/* <div className="arrow-icon-div"><img src={require('../../images/arrow.svg')} alt="" className="arrow-icon" /></div> */}
              <div className="flight-search-title-arrival">
                {/* <span className="airport-name">{to}</span> */}
                <div className="d-flex" style={{ alignItems: "center" }}>
                  <span>{toAirport ? toAirport.cityName : to === "DTT" ? "Detroit, MI" : getAirportName(to)}</span>
                  <span className="flight-code _ml-5">({getAirlineCode(to)})</span>
                </div>
                <span className="flight-date">
                  {arrival && moment(arrival).format("ddd, DD MMM YYYY")}
                </span>
              </div>
              <div className="flight-search-travellers">
                <span>
                  {`${getTotalTravellers()} ${
                    getTotalTravellers() > 1 ? "Travellers" : "Traveller"
                  }`}
                </span>
              </div>
              <div className="flight-search-btn">
                <button
                  className="edit-search-btn"
                  id="edit-search-btn"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                  onClick={() => {
                    setButtonText(!buttonText);
                  }}
                >
                  {buttonText ? "Edit Search" : "Close Search"}
                </button>
              </div>
            </div>
            <div className="collapse _mb-20" id="collapseExample">
              <FlightSearchBar handleSearchClick={onSearchClick} SetSearchForm={props.SetSearchForm} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(TravelingDetail);
