import util from "../Utils/utils";

export default  class ContactService{
 addContact(contact) {
    return util.sendApiRequest("/contactus","POST",true,contact).then(
        (response)=>{
            return response;
        },
        (error)=>{
            throw new Error(error);
        },
    );
 }
}
