import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { Login } from "./Login";
import logincontext from "../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import userService from "../Service/UserService";

export default function FlightLoader(props) {
  const userServ = new userService();
  const [show, setShow] = useState(false);
  let context = useContext(logincontext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (props.show === "true") {
      handleShow();
    } else if (props.show === "false") {
      handleClose();
    }
  }, [props]);
  return (
    <>
      <Modal centered show={show} animation={false}>
        <Container>
          <Row>
            <Col md={12}>
              <div>
                <h4 style={{ textAlign: "center" }}>Please Wait</h4>
              </div>
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </Modal>
    </>
  );
}
