import CircularProgress from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getAirlineName } from "../../../../Helper/helper";
import BottomStaticCard from "../../../Popup/BottomStaticCard";
import CallModal from "../../../Popup/CallModal";
import PopupCard from "../../../Popup/PopupCard";
import RefreshModal from "../../../Popup/RefreshPopup";
import SearchResult from "../../SearchResult";
import useFlights from "../Hooks/useFlights";
import useRefreshModal from "../Hooks/useRefreshModal";
import HelpSection from "./HelpSection";
import FilterSidebar from "./Sidebar";
import TravelingDetail from "./TravelingDetail";

const FlightSearchResult = (props) => {
  const {
    settingList,
    flightList,
    airlineNames,
    filterOptions,
    filterSelected,
    searchResult,
    buttonText,
    flightContext,
    isScrollForPopup,
    setButtonText,
    airportNames,
    getTotalTravellers,
    getCheapestPrice,
    handleSearchClick,
    onFiltersChange,
    fetchFlightList,
  } = useFlights(props);

  const { refreshModal, showAdvertise, confirmRefresh, closeRefreshModal } =
    useRefreshModal({
      flights: flightContext.data,
      fetch: fetchFlightList,
    });

  const { toFromData } = props;
  return (
    <div>
      {/* {isScrollForPopup && (
        <CallModal
          title={getAirlineName(searchResult.to)}
          phoneNo={settingList.contact_phone}
          price={settingList.discounted_price}
          // handleClose={()=> setIsScrollForPopup(false)}
        />
      )} */}
      <RefreshModal
        show={refreshModal}
        onCancel={closeRefreshModal}
        onRefresh={confirmRefresh}
      />
      <TravelingDetail
        searchResult={searchResult}
        fromAirport={toFromData?.from}
        toAirport={toFromData?.to}
        buttonText={buttonText}
        getTotalTravellers={getTotalTravellers}
        setButtonText={setButtonText}
        handleSearchClick={handleSearchClick}
        SetSearchForm={props.SetSearchForm}
        SearchForm={props.SearchForm}
      />
      <div className="theme-page-section theme-page-section-gray">
        <div className="container">
          <div
            className="row row-col-static _mb-10 visible-sm-block visible-xs-block"
            id="sticky-parent"
            data-gutter="20"
          >
            <div className="col-xs-12">
              <button
                className="btn btn-lg btn-shadow filter-button"
                onClick={() => {
                  window.$("#sidebar").toggle();
                  /* window.$(document.body).toggleClass('overflow-hidden', true) */
                }}
              >
                {" "}
                Filters
                <i class="fa fa-solid fa-sliders filter-icon"></i>
              </button>
            </div>
          </div>
          <div className="row _mh-30 _mh-mob-0" id="sticky-parent">
            <div className="col-md-3 sm-h">
              <FilterSidebar
                filterSelected={filterSelected}
                filterOptions={filterOptions}
                airlineNames={airlineNames}
                searchResult={searchResult}
                onFiltersChange={onFiltersChange}
                flightList={flightList}
              />
              <HelpSection contact_phone={settingList.contact_phone} />
            </div>
            <div className="col-md-9 padding-mob">
              {showAdvertise && (
              // {(
                <PopupCard
                  fromDate={searchResult.depart}
                  toDate={searchResult.arrival}
                  from={
                    toFromData.from
                      ? toFromData.from.cityName
                      : searchResult.from === "DTT"
                      ? "Detroit, MI"
                      : getAirlineName(searchResult.from)
                  }
                  to={
                    toFromData.to
                      ? toFromData.to.cityName
                      : searchResult.to === "DTT"
                      ? "Detroit, MI"
                      : getAirlineName(searchResult.to)
                  }
                  cheapestPrice={getCheapestPrice}
                  contactNo={settingList.contact_phone}
                />
              )}
              <div className="theme-search-results">
                <SearchResult
                  {...props}
                  filterSelected={filterSelected}
                  airportNames={airportNames}
                  airlineNames={airlineNames}
                  flightList={flightList}
                />
                {flightList.searchLoader && !flightContext?.data ? (
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                ) : null}
              </div>
              {(searchResult?.trip !== 1 && flightList?.data?.length && !flightList.searchLoader) ? 
                <div className="theme-search-results">
                  <span className="cancellation-chip">
                  SAME DAY FREE CANCELLATION IS VALID DURING OUR WORKING HOURS (8:00 A.M TO 10:00 P.M)
                  </span>
                </div> : ""
              }
              {!flightList.searchLoader && showAdvertise ? (
                <BottomStaticCard
                  couponPopup
                  coloredCard="#f91717"
                  couponCodeText={settingList.flight_search_coupon_code_text}
                  couponCodeBottomText={
                    settingList.flight_search_coupon_code_bottom_text
                  }
                  couponCode={settingList.flight_search_coupon_code}
                  contactNo={settingList.contact_phone}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default FlightSearchResult;
