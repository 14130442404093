import React, { Component } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

import { BannerUtils } from "../../Service/UtilsService";
import userService from "../../Service/UserService";

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.token = props.match.params.token;
    this.state = {
      form: {
        password: "",
        confirmPassword: "",
        token: this.token,
      },
      formErrors: {
        password: null,
        confirmPassword: null,
      },
      banner: {
        bannerimage: "",
      },
    };
    this.userServ = new userService();
  }

  componentDidMount() {
    window.scroll(0, 0);
    let ths = this;
    async function fetchData() {
      const result = await BannerUtils("forgotpassword");
      ths.setState({ banner: result });
    }
    fetchData();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};

    // handle change event except language field
    formObj = {
      ...form,
      [name]: value,
    };

    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};
      if (name === "password" || name === "confirmPassword") {
        let refValue = this.state.form[name === "password" ? "confirmPassword" : "password"];
        const errorMsg = this.validateField(name, value, refValue);
        formErrorsObj = { ...formErrors, [name]: errorMsg };
        if (!errorMsg && refValue) {
          formErrorsObj.confirmPassword = null;
          formErrorsObj.password = null;
        }
      } else {
        const errorMsg = this.validateField(name, name === "language" ? this.state.form["language"] : value);
        formErrorsObj = { ...formErrors, [name]: errorMsg };
      }
      this.setState({ formErrors: formErrorsObj });
    });
  };

  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "password":
        // refValue is the value of Confirm Password field
        if (!value) errorMsg = "Please enter Password.";
        else if (refValue && value !== refValue) errorMsg = "Password and Confirm Password does not match.";
        break;
      case "confirmPassword":
        // refValue is the value of Password field
        if (!value) errorMsg = "Please enter Confirm Password.";
        else if (refValue && value !== refValue) errorMsg = "Password and Confirm Password does not match.";
        break;
      default:
        break;
    }
    return errorMsg;
  };

  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;
      if (x === "password" || x === "confirmPassword") {
        refValue = form[x === "password" ? "confirmPassword" : "password"];
      }
      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
    });
    return errorObj;
  };

  handleSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    }

    this.userServ
      .reset_password(form)
      .then((response) => {
        toast.success(response.message);
        setTimeout(() => {
          this.props.history.push("/login");
        }, 1000);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  render() {
    const { form, formErrors } = this.state;
    return (
      <div className="theme-hero-area">
        <div className="theme-hero-area-bg-wrap">
          <div
            className="theme-hero-area-bg"
            style={{ backgroundImage: `url(${this.state.banner.bannerimage})` }}
          ></div>
          <div className="theme-hero-area-mask theme-hero-area-mask-strong"></div>
        </div>
        <div className="theme-hero-area-body">
          <div className="theme-page-section _pt-100 theme-page-section-xl">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-md-offset-4">
                  <div className="theme-login theme-login-white">
                    <div className="theme-login-header">
                      <h1 className="theme-login-title">{this.state.banner.title}</h1>
                      <p className="theme-login-subtitle">{this.state.banner.subtitle}</p>
                    </div>
                    <div className="theme-login-box">
                      <div className="theme-login-box-inner" style={{ padding: "29px 25px " }}>
                        <form className="theme-login-form">
                          <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              value={form.password}
                              onChange={this.handleChange}
                              onBlur={this.handleChange}
                              placeholder=" Password"
                            />
                            {formErrors.password && <span className="err">{formErrors.password}</span>}
                          </div>
                          <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="password"
                              name="confirmPassword"
                              value={form.confirmPassword}
                              onChange={this.handleChange}
                              onBlur={this.handleChange}
                              placeholder="Confirm Password"
                            />
                            {formErrors.confirmPassword && <span className="err">{formErrors.confirmPassword}</span>}
                          </div>
                          <button
                            className="btn btn-uc btn-dark btn-block btn-lg"
                            onClick={this.handleSubmit}
                            type="button"
                          >
                            Change Password
                          </button>
                        </form>
                      </div>
                      <div className="theme-login-box-alt">
                        {/* <p>
                          Already have an account? &nbsp;
                          <Link to="/login">Sign in.</Link>
                        </p> */}
                      </div>
                    </div>
                    <p className="theme-login-terms">
                      By signing up you accept our
                      <a href="#"> terms of use</a>
                      <br />
                      and
                      <a href="#"> privacy policy</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}
