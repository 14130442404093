import React, { useState, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import TravelerService from "../../../Service/TravelerService";

const Traveler = (props) => {
  let traveler_id = props.match.params.id;
  let travelerServ = new TravelerService();
  const [redirect, setredirect] = useState(false);
  const [traveler, settraveler] = useState({
    title: "",
    userId: window.user.result.id,
    firstName: "",
    lastName: "",
    day: "",
    month: "",
    year: "",
    passengerType: "",
  });
  let schema = Yup.object({
    name: Yup.string().required().max(100, "Too Long"),
    email: Yup.string().required().email(),
    phone: Yup.string().required(),
    country: Yup.string().required(),
  });
  //fetching travel detail based on id
  useEffect(() => {
    function fetchData() {
      gettraveler();
    }
    if (traveler_id) {
      fetchData();
    }
  }, []);

  function gettraveler() {
    travelerServ
      .getTraveler(traveler_id)
      .then((result) => {
        settraveler(result);
      })
      .catch((err) => {
        settraveler({});
      });
  }
  const handleSubmitForm = (values, actions) => {
    if (traveler_id) {
      travelerServ.editService(values).then(
        (response) => {
          toast.success(response);
          setredirect(true);
        },
        (error) => {
          toast.success(error);
          setredirect(false);
        }
      );
    } else {
      travelerServ
        .addService(values)
        .then((response) => {
          toast.success(response);
          setredirect(true);
        })
        .catch((err) => {
          toast.success(err);
          setredirect(false);
        });
    }
  };
  if (redirect === true) {
    return <Redirect to="/user/travelers" />;
  }

  return (
    <div className="bg-light py-4">
      <div className="container py-4">
        <div className="row">
          <div className="col-sm-8">
            <div className="flight-pax" style={{ marginTop: "10" }}>
              <h4> Traveller's detail</h4>
              <Formik
                enableReinitialize={true}
                initialValues={traveler}
                onSubmit={handleSubmitForm}
                // validationSchema={schema}
                render={({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="card">
                      <div className="card-body">
                        <div className="ng-pristine ng-invalid ng-touched">
                          <div formarrayname="passengers" className="ng-untouched ng-pristine ng-invalid">
                            <div className="ng-untouched ng-pristine ng-invalid">
                              {/* <h5> Adult 1 </h5> */}
                              <div className="row form-group">
                                <div className="col-4">
                                  <select
                                    name="title"
                                    className="form-control ng-untouched ng-pristine ng-valid"
                                    formcontrolname="title"
                                    placeholder="Title"
                                    onChange={handleChange}
                                    value={values.title}
                                  >
                                    <option value=""> Title </option>
                                    <option value="Mr"> Mr </option>
                                    <option value="Miss"> Miss </option>
                                    <option value="Mrs"> Mrs </option>
                                    <option value="Ms"> Ms </option>
                                    <option value="Master"> Master </option>
                                  </select>
                                </div>
                                <div className="col-4">
                                  <input
                                    name="firstName"
                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                    formcontrolname="firstName"
                                    placeholder="First name"
                                    onChange={handleChange}
                                    value={values.firstName}
                                  />
                                </div>
                                <div className="col-4">
                                  <input
                                    name="lastName"
                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                    formcontrolname="lastName"
                                    placeholder="Last name"
                                    onChange={handleChange}
                                    value={values.lastName}
                                  />
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-3">
                                  <select
                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                    formcontrolname="day"
                                    placeholder="Day"
                                    onChange={handleChange}
                                    name="day"
                                    value={values.day}
                                  >
                                    <option value=""> Day </option>
                                    {[...Array(31).keys()].map((el) => (
                                      <option value={el + 1}> {el + 1}</option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-sm-3">
                                  <select
                                    name="month"
                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                    formcontrolname="month"
                                    placeholder="Month"
                                    onChange={handleChange}
                                    value={values.month}
                                  >
                                    <option value=""> Month </option>
                                    <option value="1"> January </option>
                                    <option value="2"> Febuary </option>
                                    <option value="3"> March </option>
                                    <option value="4"> April </option>
                                    <option value="5"> May </option>
                                    <option value="6"> June </option>
                                    <option value="7"> July </option>
                                    <option value="8"> August </option>
                                    <option value="9"> September </option>
                                    <option value="10"> October </option>
                                    <option value="11"> November </option>
                                    <option value="12"> December </option>
                                  </select>
                                </div>
                                <div className="col-sm-3">
                                  <select
                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                    formcontrolname="year"
                                    placeholder="Year"
                                    onChange={handleChange}
                                    name="year"
                                    value={values.year}
                                  >
                                    <option value=""> Year </option>
                                    <option value="2008"> 2008 </option>
                                    <option value="2007"> 2007 </option>
                                    <option value="2006"> 2006 </option>
                                    <option value="2005"> 2005 </option>
                                    <option value="2004"> 2004 </option>
                                    <option value="2003"> 2003 </option>
                                    <option value="2002"> 2002 </option>
                                    <option value="2001"> 2001 </option>
                                    <option value="2000"> 2000 </option>
                                    <option value="1999"> 1999 </option>
                                    <option value="1998"> 1998 </option>
                                    <option value="1997"> 1997 </option>
                                    <option value="1996"> 1996 </option>
                                    <option value="1995"> 1995 </option>
                                    <option value="1994"> 1994 </option>
                                    <option value="1993"> 1993 </option>
                                    <option value="1992"> 1992 </option>
                                    <option value="1991"> 1991 </option>
                                    <option value="1990"> 1990 </option>
                                    <option value="1989"> 1989 </option>
                                    <option value="1988"> 1988 </option>
                                    <option value="1987"> 1987 </option>
                                    <option value="1986"> 1986 </option>
                                    <option value="1985"> 1985 </option>
                                    <option value="1984"> 1984 </option>
                                    <option value="1983"> 1983 </option>
                                    <option value="1982"> 1982 </option>
                                    <option value="1981"> 1981 </option>
                                    <option value="1980"> 1980 </option>
                                    <option value="1979"> 1979 </option>
                                    <option value="1978"> 1978 </option>
                                    <option value="1977"> 1977 </option>
                                    <option value="1976"> 1976 </option>
                                    <option value="1975"> 1975 </option>
                                    <option value="1974"> 1974 </option>
                                    <option value="1973"> 1973 </option>
                                    <option value="1972"> 1972 </option>
                                    <option value="1971"> 1971 </option>
                                    <option value="1970"> 1970 </option>
                                    <option value="1969"> 1969 </option>
                                    <option value="1968"> 1968 </option>
                                    <option value="1967"> 1967 </option>
                                    <option value="1966"> 1966 </option>
                                    <option value="1965"> 1965 </option>
                                    <option value="1964"> 1964 </option>
                                    <option value="1963"> 1963 </option>
                                    <option value="1962"> 1962 </option>
                                    <option value="1961"> 1961 </option>
                                    <option value="1960"> 1960 </option>
                                    <option value="1959"> 1959 </option>
                                    <option value="1958"> 1958 </option>
                                    <option value="1957"> 1957 </option>
                                    <option value="1956"> 1956 </option>
                                    <option value="1955"> 1955 </option>
                                    <option value="1954"> 1954 </option>
                                    <option value="1953"> 1953 </option>
                                    <option value="1952"> 1952 </option>
                                    <option value="1951"> 1951 </option>
                                    <option value="1950"> 1950 </option>
                                    <option value="1949"> 1949 </option>
                                    <option value="1948"> 1948 </option>
                                    <option value="1947"> 1947 </option>
                                    <option value="1946"> 1946 </option>
                                    <option value="1945"> 1945 </option>
                                    <option value="1944"> 1944 </option>
                                    <option value="1943"> 1943 </option>
                                    <option value="1942"> 1942 </option>
                                    <option value="1941"> 1941 </option>
                                    <option value="1940"> 1940 </option>
                                    <option value="1939"> 1939 </option>
                                    <option value="1938"> 1938 </option>
                                    <option value="1937"> 1937 </option>
                                    <option value="1936"> 1936 </option>
                                    <option value="1935"> 1935 </option>
                                    <option value="1934"> 1934 </option>
                                    <option value="1933"> 1933 </option>
                                    <option value="1932"> 1932 </option>
                                    <option value="1931"> 1931 </option>
                                    <option value="1930"> 1930 </option>
                                    <option value="1929"> 1929 </option>
                                    <option value="1928"> 1928 </option>
                                    <option value="1927"> 1927 </option>
                                    <option value="1926"> 1926 </option>
                                  </select>
                                </div>
                                <div className="col-sm-3">
                                  <select
                                    className="form-control ng-untouched ng-pristine ng-invalid"
                                    formcontrolname="passengerType"
                                    placeholder="Passenger Type"
                                    onChange={handleChange}
                                    name="passengerType"
                                    value={values.passengerType}
                                  >
                                    <option value=""> Passenger Type </option>
                                    <option value="adult"> Adult </option>
                                    <option value="child"> Child </option>
                                    <option value="infant"> Infant </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row py-4">
                      <div className="col-12">
                        <button type="submit" className="btn btn-warning" style={{ backgroundColor: "#ff6c2d" }}>
                          <div className="p-2 font-weight-bold text-white">Save</div>
                        </button>
                      </div>
                    </div>
                    <div id="paySection"></div>
                  </form>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Traveler;
