import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const PopupCard = ({
  fromDate,
  toDate,
  from,
  to,
  cheapestPrice,
  contactNo,
}) => {
  const cheapestFare = cheapestPrice();
  return (
    <>
      <div className="popup-card">
        <div className="popup-card-header mob-card-header">
          {from} - {to}, {moment(fromDate).format("DD MMM YYYY")}{" "}
          {toDate ? `- ${moment(toDate).format("DD MMM YYYY")}` : ""}
        </div>
        <div className="card-body text-primary">
          <h5 className="card-title">
          {/* Want to book your flights for lower than{" "}
            {cheapestFare ? `£` + cheapestFare : "Online Price"}? */}
             Please click on Book Now button to get the cheapest price
          </h5>
          {/* <p className="card-text mob-card-text">{`We might have lower fare for ${to} on phone. We have hundreds of unpublished deals that can not be displayed online due to airlines restrictions.`}</p> */}
          {/* <h5 className="card-title">Please click on Book Now button to get the final price</h5> */}
          {/* <div className="phone d-flex">
            <img
              className="call-icon card-call-icon"
              src={"/assets/ficon/call-icon.svg"}
              alt="call-icon"
            />
            <div className="_ml-10">
              <div>
                <a href={`tel:${contactNo}`} className="call-us">
                  {contactNo}
                </a>
              </div>
              <p className="call-below-text">
                Call us now to grab a SUPER SAVER DEAL.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default PopupCard;
