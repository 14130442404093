import React, { useState, useContext, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import FaqService from "../../Service/FaqService";
import { BannerUtils } from "../../Service/UtilsService";
import {Link} from "react-router-dom";
import {leftContentBar} from "../../Helper/helper";
const Faq = (props) => {
  let faqServ = new FaqService();
  const [faq, setFaq] = useState({ count: "", rows: [] });

  // const [Banner, setBanner] = useState([]);
  // const [refresh, setRefresh] = useState(null);

  useEffect(() => {
    async function fetchData() {
      window.scroll(0, 0, { behavior: "smooth" });
      const result = await faqServ.listFaq({ "": "" }, 0, 1000).then((result) => {
        if (result && result.count) {
          result.rows = result.rows.map( (r) => {
            r.toggle = 1;
            return r;
          })
          setFaq(result);
        } else {
          setFaq({ count: "", rows: [] });
        }
      });
      // const resultBaner = await BannerUtils("faq");
      // setBanner(resultBaner);
    }
    fetchData();
  }, []);
  return (
    <div>
      <div className="theme-hero-area front hero-area-bg _pv-mob-50">
{/*         <div class="theme-hero-area-bg-wrap">
          <div class="theme-hero-area-bg" style={{ backgroundImage: `url(${Banner.bannerimage})` }}></div>
          <div class="theme-hero-area-mask theme-hero-area-mask-half"></div>
          <div class="theme-hero-area-inner-shadow"></div>
        </div>
        <div class="theme-hero-area-body">
          <div class="container">
            <div class="row">
              <div class="col-md-10 col-md-offset-1 theme-page-header-abs">
                <div class="theme-page-header theme-page-header-lg">
                  <h1 class="theme-page-header-title"> FAQs </h1>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          <div className="theme-hero-area-body">
            <div className="container">
              <div className="theme-hero-area-conatct-bg-wrap">
                <div className="contact-us-title">
                  <div className="title-head">FAQs</div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className="theme-page-section _pb-30 theme-page-section-gray theme-page-section-xl">
        <div className="container">
          <div className="row _mh-30 _mh-mob-0">
            {leftContentBar}
            <div class="col-md-9 _m-a">
              <div className="background-faq">
              {faq.rows.map((td,tdIdx) => {
                return (
                <Accordion>
                  <Card>
                    <div className={ td.toggle < 2 ? "faq-btn in-active" : "faq-btn active"}>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={td.id}
                          aria-expanded="false"
                          style={{ outlineStyle: "none" }}
                          onClick={() => {
                              td.toggle = (td.toggle < 2 ? 2 : 1); 
                              let faqTemp = {... faq};
                              faqTemp.rows[tdIdx] = {...td}; 
                              setFaq(faqTemp); 
                            }}
                        >
                          <strong className="question">{td.question}</strong>
                          <i className={td.toggle < 2 ? "fa fa-angle-down  float-right _pt-5" : "fa fa-angle-up  float-right _pt-5"} aria-hidden="true" style={{ fontWeight: "900", fontSize: "17px" }}></i>{" "}
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={td.id}>
                        <Card.Body className="crdbdy">
                          <div dangerouslySetInnerHTML={{__html: td.answer}}></div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </div>
                  </Card>
                </Accordion>
              )
              })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Faq;
