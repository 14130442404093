import React, { useState, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { BannerUtils } from "../../Service/UtilsService";
import ContactService from "../../Service/ContactService";
import context from "../../Context/Context";
const Contact = (props) => {
  let contactUsContext = useContext(context);
  let contactServ = new ContactService();
  // const [Banner, setBanner] = useState([]);
  // const [verified, setverified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
  });
  let schema = Yup.object({
    name: Yup.string().required().max(100, "Too Long"),
    message: Yup.string().required().max(1000, "Too Long"),
    email: Yup.string().required().email(),
    phone: Yup.string().required(),
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      window.scroll(0, 0);

      // const resultBaner = await BannerUtils("contact_us");
      // setBanner(resultBaner);
      //   const result = await LoginUtils();
      //   setBanner(result);
    }
    fetchData();
  }, []);
  const handleSubmitForm = (values, actions) => {
    setContact(values);
    setLoading(true);
    contactServ
      .addContact({
        ...values,
        phone: Number(values.phone),
      })
      .then((response) => {
        setLoading(false);
        toast.success(response.message);
        setTimeout(() => {
          props.history.push("/");
        }, 1000);
      })
      .catch((error) => {
        toast.success(error);
        setLoading(false);
      });
  };
  return (
    <div>
      <div class="theme-hero-area front hero-area-bg _pv-mob-50">
        {/*   <div class="theme-hero-area-bg-wrap">
          <div class="theme-hero-area-bg" style={{ backgroundImage: `url(${Banner.bannerimage})` }}></div>
          <div class="theme-hero-area-mask theme-hero-area-mask-half"></div>
          <div class="theme-hero-area-inner-shadow"></div>
        </div> */}
        <div className="theme-hero-area-body">
          <div className="container">
            <div class="theme-hero-area-conatct-bg-wrap">
              <div className="contact-us-title">
                <div className="title-head">Contact Us</div>
                <div className="title-tag">Ready to help you 24/7</div>
              </div>
            </div>
          </div>
        </div>
        {/*         <div class="theme-hero-area-body">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-md-offset-2 theme-page-header-abs">
                <div class="theme-page-header theme-page-header-lg">
                  <h1 class="theme-page-header-title">{contactUsContext.SettingList["contact_heading"]}</h1>
                  <div class="theme-contact-info">
                    <div class="row row-no-gutter">
                      <div class="col-md-3">
                        <address
                          class="theme-contact-address"
                          dangerouslySetInnerHTML={{
                            __html: Setting.values["contact_address"]
                          }}
                        ></address> 
                        <address class="theme-contact-address">21 Chipmunk Lane
                        <br/>Old Town
                        <br/>Maine 04468
                      </address>
                      </div>
                      <div class="col-md-4">
                        <ul class="theme-contact-info-list">
                          <li>
                            <i class="fa fa-phone"></i>
                            {Setting.values["contact_phone"]}
                          </li>
                          <li>
                            <a href="#">
                              <i class="fa fa-envelope"></i>
                              {Setting.values["contact_email"]}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

      <div className="theme-hero-area-body">
        <div className="theme-page-section theme-page-section-xxl">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* {home.homecards.rows.map(td => ( */}
                <div className="col-md-4 ">
                  <div className="feature _br-5 feature-wrap-fade-white feature-center contact-card">
                    <div className="contact-card-header">
                      <i
                        class="fa fa-mobile contact-card-header-icon"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="feature-caption _c-w">
                      <h5 className="feature-title contact-card-title contact-card-title-text">
                        Call Us
                      </h5>
                      <p className="feature-subtitle contact-card-body">
                        {" "}
                        <a
                          href={`tel:${contactUsContext.SettingList["contact_phone"]}`}
                          className="contactus-color"
                        >
                          {contactUsContext.SettingList["contact_phone"]}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 ">
                  <div className="feature _br-5 feature-wrap-fade-white feature-center contact-card">
                    <div className="contact-card-header">
                      <i
                        class="fa fa-map-marker contact-card-header-icon"
                        style={{ fontSize: "30px", paddingTop: "2px" }}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="feature-caption _c-w">
                      <h5 className="feature-title contact-card-title contact-card-title-text">
                        Address
                      </h5>
                      <p className="feature-subtitle contact-card-body">
                        {contactUsContext.SettingList["contact_address"]}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 ">
                  <div className="feature _br-5 feature-wrap-fade-white feature-center contact-card">
                    <div className="contact-card-header">
                      <i
                        class="fa fa-envelope contact-card-header-icon"
                        style={{
                          fontSize: "22px",
                          lineHeight: "1.5",
                          paddingLeft: "3px",
                        }}
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div className="feature-caption _c-w">
                      <h5 className="feature-title contact-card-title contact-card-title-text">
                        Email Us
                      </h5>
                      <p className="feature-subtitle contact-card-body">
                        {" "}
                        <a
                          href={
                            "mailto:" +
                            contactUsContext.SettingList["contact_email"]
                          }
                          target="_blank"
                          className="contactus-color"
                        >
                          {contactUsContext.SettingList["contact_email"]}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                {/* ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="theme-page-section theme-page-section-gray"
        style={{ paddingBottom: "70px" }}
      >
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-md-12">
              <div class="theme-contact">
                <div class="row row-col-mob-gap">
                  <div class="col-xs-12 col-md-6">
                    <h2 class="theme-contact-title">Our Location</h2>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.4646043648454!2d-0.42396228423013854!3d51.50469177963468!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3cc1c09b8703f170!2sFlightsTravel!5e0!3m2!1sen!2sin!4v1600861368593!5m2!1sen!2sin"
                      width="100%"
                      height="90%"
                      frameborder="0px"
                      allowFullScreen=""
                    ></iframe>
                  </div>

                  <Formik
                    initialValues={contact}
                    onSubmit={handleSubmitForm}
                    validationSchema={schema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <div class="col-xs-12 col-md-6">
                        <h2 class="theme-contact-title">Contact Us</h2>
                        <div className="theme-contact-form">
                          <form onSubmit={handleSubmit}>
                            <div class="form-group theme-contact-form-group">
                              <label>Name</label>
                              <input
                                className="form-control"
                                id="name"
                                type="text"
                                name="name"
                                placeholder="Enter full name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                // isValid={touched.name && !errors.name}
                                autoComplete="new-password"
                              />
                              <ErrorMessage name="name">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div class="form-group theme-contact-form-group">
                              <label>Email</label>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                placeholder="Email Address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                isValid={touched.email && !errors.email}
                                autoComplete="new-password"
                              />
                              <ErrorMessage name="email">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div class="form-group theme-contact-form-group">
                              <label>Mobile No.</label>
                              <input
                                className="form-control"
                                type="tel"
                                name="phone"
                                placeholder="Mobile Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                                isValid={touched.phone && !errors.phone}
                              />
                              <ErrorMessage name="phone">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <div class="form-group theme-contact-form-group">
                              <label>Message</label>
                              <textarea
                                className="form-control"
                                rows="5"
                                type="text"
                                name="message"
                                placeholder="Message"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                                isValid={touched.message && !errors.message}
                              />
                              <ErrorMessage name="message">
                                {(msg) => (
                                  <div className="err_below">{msg}</div>
                                )}
                              </ErrorMessage>
                            </div>
                            <button
                              class="btn btn-primary btn-lg"
                              type="submit"
                              style={{
                                padding: "10px 25px",
                                fontSize: "inherit",
                              }}
                              // disabled={!verified}
                            >
                              Send Message
                            </button>{" "}
                            {loading && (
                              <>
                                <i
                                  class="fa fa-spinner fa-spin"
                                  aria-hidden="true"
                                ></i>{" "}
                                {"Submitting your request, please wait"}{" "}
                              </>
                            )}
                          </form>
                        </div>
                        <br></br>
                        {/* <ReCAPTCHA
                          sitekey="6LcZ9OAUAAAAADBGQyxFf24_gyGLnDZOaSsNOLqX"
                          onChange={e => {
                            setverified(true);
                          }}
                          onExpired={e => {
                            setverified(false);
                          }}
                          onErrored={e => {
                            setverified(false);
                          }}
                        /> */}
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Contact;
