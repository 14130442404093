import React from 'react'
import { Link } from "react-router-dom";
import qs from "qs";
import {getCurrency } from '../../Utils/utils';
const PriceGuarantee = ({
    marginTop = false,
    queryParams,
    checkGoogleFlightsUrl,
    cheapestData
}) => {
    return (
        <div className={`price-guarantee ${marginTop ? "_mt-10" : ""}`}>
            {checkGoogleFlightsUrl ?
                <>
                    {cheapestData && Object.keys(cheapestData)?.length ?
                        <div className='d-flex align-items-center'>
                            <img src="/assets/img/price-guarantee.png" className='price-guarantee-icon' />
                            <div className="back-to-result-button">
                                <Link to={`search?${qs.stringify(queryParams)}`}>
                                    {cheapestData?.price && cheapestData?.airlineName ?
                                        `See Cheapest ${cheapestData?.airlineName} Flights From ${getCurrency() === 'USD' ? '$' : '£'}{cheapestData?.price}` : "See Alternate Flights"
                                    }
                                </Link>
                            </div></div> :
                        <>
                            <div className='d-flex align-items-center'>
                                <img src="/assets/img/price-guarantee.png" className='price-guarantee-icon' />
                                <div className='price-guarantee-text'>PRICE GUARANTEE</div>
                            </div>
                            <div className='disc'>Cheapest price you can't get anywhere else</div>
                        </>
                    }
                </> :
                <>
                    <div className='d-flex align-items-center'>
                        <img src="/assets/img/price-guarantee.png" className='price-guarantee-icon' />
                        <div className='price-guarantee-text'>PRICE GUARANTEE</div>
                    </div>
                    <div className='disc'>Cheapest price you can't get anywhere else</div>
                </>
            }
        </div>
    )
}

export default PriceGuarantee