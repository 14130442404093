import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import logincontext from "../Context/Context";
import UserService from "../Service/UserService";
import {toast} from "react-toastify";

// import { ResetPassword } from "./Resetpassword";
export function Login() {
  let userServ = new UserService();
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  let context = useContext(logincontext);
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (context.openModal.type === "signup") {
      handleClose();
      return;
    }
    if (context.openModal.type === "reset") {
      handleClose();
      return;
    }
    if (context.openModal.type === "login") {
      handleShow();
    }
  }, [context.openModal]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    userServ
      .login(login)
      .then((response) => {
        localStorage.setItem("user", JSON.stringify(response));
        window.user = response;
        context.login(true);
        handleClose();
      })
      .catch((error) => {
        toast.error(error);
        context.login(false);
      });
  };
  return (
    <>
      <div style={{ cursor: "pointer" }} className="p11 login-btn" variant="primary" onClick={handleShow}>
        Login
      </div>
      <Modal dialogClassName="SignUpModal" centered show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton className= "SignUpModalHeader">
               <img
                // src={Setting.values.main_logo}
                src={context.SettingList && context.SettingList.main_logo}
                alt="Flights Travel"
                title="Flights Travel"
               style={{ height: "5rem", marginTop: "-4px"}}
              />
        </Modal.Header>
        <Modal.Body>
          {/* <Row>
            <Col md={5}> */}
              <div>
              <div className="theme-login-box-heading">
                      <h4>Nice to see you again</h4>
                      <p>Sign in for member-only deals and access to your flight details.</p>
                      </div>
                <div className="theme-login-box-inner">
                  <form className="theme-login-form">
                    <div className="form-group theme-login-form-group">
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        placeholder="Email Address"
                      />
                    </div>
                    <div class="form-group theme-login-form-group">
                      <input
                        class="form-control"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        type="password"
                        name="password"
                        placeholder="Password"
                      />
                     
                    </div>
                    <div class="form-group">
                      <div class="theme-login-checkbox" >
                        <label class="icheck-label">
                          <input class="icheck" type="checkbox" />
                          <span class="icheck-title">Keep me logged in</span>
                        </label>
                      </div>
                      <div className="theme-login-forgotpassword">
                      <p class="help-block">
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            context.handelModel({ type: "reset", value: true });
                          }}
                        >
                          Forgot Password?
                        </a>
                        {/* <a href="/forgotpassword">Forgot password?</a> */}
                      </p>
                      </div>
                    </div>
                 
                    <button type="button" onClick={handleSubmit} style={{marginTop : "60px",}} class="btn btn-uc btn-primary-inverse btn-block btn-nl">
                      Sign In
                    </button>
  
                  </form>
                </div>
                <div class="theme-login-box-alt">
                  <p>
                    Don't have an account? &nbsp;{" "}
                    <a
                     className="LoginMessage"
                      onClick={() => {
                        context.handelModel({ type: "signup", value: true });
                      }}
                    >
                      Register
                    </a>
                  </p>
                </div>
              </div>
            {/* </Col>
          </Row> */}
          {/*<ToastContainer />*/}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>*/}
        </Modal.Footer>
      </Modal>
    </>
  );
}
