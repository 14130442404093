import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {ErrorMessage, Formik} from "formik";
import * as Yup from "yup";
import logincontext from "../Context/Context";
import {toast} from "react-toastify";
import userService from "../Service/UserService";

export function Register(props) {
  const userServ = new userService();
  const [show, setShow] = useState(!!props.show);
  let context = useContext(logincontext);
  const [register, setregister] = useState({ name: "", email: "", password: "", confirmPassword: "" });
  const handleClose = () => {
      setShow(false);
      if(props.handleClose){
          props.handleClose()
      }
  }
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (context.openModal.type == "login") {
      handleClose();
      return;
    }
    if (context.openModal.type == "reset") {
      handleClose();
      return;
    }
    if (context.openModal.type == "signup") {
      handleShow();
      return;
    }
  }, [context.openModal]);
  let schema = Yup.object({
     name: Yup.string().required().max(100, "Too Long"),
    email: Yup.string().required().email(),
    password: Yup.string().required()
    .matches(
      /^(?=.*[0-9])(?=.{8,})/,
      "Must contain atleast 8 characters and a number"
    ),
    // confirmPassword: Yup.string()
    //   .oneOf([Yup.ref("password"), null], "password must match")
    //   .required("Confirm Password is required field"),
  });
  const handleSubmit = (values) => {
    // values.preventDefault();
    userServ
      .addUser(values)
      .then((response) => {
        toast.success(response.message);

        setTimeout(() => {
          handleClose();
        }, 1000);
      })
      .catch((err) => {
        toast.error(err.toString());
      });
  };
  return (
    <>
        {!props.hideRegister ?
      <button className="btn p11 register-btn" 
        style={{background: "rgb(249, 91, 23)", color:"white", padding: "10px 15px", justifyContent: "center"}}
        onClick={handleShow}>
        Register
      </button> : null}

      <Modal dialogClassName="SignUpModal" centered show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton className= "SignUpModalHeader">
         
          <img
                // src={Setting.values.main_logo}
                src={context.SettingList && context.SettingList.main_logo}
                alt="Flights Travel"
                title="Flights Travel"
               style={{ height: "5rem", marginTop: "-4px"}}
              />
        </Modal.Header>
        <Modal.Body>
          {/* <Row> */}
            {/* <Col md={4}>  */}
              <Formik
                // enableReinitialize={true}
                initialValues={register}
                onSubmit={handleSubmit}
                validationSchema={schema}
                render={({ values, handleChange, handleSubmit, errors, handleBlur, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div className="theme-login-box-heading">
                      <h4>Sign up and Save</h4>
                      <p>Create an account now to access member-only deals.</p>
                      </div>
                      <div className="theme-login-box-inner">
                        <div className="theme-login-form">
                           <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              placeholder="Name"
                              onBlur={handleBlur}
                              isValid={touched.name}
                            />
                            <ErrorMessage name="name">{(msg) => <div className="err">{msg}</div>}</ErrorMessage>
                          </div>
                          <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              placeholder="Email"
                              onBlur={handleBlur}
                              isValid={touched.email}
                            />
                            <ErrorMessage name="email">{(msg) => <div className="err">{msg}</div>}</ErrorMessage>
                          </div>
                          <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              placeholder=" Password"
                              onBlur={handleBlur}
                              isValid={touched.password}
                            />
                            <ErrorMessage name="password">{(msg) => <div className="err">{msg}</div>}</ErrorMessage>
                          </div>
                           {/* <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="password"
                              name="confirmPassword"
                              value={values.confirmPassword}
                              onChange={handleChange}
                              placeholder="Confirm Password"
                              onBlur={handleBlur}
                              isValid={touched.password}
                            />
                            <ErrorMessage name="confirmPassword">
                              {(msg) => <div className="err">{msg}</div>}
                            </ErrorMessage>
                          </div>   */}
                          {/* <div className="form-group theme-login-form-group">
                            <label>
                                        <Checkbox
                                        id="emailMe"
                                        name="emailMe"
                                       className="email-chkbox"
                                        />
                                        <span style={{ marginLeft: 8, fontWeight: "normal" ,fontSize: "14px" }}>Email me Flights and Travels best deals</span>
                                      </label>
                          </div> */}
                          <button type="submit" className="btn btn-uc btn-primary-inverse btn-block btn-nl" type="submit">
                            Create Account  
                          </button>
                          {/* <div className="form-group theme-login-form-group">
                            <div className="separator">Use another service to log in</div>
                            <div> </div> <div></div>
                          </div> */}
                         
                        </div>
                      </div>
                      <div className="form-group theme-login-form-group" style={{marginBottom: "0px"}}>
                        <p  className="form-control SignupModalConditions" >
                       By signing up you accept our <a href="/pg/terms_conditions"  target="_blank" >terms of use</a> and <a href="/pg/privacy_policy"  target="_blank" >privacy policy.</a></p>
                      </div>
                      <div className="theme-login-box-alt">
                        <p>
                          Already have an account? &nbsp;
                          {/* <a href="login-2.html">Si gn in.</a> */}
                          {/* <Link to="/login">Sign in.</Link> */}
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              context.handelModel({ type: "login", value: true });
                            }}
                          >
                            Login
                          </a>
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              />
           {/* </Col> */}
          {/* </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>*/}
        </Modal.Footer>
      </Modal>
    </>
  );
}
