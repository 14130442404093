import React, {useContext, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {ErrorMessage, Formik} from "formik";
import * as Yup from "yup";
import logincontext from "../Context/Context";
import {ToastContainer} from "react-toastify";
import userService from "../Service/UserService";
import {notification} from "../Shared/NotificationModal";

export default function ResetPassword(props) {
  const userServ = new userService();
  const [show, setShow] = useState(false);
  let context = useContext(logincontext);
  const [ResetPassword, setResetPassword] = useState({ email: "" });
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (context.openModal.type == "login" || context.openModal.type == "signup") {
      handleClose();
    } else if (context.openModal.type == "reset") {
      handleShow();
    }
  }, [context.openModal]);
  let schema = Yup.object({
    email: Yup.string().required().email(),
  });
  const handleSubmit = async (values) => {
    try {
      const result = await userServ.forget_password(values);
      if (result) {
        notification("success",result.message);
        handleClose();
      }
    } catch (err) {
      notification("error",err);
    }
  };
  return (
    <>
      <Modal dialogClassName="SignUpModal" centered show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton className= "SignUpModalHeader">
         
          <img
                // src={Setting.values.main_logo}
                src={context.SettingList && context.SettingList.main_logo}
                alt="Flights Travel"
                title="Flights Travel"
               style={{ height: "5rem", marginTop: "-4px"}}
              />
        </Modal.Header>
        <Modal.Body>
          {/* <Row>
            <Col md={5}> */}
              <Formik
                // enableReinitialize={true}
                initialValues={ResetPassword}
                onSubmit={handleSubmit}
                validationSchema={schema}
                render={({ values, handleChange, handleSubmit, errors, handleBlur, touched }) => (
                  <form onSubmit={handleSubmit}>
                    <div>
                      <div class="theme-login-box-inner">
                        <p class="theme-login-pwd-reset-text">
                          Enter the email associated with your account in the field below and we'll email you a link to
                          reset your password.
                        </p>
                        <div class="form-group theme-login-form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Email"
                            onBlur={handleBlur}
                            isValid={touched.email}
                          />
                          <ErrorMessage name="email">{(msg) => <div className="err">{msg}</div>}</ErrorMessage>
                        </div>
                        <button
                          style={{ marginBottom: "10px" }}
                          onClick={handleSubmit}
                          class="btn btn-uc btn-primary-inverse btn-block btn-nl"
                        >
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              />
            {/* </Col>
          </Row> */}
          <ToastContainer />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleClose}>
            Close
          </button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>*/}
        </Modal.Footer>
      </Modal>
    </>
  );
}
