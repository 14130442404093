import React from 'react'

const TerminalDetails = ({
    terminal = ""
}) => {
  return (
    <>
      <div className="terminal">{terminal ? `Terminal ${terminal}`: ""}</div>
    </>
  )
}

export default TerminalDetails