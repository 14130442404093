import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import FlightService from "../../../Service/FlightService";
import { getCurrency } from "../../../Utils/utils";
const AccountHistory = () => {
  let flightServ = new FlightService();
  const [BookingList, setBookingList] = useState([]);
  const [Currency, setCurrency] = useState([]);
  useEffect(() => {
    async function fetch() {
      const result = await flightServ.bookingHistory();
      setBookingList(result.rows);
    }
    fetch();
    const currency = getCurrency();
    if (currency == 'USD')
    {
      setCurrency('$');
    }
    else
    {
      setCurrency('£');
      }
    
  }, []);
  return (
    <div class="col-md-9-5 " style={{ marginTop: "30px" }}>
      <h1 class="theme-account-page-title">Booking History</h1>
      <div class="theme-account-history">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Date</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {BookingList.map((el) => {
              return (
                <tr>
                  <td class="theme-account-history-type">
                    <i class="fa fa-plane theme-account-history-type-icon"></i>
                  </td>
                  <td>
                    <p class="theme-account-history-type-title">
                      {el.from} &rarr; {el.to}
                    </p>
                    <Link className="theme-account-history-item-name" to={`/bookingflightdetail/` + el.id}>
                      flight detail
                    </Link>
                  </td>
                  <td class="theme-account-history-tr-date">
                    <p class="theme-account-history-date">
                      {moment(el.departure_date).utc().format("LL")} &#8212;{" "}
                      {moment(el.arrival_date).utc().format("LL")}
                    </p>
                  </td>
                  <td>
                    <p class="theme-account-history-item-price">{Currency}{el.total_price}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default AccountHistory;
