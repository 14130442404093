import util from "../Utils/utils";

export default class PageService {

    getPage(id) {
        return util.sendApiRequest("/staticpage/pg/"+id,"GET",true).then(
            (response)=>{
                return response;  
            },
            (error)=>{
                throw new Error(error);
            },
        );
    }
}