import { useEffect, useState } from "react";

const useTimer = ({ mount, time = 30 }) => {
  const [counting, setCounting] = useState(false);
  const [count, setCount] = useState("");
  let timerId = undefined;

  const timer = (t) => {
    // let m = parseInt(Number(t) / 60)
    const s = t % 60;
    return `${s < 10 ? `0${s}` : s}s`;
  };

  const startTimer = () => {
    let timeLeft = time; // defaults to 30 sec
    timerId = setInterval(countdown, 1000);
    setCounting(true);
    function countdown() {
      if (timeLeft === 0) {
        clearTimeout(timerId);
        clearInterval(timerId);
        setCounting(false);
        setCount(timer(0));
      } else {
        // setCount(timer(timeLeft));
        timeLeft -= 1;
      }
    }
  };

  const clearTimer = () => {
    clearInterval(timerId);
    clearTimeout(timerId);
  };

  useEffect(() => {
    if (mount) startTimer();
  }, [mount]);

  return [false, counting, startTimer, clearTimer];
};

export default useTimer;
