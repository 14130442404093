import React from "react";
import { Link } from "react-router-dom";
import CallPopup from "../Popup/CallPopup";
import CallModal from "../Popup/CallModal";
import PopupCard from "../Popup/PopupCard";
import PhoneDeal from "./PhoneDeal";


const SamplePopup = () => {
  return (
    <>
    <div style={{background: "#efefef"}}>
        <div className="container _mt-10">
          <div className="row">
            <div className=" col-md-9" style={{margin:"auto"}}>
                  {/* <CallPopup/> */}
                  <br/>
                  <CallModal/>
                  <br/>
                  <br/>
                  <PopupCard/>
                  <br/>
                  <PhoneDeal/>
                </div>
            </div>
        </div>
    </div>
    </>
    );
};
export default SamplePopup;