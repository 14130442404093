import React, { useContext, useEffect, useReducer, useState } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import LoginContext from "./Context/Context";
import FlightService from "./Service/FlightService";
import Header from "./Component/Header/Header";
import Home from "./Component/Home/Home";
import Footer from "./Component/Footer/Footer";
import Login from "./Component/Login/Login";
import Registration from "./Component/Registration/Registration";
import ForgotPassword from "./Component/ForgotPassword/ForgotPassword";
import User from "./Component/Account/User";
import {
  EmergencyMessageUtils,
  HomeUtils,
  listSetting,
  PagesBannerUtils,
  // CampaignUtils,
} from "./Service/UtilsService";
import AccountHistory from "./Component/Account/AccountHistory/AccountHistory";
import Payment from "./Component/PaymentSuccess/Payment";
import Pg from "./Component/pg/pg";
import Faq from "./Component/Faq/Faq";
import Contact from "./Component/Contact/Contact";
import FlightSearchResult from "./Component/SearchResult/New/Components/FligthsResult";
// import FlightSearchResult from "./Component/SearchResult/FlightSearchResult";
import Traveler from "./Component/Account/Travelers/TravelersAddEdit";
import ResetPassword from "./Component/ResetPassword/ResetPassword";
import { Error404 } from "./Component/ErrorPage/Error404";
import PaymentCheckout from "./Component/PaymentCheckout/PaymentCheckout";
import FlightDetails from "./Component/FlightDetails/FlightDetails";
import ResetPasswordModal from "./DefaultComponent/Resetpassword";
import Message from "./Component/EmergencyMessage/Message";
import FlightLoaderCommon from "./DefaultComponent/FlightLoaderCommon";
import BookingFlightDetail from "./Component/Account/AccountHistory/BookingDetail";
import ScrollToTopBtn from "./DefaultComponent/ScrollToTop";
import ScrollTopOnClick from "./DefaultComponent/ScrollTopOnClick";
import Popup from "./Component/Popup/SamplePopup";
import { notification } from "./Shared/NotificationModal";
import ThankYou from "./Component/ThankYou/ThankYou";
import Thanks from "./Component/ThankYou/Thanks";
import "react-toastify/dist/ReactToastify.css";
import { getListSetting, pageBanner } from "./Helper/helper";
import { withRouter } from "react-router";
import CookieConsent, { Cookies } from "react-cookie-consent";
import PayOnline from "./Component/PayOnline";
import PayOnlineSuccess from "./Component/PaymentOnlineSuccess/Payment";
import PayOnlineFail from "./Component/PaymentOnlineFail/Payment";
import RefreshModal from "./Component/Popup/RefreshPopup";
import qs from "querystring";
import Holiday from "./Component/Holiday/Holiday";
import { CABIN_OPTIONS } from "./Component/SearchResult/New/constants";
import { getCurrency } from "./Utils/utils";
require("dotenv").config();
if (typeof window !== "undefined") {
  window.user = JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user"))
    : null;
} else {
  global.window = {
    ...global.window,
  };
}
const FlightReducer = (state, action) => {
  if (action.type === "result") {
    return action.payload;
  } else if (action.type === "reset") {
    state = {
      ...action.payload,
      data: [],
      id: "",
      iataAirlineCodes: [],
      oneWay: "",
    };
    return state;
  } else {
    return state;
  }
};

function App(props) {
  let appContext = useContext(LoginContext);
  const [htmlFooter, setHtmlFooter] = useState(props.htmlFooter);
  const [isLogin, setisLogin] = useState(false);
  const [fromFlightDetails, isFromFlightDetails] = useState(false);
  const [searchpage, setsearchpage] = useState(false); //setting the state for query param when result directly comming from search page query params
  const [airportLoading, setairportLoading] = useState(false);
  const [modal, setmodal] = useState({ type: "", value: false });
  const [BookedFlight, SetBookedFlight] = useState({
    airlineName: [],
    data: [],
    iataAirlineCodes: [],
  });
  const [SearchForm, SetSearchForm] = useState({
    from: "London - LHR",
    to: "",
    from1: "",
    to1: "",
    depart: "",
    arrival: "",
    adult: 1,
    child: 0,
    cabin: CABIN_OPTIONS[0].value,
    infant: 0,
    trip: 0,
    utm_campaign: "localSearch",
  });
  const [PagesBannerList, setPagesBannerList] = useState(
    props.pageBannerUtils || { rows: [], count: 0 }
  );
  const [SettingList, setSettingList] = useState(props.footerSetting || {});
  const [CampaignDetails, setCampaignDetails] = useState(
    props.campaignSetting || {}
  );

  const [home, setHome] = useState(
    props.homeSettings || {
      setting: { count: 0, values: {} },
      banner: { count: 0, rows: [] },
      topDeal: { count: 0, rows: [] },
      directDeal: { count: 0, rows: [] },
      homecards: { count: 0, rows: [] },
      testimonial: { count: 0, rows: [] },
      galleryImage: { count: 0, rows: [] },
      galleryTab: { count: 0, rows: [] },
    }
  );

  const [airportCodeQuery, setairportCodeQuery] = useState("");
  const location = useLocation();
  const history = useHistory();
  const [FlightLoading, setFlightLoading] = useState(
    location.pathname === "/search" ? "true" : "false"
  );
  const [AirPortCodeList, SetAirPortCodeList] = useState({
    rows: [],
    count: 0,
  });
  const [message, setmessage] = useState({});
  const [show, setShow] = useState(false);
  const [flightList, dispatch] = useReducer(FlightReducer, {
    data: [],
    id: "",
    iataAirlineCodes: [],
    oneWay: "",
  });
  const [toFromData, setToFromData] = useState({});
  // const [flightList, SetflightList] = useState({ data: [], id: "", iataAirlineCodes: [], oneWay: "" });
  let flightServ = new FlightService();
  const [accept, setAccept] = useState(false);
  const [showCookie, setShowCookie] = useState(false);

  useEffect(() => {
    const AirPortCode = async () => {
      setairportLoading(true);
      let result = await flightServ.getAirportCode(airportCodeQuery);

      SetAirPortCodeList({
        count: result.count,
        rows: result.rows,
      });
      setairportLoading(false);
    };
    if (airportCodeQuery.length === 0) {
      return;
    }
    AirPortCode();
  }, [airportCodeQuery]);
  const login = (cond) => {
    setisLogin(cond);
  };
  //handle modeal open close login  and register
  const handleModal = ({ type, value }) => {
    setmodal({ type, value });
  };

  //handle airport code keystroke
  const handleAirportQuery = (query) => {
    setairportCodeQuery(query);
  };
  const Logout = (cond) => {
    setisLogin(cond);
  };
  //handleBooked  user  flight detail
  const handleBookedFlight = (detail) => {
    SetBookedFlight(detail);
  };

  //handeling emergency message
  useEffect(() => {
    setHtmlFooter(props.htmlFooter);
    async function fetch() {
      let result = await EmergencyMessageUtils();
      setmessage(result);
      if (result) {
        setShow(true);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await HomeUtils();
      if (response === "TypeError: Failed to fetch") {
        return;
      }
      let setting = home.setting || {};
      setting.count = response.setting?.count || 0;
      if (response.setting?.rows) {
        for (let i in response.setting.rows) {
          if (response.setting.rows[i].is_file === true) {
            setting.values[response.setting.rows[i].name] =
              response.setting.rows[i].logoImagePath;
          } else {
            setting.values[response.setting.rows[i].name] =
              response.setting.rows[i].value;
          }
        }
      }
      response.setting = setting;
      setHome(response);
    }

    fetchData();
  }, []);

  //function to handle query param from search page
  function searchPageFormhandler(obj) {
    SetSearchForm(obj);
    setsearchpage(true);
  }

  function submitSearch(obj) {
    SetSearchForm(obj);
  }

  const resetFlightList = () => {
    dispatch({ type: "reset", payload: { data: [] } });
  };

  useEffect(() => {
    if (searchpage) {
      fetchFlightSearch();
    }
    return () => {
      // clearInterval();
      setsearchpage(false);
    };
  }, [searchpage]);

  const fetchFlightSearch = (sform, showLoading = true, updateUrl = false) => {
    dispatch({ type: "reset", payload: { data: [], searchLoader: true } });
    showLoading && setFlightLoading("true");

    const urlParams = new URLSearchParams(location.search);

    // clearInterval();
    // dispatch({
    //   type: "result",
    //   payload: {
    //     searchLoader: true,
    //   },
    // });
    // updating search params : ! it's necessary for footer 's pages
    if (!sform?.cabin) {
      const cabinQuery = urlParams.get("cabin") || 4;
      sform.cabin = cabinQuery;
    }

    if (!sform?.utm_campaign) {
      const utmCampaignQuery = urlParams.get("utm_campaign");
      sform.utm_campaign = utmCampaignQuery;
    }

    let campaign = "localSearch";

    // console.log(
    //   "utm_camp sform?.utm_campaign: ",
    //   sform?.utm_campaign + " testing"
    // );

    // console.log(
    //   "utm_camp SearchForm?.utm_campaign: ",
    //   SearchForm?.utm_campaign + " testing"
    // );

    if (sform?.utm_campaign) {
      campaign = sform?.utm_campaign;
    } else if (SearchForm?.utm_campaign) {
      campaign = SearchForm?.utm_campaign;
    } else {
      campaign = "localSearch";
    }

    // campaign = sform?.utm_campaign
    //   ? sform?.utm_campaign
    //   : SearchForm?.utm_campaign;

    //console.log("utm_camp: ", campaign + " testing");

    //console.log("utm_camp campaignResult: ", campaignResult + " testing");

    if (updateUrl)
      history.replace(`/search?${qs.stringify(sform || SearchForm)}`);

    if (sform) {
      SetSearchForm(sform);
    }

    let offersData = [];
    let offersIataAirlineCodes = [];
    let payload = sform || SearchForm;
    payload.cabin = Number(payload.cabin);
    payload.adult = Number(payload.adult);
    payload.child = Number(payload.child);
    payload.infant = Number(payload.infant);
    payload.trip = Number(payload.trip);
    payload.currency = getCurrency();
    // const campaignResult = CampaignUtils(campaign);
    // setCampaignDetails(campaignResult);

    flightServ.getCampaignDetails(campaign).then((res) => {
      if (res !== null) {
        setCampaignDetails(res);
      }
    });

    flightServ
      .searchFlightOffers(payload)
      .then((res) => {
        if (res !== null) {
          offersData =
            (res.data && res.data.map((d) => ({ ...d, isOffer: true }))) || [];
          offersIataAirlineCodes = res.iataAirlineCodes;
          dispatch({
            type: "result",
            payload: {
              ...res,
              data: offersData.length ? [offersData[0]] : [],
              shouldApiRefetch: false,
              searchLoader: true,
            },
          });
          if (offersData.length) {
            setFlightLoading("false");
          }
        }
      })
      .catch((err) => {
        notification("error", err);
        // setFlightLoading("false");
        dispatch({
          type: "result",
          payload: {
            shouldApiRefetch: true,
            data: [],
            iataAirlineCodes: [],
          },
        });
      });
    const url = new URL(window.location.href);
      let reqData = {
        ...(sform || SearchForm),
      }
      if (url?.searchParams?.get('requestId')) {
        reqData.requestId = Number(url?.searchParams?.get('requestId'))
      }
    reqData.currency = getCurrency();
    flightServ
      .searchFlight(reqData)
      .then((response) => {
        let searchData = [...response.data];

        offersData.map((d, i) => {
          searchData.splice(i * 3, 0, d);
        });
        dispatch({
          type: "result",
          payload: {
            ...response,
            shouldApiRefetch: true,
            searchLoader: false,
            data: searchData,
            iataAirlineCodes: [
              ...offersIataAirlineCodes,
              ...response.iataAirlineCodes,
            ],
          },
        });
        setFlightLoading("false");
      })
      .catch((err) => {
        notification(
          "error",
          "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!"
        );
        dispatch({
          type: "result",
          payload: {
            shouldApiRefetch: true,
            searchLoader: false,
            data: [],
            iataAirlineCodes: [],
          },
        });
        setFlightLoading("false");
      });
  };

  //all pages banner list
  useEffect(() => {
    async function fetch() {
      let result = await PagesBannerUtils();
      let pagesData = pageBanner(result);
      setPagesBannerList(pagesData);
    }

    fetch();
    return () => {
      clearInterval();
    };
  }, []);

  //setting list  fetch
  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchData() {
      const settingResult = await listSetting({ "": "" }, 0, 1000);
      let newKeyValueObjectList = getListSetting(settingResult);
      setSettingList(newKeyValueObjectList);
    }

    props.history.listen(() => {
      window.scrollTo(0, 0);
    });
    fetchData();
  }, []);

  useEffect(() => {
    if (SearchForm.from && SearchForm.to) {
      let from = SearchForm.from.includes("-")
        ? SearchForm.from?.split("-")[1].replaceAll(" ", "")
        : SearchForm.from;
      let to = SearchForm.to.includes("-")
        ? SearchForm.to?.split("-")[1].replaceAll(" ", "")
        : SearchForm.to;

        if(SearchForm.from1 && SearchForm.to1) {
          let from1 = SearchForm.from1.includes("-")
          ? SearchForm.from1?.split("-")[1].replaceAll(" ", "")
          : SearchForm.from1;
          let to1 = SearchForm.to1.includes("-")
            ? SearchForm.to1?.split("-")[1].replaceAll(" ", "")
            : SearchForm.to1;

            Promise.all([
              flightServ.getAirportCode(from1),
              flightServ.getAirportCode(to1),
            ]).then((res) => {
              const fA1 = Array.isArray(res[0]) ? res[0]?.[0] : res[0];
              const fB1 = Array.isArray(res[1]) ? res[1]?.[0] : res[1];
              setToFromData({
                ...toFromData,
                from1: { ...fA1, cityName: fA1?.["city_name"] },
                to1: { ...fB1, cityName: fB1?.["city_name"] },
              });
            });

        }
      Promise.all([
        flightServ.getAirportCode(from),
        flightServ.getAirportCode(to),
      ]).then((res) => {
        const fA = Array.isArray(res[0]) ? res[0]?.[0] : res[0];
        const fB = Array.isArray(res[1]) ? res[1]?.[0] : res[1];
        setToFromData({
          ...toFromData,
          from: { ...fA, cityName: fA?.["city_name"] },
          to: { ...fB, cityName: fB?.["city_name"] },
        });
      });
    }
  }, [SearchForm]);

  const children = (
    <>
      <ScrollTopOnClick />
      <QueryParamProvider ReactRouterRoute={Route}>
        {message && show && <Message message={message} />}
        <Header message={message} show={show} />
        <ResetPasswordModal />
        <FlightLoaderCommon show={FlightLoading} toFromData={toFromData} />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Registration} />
          <Route exact path="/user/:id" component={User} />
          <Route exact path="/accounthistory" component={AccountHistory} />
          <Route exact path="/paymentsuccess" component={Payment} />
          {/* <Route exact path="/traveler" component={Traveler} /> */}
          <Route
            exact
            path="/search/:query?"
            render={(props) => (
              <FlightSearchResult
                {...props}
                toFromData={toFromData}
                fetchFlightSearch={fetchFlightSearch}
                SetSearchForm={SetSearchForm}
                SearchForm={SearchForm}
              />
            )}
          />
          <Route exact path="/pg/:pgname" component={Pg} />
          <Route exact path="/faq" component={Faq} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route
            exact
            path={["/flightdetails", "/googleresults"]}
            component={FlightDetails}
          />
          <Route
            path="/book"
            render={(props) => (
              <FlightSearchResult
                {...props}
                toFromData={toFromData}
                fetchFlightSearch={fetchFlightSearch}
                SetSearchForm={SetSearchForm}
                SearchForm={SearchForm}
              />
            )}
          />
          <Route path="/resetpassword/:token" component={ResetPassword} />
          <Route path="/paymentcheckout" component={PaymentCheckout} />
          <Route
            path="/bookingflightdetail/:id"
            component={BookingFlightDetail}
          />
          <Route
            path="/traveler/add"
            render={(props) => <Traveler {...props} />}
          />
          <Route path="/traveler/edit/:id" component={Traveler} />
          <Route exact path="/popup" component={Popup} />
          <Route
            exact
            path="/thankyou"
            component={(props) => (
              <ThankYou {...props} toFromData={toFromData} />
            )}
          />
          <Route
            exact
            path="/thanks"
            component={(props) => <Thanks {...props} toFromData={toFromData} />}
          />
          <Route exact path="/pay-online" component={PayOnline} />
          <Route
            exact
            path="/pay-online-success"
            component={PayOnlineSuccess}
          />
          <Route exact path="/pay-online-fail" component={PayOnlineFail} />
          <Route exact path="/holiday" component={Holiday} />
          <Route exact path="/" render={(props) => <Home {...props} />} />
          <Route component={Error404} />
        </Switch>
        { }
        <Footer
          footerMenu={props.footerMenu || {}}
          footerSetting={props.footerSetting || {}}
          htmlFooter={props.htmlFooter}
        />
      </QueryParamProvider>
    </>
  );
  return (
    <LoginContext.Provider
      value={{
        isLogin: isLogin,
        login: login,
        logoutHandler: Logout,
        searchReasult: SearchForm,
        fetchFlightList: fetchFlightSearch,
        flightList: flightList,
        airportCodeList: AirPortCodeList,
        home: home,
        handleBookedFlight: handleBookedFlight,
        bookedFlightDetail: BookedFlight,
        handleAirportquery: handleAirportQuery,
        handelModel: handleModal,
        openModal: modal,
        pagesBannersList: PagesBannerList,
        SettingList: SettingList,
        airportSearchLoading: airportLoading,
        searchpageformHandler: searchPageFormhandler,
        submitSearch: submitSearch,
        resetFlightList: resetFlightList,
        campaignDetails: CampaignDetails,
        fromFlightDetails: fromFlightDetails,
        isFromFlightDetails: isFromFlightDetails
      }}
    >
      <div className="App" style={{ overflowX: "hidden" }}>
        {children}
        <ScrollToTopBtn />
        {showCookie && (
          <CookieConsent
            sameSite="Secure"
            cookieName="flightstravel_consent"
            hideOnAccept={false}
            style={{
              background: "#005275",
              color: "white",
            }}
            buttonStyle={{
              background: "#2dace3",
              color: "white !important",
              padding: "10px 15px",
              borderRadius: "5px",
              fontWeight: "600",
            }}
            onAccept={() => {
              setAccept(true);
              setTimeout(() => {
                setShowCookie(false);
              }, 1000);
            }}
            containerClasses={`CookieConsent ${accept ? "fadeDown" : ""}`}
            buttonText="Accept"
          >
            <b>Cookies:</b> We use cookies to enhance the user experience. By
            continuing to browse our website, you are agreeing to use our site
            cookies. See our <a href="/pg/cookies_policy">cookie policy</a> for
            more information on cookies and how to manage them.{" "}
          </CookieConsent>
        )}
        <div id="notification-modal" />
      </div>
    </LoginContext.Provider>
  );
}

export default React.memo(withRouter(App));
