import React from "react";
import { CABIN_OPTIONS } from "../Component/SearchResult/New/constants";

export default React.createContext({
  isLogin: false,
  login: (cond) => {},
  openModal: { type: "", values: "" },
  handelModel: () => {},
  logoutHandler: () => {},
  searchReasult: {
    from: "London - LHR",
    to: "",
    depart: "",
    arrival: "",
    adult: 1,
    child: 0,
    infant: 0,
    cabin: CABIN_OPTIONS[0].value,
    trip: 0,
    utmCampaign: "localSearch",
  },
  fetchFlightList: (searchData) => {},
  flightList: {
    data: [],
    iataAirlineCodes: [],
    id: "",
    oneWay: "",
  },
  airportCodeList: [{ rows: [], count: 0 }],
  FooterSetting: () => {},
  footer: [],
  home: {
    setting: { count: 0, values: {} },
    banner: { count: 0, rows: [] },
    topDeal: { count: 0, rows: [] },
    directDeal: { count: 0, rows: [] },
    homecards: { count: 0, rows: [] },
    testimonial: { count: 0, rows: [] },
    galleryImage: { count: 0, rows: [] },
    galleryTab: { count: 0, rows: [] },
  },
  airportCodeList: [{ rows: [], count: 0 }],
  bookedFlightDetail: {
    airlineName: [],
    data: [],
    iataAirlineCodes: [],
    airportName: [],
  }, // when user clicked on book now button
  handleBookedFlight: () => {},
  handleAirportquery: () => {},
  pagesBannersList: { count: 0, rows: [] }, // all banner for this website
  SettingList: {}, //website setting list
  airportSearchLoading: false, // loading indicator for AIRPORT NAME SEARCH
  searchpageformHandler: () => {},
  submitSearch: () => {},
  resetFlightList: () => {},
  campaignDetails: {},
});
