import React from "react";
import { Link } from "react-router-dom";

import Prefrences from "./Preferences/Prefrences";
import AccountHistory from "./AccountHistory/AccountHistory";
import Travelers from "./Travelers/Travelers";
import { USER_URL } from "./UrlList";

const User = (props) => {
  let queryId = props.match.params.id;
  let component;
  switch (queryId) {
    case "prefrences":
      component = <Prefrences {...props} />;
      break;
    case "history":
      component = <AccountHistory {...props} />;
      break;
    case "travelers":
      component = <Travelers {...props} />;
    default:
      break;
  }
  return (
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg" style={{ padding: "67px 0px " }}>
      <div class="container" style={{ minHeight: "600px" }}>
        <div class="row">
          <div class="col-md-2-5 ">
            <div class="theme-account-sidebar">
              <div class="theme-account-avatar">
                {window.user.result.userImagePath ? (
                  <img
                    class="theme-account-avatar-img"
                    src={window.user.result.userImagePath}
                    alt="Image Alternative text"
                    title="Image Title"
                    style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                  />
                ) : (
                  <i class="fa fa-user fa-3x" aria-hidden="true"></i>
                )}
                <br />
                <p class="theme-account-avatar-name">Welcome, {window.user.result.name}</p>
              </div>
              <br />
              <nav class="theme-account-nav">
                <ul class="theme-account-nav-list">
                  {USER_URL.map((el) => {
                    return (
                      <li class={`${queryId == el.url ? "active" : null}`}>
                        <Link to={`${el.url}`}>
                          <i class={`fa ${el.icon} `}></i>
                          {el.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
          {component}
        </div>
      </div>
    </div>
  );
};


export default User;
