import React, { Component } from "react";
import { useLocation } from "react-router-dom";
export default function ScrollTopOnClick(props) {
  let location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    //ga.send(["pageview", location.pathname]);
  }, [location]);

  return <></>;
}