import React from "react";

export const Error404 = () => {
  return (
    <div class="theme-hero-area theme-hero-area-full">
      <div class="theme-hero-area-bg-wrap">
        <div
          class="theme-hero-area-bg"
          style={{
            backgroundImage: "url(assets/img/1500x800.png)"
          }}
        ></div>
        <div class="theme-hero-area-mask theme-hero-area-mask-dark"></div>
        <div class="theme-hero-area-inner-shadow"></div>
      </div>
      <div class="theme-hero-area-body theme-hero-area-body-vert-center">
        <div class="container">
          <div class="theme-page-404 _ta-c">
            <h1 class="theme-page-404-title">404</h1>
            <p class="theme-page-404-subtitle">
              Sorry, we could not find that page
            </p>
            <a class="btn btn-ghost btn-xxl btn-white btn-uc" href="index.html">
              To Homepage
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
