import util from "../Utils/utils";

export default class TravellerService {
  getService(id) {
    return util
      .sendApiRequest("/traveller/certainuser/" +id, "GET", true,id)
      .then(
        response => {
          return response;
        },
        error => {
          throw new Error(error);
        }
      );
  }

  getTraveler(id) {
    return util
      .sendApiRequest("/traveller/" +id, "GET", true)
      .then(
        response => {
          return response;
        },
        error => {
          throw new Error(error);
        }
      );
  }

  editService(service) {
    return util
      .sendApiRequest("/traveller", "PUT", true, service)
      .then(
        response => {
          return response;
        },
        error => {
          throw new Error(error);
        }
      );
  }

  addService(service) {
    return util
      .sendApiRequest("/traveller", "POST", true, service)
      .then(
        response => {
          return response;
        },
        error => {
          throw new Error(error);
        }
      );
  }
  deleteService(id) {
    return util
      .sendApiRequest("/traveller/" + id, "DELETE", true)
      .then(
        response => {
          return response;
        },
        error => {
          throw new Error(error);
        }
      );
  }
}
