import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  background: none;
  color: #f27405;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 17px;
  height: 17px;
  background: ${(props) => (props.checked ? "#2dace3" : " none")};
  transition: all 150ms;
  border: ${(props) => (props.checked ? "1px solid #2dace3" : " 1px solid rgb(106, 106, 106)")};
  cursor: pointer;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

const Checkbox = ({ className, checked, ...props }) => {
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="2 -1 17 17">
          <polyline points="6 7 9 10 14 5" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};
export default Checkbox;
