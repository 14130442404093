import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getCurrency } from "../../Utils/utils";
const PhoneOnlyDealPopup = ({ title, contactNo, ...props }) => {
  const [show, setShow] = useState(
    props.hasOwnProperty("openPopup") ? props.openPopup : true
  );

  useEffect(() => {
    if (props.openPopup) {
      setShow(props.openPopup);
    }
  }, [props.openPopup]);

  const handleClose = () => {
    setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };
  // const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>*/}
      <Modal
        show={show}
        onHide={handleClose}
        role="dialog"
        dialogClassName="call-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="_p-0">
          <div className="call_modal-container">
            <div className="modal-content-box">
              <h2>{`SUPER SAVER DEAL for ${title || "your"} flights`}</h2>
              <h3 className="phone-deal-description">
                This is special unpublished fare and is available for limited
                time only.
              </h3>
              <div className="call-modal-content">
                <div className="modal-content-popup">
                  <h6 class="call-us-text phone-only-text">
                    Call our friendly and professional travel experts
                  </h6>
                  <h6 class="call-us-text-2 phone-only-text">
                    now and mention coupon code "UNPUBDEALS"
                  </h6>
                  <div className="phone d-flex phone-help">
                    <img
                      className="call-icon modal-call-icon"
                      src={"/assets/ficon/call-icon.svg"}
                      alt="call-icon"
                    />
                    <a
                      href={`tel:${contactNo}`}
                      className="call-us modal-call-us"
                    >
                      {contactNo}{" "}
                    </a>
                  </div>
                  <p className="modal-bottom-text">
                    Most of our clients save significant {getCurrency() === 'USD' ? '$$$' : '£££'}s using SUPER SAVER
                    DEAL
                  </p>
                </div>
                <div className="modal-call-center">
                  <img
                    className="phone-girl-img"
                    src={"/assets/ficon/call-center-girl.png"}
                    alt="girl-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PhoneOnlyDealPopup;
