import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const CallModal = ({ title, ...props }) => {
  const [show, setShow] = useState(true);

  // Future: Remove this if popup close doesn't scroll to top automatically.
  /*useEffect(()=>{
        window.$('.register-btn').focus();
        window.$("#toId").blur();
    },[]);*/

  // useEffect(() => {
  //   setTimeout(() => setShow(true), 5000);
  // }, []);

  const handleClose = () => {
    setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };
  // const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>*/}

      <Modal
        show={show}
        onHide={handleClose}
        role="dialog"
        dialogClassName="call-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="_p-0">
          <div className="call_modal-container">
            <div className="modal-content-box">
              <h2>{`Save More on ${title || "your"} flights`}</h2>
              <div className="call-modal-content">
                <div className="modal-content-popup">
                  <h4 className="call-us-text">Call us to get</h4>
                  <h4 className="call-us-text-2">deals cheaper than online</h4>
                  <div className="phone d-flex phone-help">
                    <img
                      className="call-icon modal-call-icon"
                      src={"/assets/ficon/call-icon.svg"}
                      alt="call-icon"
                    />
                    <a
                      href={`tel:${props.phoneNo}`}
                      className="call-us modal-call-us"
                    >
                      {props.phoneNo}
                    </a>
                  </div>
                  <p className="modal-bottom-text">
                    All special negotiated deals can only be sold on phone.
                    These can't be displayed online due to airline restrictions.
                    Call us now to save!
                  </p>
                </div>
                <div className="modal-call-center">
                  <img
                    className="phone-girl-img"
                    src={"/assets/ficon/images/contact-girl.png"}
                    ailt="girl-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CallModal;
