import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import ReactDOM from "react-dom";

const errorIcon = (
  <svg
    className="notification-icon notification-error-icon"
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
  </svg>
);
const successIcon = (
  <svg
    className="notification-icon notification-success-icon"
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
  </svg>
);

const NotificationModal = ({ type, msg, onClose }) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    ReactDOM.unmountComponentAtNode(
      document.getElementById("notification-modal")
    );
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return type === "error" ? (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      dialogClassName="notification-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <div className="modal-title">
            <p>{errorIcon}</p>
            <span>Alert</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="_p-0">
        <div className="notification-content">
          <p>{ReactHtmlParser(msg && msg.split('"').join(""))}</p>
          <button onClick={handleClose} className="notification-button">
            {" "}
            Ok{" "}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  ) : (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      dialogClassName="notification-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {" "}
          <div className="modal-title">
            <p>{successIcon}</p>
            <span>Success</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="_p-0">
        <div className="notification-content">
          <p>{ReactHtmlParser(msg)}</p>
          <button onClick={handleClose} className="notification-button">
            {" "}
            Ok{" "}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export const notification = (type, msg, onClose) => {
  ReactDOM.render(
    <NotificationModal type={type} msg={msg} onClose={onClose} />,
    document.getElementById("notification-modal")
  );
};

export default NotificationModal;
