import React, { useState } from "react";
import styled from "styled-components";
import { Formik, ErrorMessage } from "formik";

const PassengersNumberInput = ({ value, min, max, disablePlus, changeEvent, ...props }) => {
  const [count, setcount] = useState(0);
  function decrease() {
    if (value > min) {
      value--;
      changeEvent(value);
    }
  }

  function increase() {
    if (value < max) {
      value++;
      changeEvent(value);
    }
  }
  return (
    <Formik>
      {({ initialValues, errors, handleChange, validationSchema }) => (
        <>
          <div className="def-number-input number-input">
            <button type="button" onClick={decrease} className="minus"></button>
            <input
              className="quantity"
              style={{ outline: "none" }}
              readOnly
              onChange={() => {}}
              name="quantity"
              value={value}
              type="number"
            />
            <button type="button" disabled={disablePlus} onClick={increase} className="plus"></button>
          </div>
          {/* <ErrorMessage>{(msg) => <div>{msg}</div>}</ErrorMessage> */}
        </>
      )}
    </Formik>
  );
};

export default PassengersNumberInput;
