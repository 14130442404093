import React, { useContext, useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import LoginContext from "../../Context/Context";
import { Login } from "../../DefaultComponent/Login";
import { Register } from "../../DefaultComponent/SignUp";
import TopHeaderNofication from "../../Shared/TopHeaderNotification";

const Header = (props) => {
  const context = useContext(LoginContext);
  const [bgColor, setBgColor] = useState(false);
  const [isLoggedIn, setIsLogin] = useState(
    context.isLogin || !!(window && window.user)
  );

  /*   const navRef = React.useRef()
  navRef.current = bgColor */
  const loginStyle = (login) => {
    return { display: `${!!login ? "block" : "none"}` };
  };
  const handleLogout = () => {
    localStorage.removeItem("user");
    window.user = null;
    context.logoutHandler(false);
    props.history.push("/");
  };
  // useEffect(() => {
  //   window.scroll(0, 0);
  //   async function fetchData() {
  //     window.scroll(0, 0);
  //     const settingResult = await settingServ.listSetting({ "": "" }, 0, 1000).then((result) => {
  //       let setting = Setting;
  //       setting.count = result.count;
  //       for (let i in result.rows) {
  //         if (result.rows[i].is_file === true) {
  //           setting.values[result.rows[i].name] = result.rows[i].logoImagePath;
  //         } else {
  //           setting.values[result.rows[i].name] = result.rows[i].value;
  //         }
  //       }
  //       result = setting;
  //       if (result && result.count) {
  //         setSetting(result);
  //       } else {
  //         setSetting({});
  //       }
  //     });
  //   }
  //   fetchData();
  // }, []);
  let hederStyle = {
    position: "absolute",
    top: "0px",
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset >= 15) {
      setBgColor(true);
    } else {
      setBgColor(false);
    }
  };

  useEffect(() => {
    setIsLogin(context.isLogin || !!(window && window.user));
  }, [context.isLogin, !!(window && window.user)]);

  return (
    <>
      {/* navbar-theme-transparent */}
      <nav
        className={`navbar navbar-default navbar-inverse navbar-theme ${props.show == false ? "navbar-theme-abs" : "navbar-theme-rel"
          }  navbar-theme-border ${bgColor ? "navSolidColor" : "navBgColor"} ${props.location.pathname == "/user/prefrences" ||
            props.location.pathname == "/user/history" ||
            props.location.pathname == "/user/travelers" ||
            props.location.pathname == "/paymentcheckout" ||
            props.location.pathname == "/flightdetails" ||
            props.location.pathname == "/googleresults" ||
            props.location.pathname == "/traveler/add" ||
            props.location.pathname == "/flightDetails" ||
            props.location.pathname == "/search" ||
            props.location.pathname == "/thankyou" ||
            props.location.pathname == "/contact" ||
            props.location.pathname == "/pg/about_us" ||
            props.location.pathname == "/pg/privacy_policy" ||
            props.location.pathname == "/pg/cookies_policy" ||
            props.location.pathname == "/pg/terms_conditions" ||
            props.location.pathname == "/pg/fly_now_pay_later" ||
            props.location.pathname == "/pg/payment_policy" ||
            props.location.pathname == "/faq" ||
            props.location.pathname.includes("/book") ||
            props.location.pathname.includes("/pay-online") ||
            props.location.pathname.includes("bookingflightdetail")
            ? "navbar-theme-solidcolor"
            : "navbar-theme-transparent"
          }`}
        id="main-nav"
      // style={props.message == null && props.show == false ? hederStyle : { position: "relative", top: "49px" }}
      >
        {/* <TopHeaderNofication
          title={context.SettingList && context.SettingList.header_notification}
        /> */}
        <div className="container">
          <div className="navbar-inner nav">
            <div className="navbar-header">
              {/* <button
                  className="navbar-toggle collapsed"
                  data-toggle="collapse"
                  data-target="#navbar-main"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" ></span>
                  <span className="icon-bar" ></span>
                </button> */}
              <Link to="/" className="brand-logo">
                {" "}
                <img
                  // src={Setting.values.main_logo}
                  src={context.SettingList && context.SettingList.main_logo}
                  alt="Flights Travel"
                  title="Flights Travel"
                /* style={props.location.pathname !== "/" ? { height: "3rem", marginTop: "-4px" } : null} */
                />
              </Link>
            </div>
            <div className="center_icons">
              <img src="/assets/ficon/atol_logo1.png" className="atol-logo" alt="no-fee" />
              <a
                href="https://uk.trustpilot.com/review/flightstravel.co.uk"
                target="_blank"
              >
                <img src="/assets/ficon/trustpilot-logo.png" alt="no-fee"></img>
              </a>
            </div>

            {/* <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="/pg/terms_conditions">Terms And Conditions</Link>
              </li>
              <li>
                <Link to="/faq">Faq</Link>
              </li>
            </ul> */}
            {/* <ul className="nav navbar-nav">
              <li className="dropdown">
                <Link to="/">Flight</Link>
                 <a
                  className="dropdown-toggle"
                  href="exp-index-1.html"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Flight
                </a>
              </li>
            </ul>*/}

            <ul className="nav navbar-nav navbar-right">
              <li key="phone-item" className="navbar-nav-item-phone">
                <a
                  href={`tel:${context.SettingList.contact_phone}`}
                  aria-label="Contact Phone"
                >
                  <i
                    className="fa fa-phone navbar-nav-item-phone-icon"
                    style={{ cursor: "pointer" }}
                  ></i>
                  <span className="phone-number-text">
                    {context.SettingList.contact_phone}
                  </span>
                </a>
                {/* <Link to="/call">call</Link> */}
              </li>
              {/* <li
                key={"account-btn"}
                className={"navbar-nav-item-user dropdown"}
              >
                {isLoggedIn ? (
                  <>
                    <a
                      className="dropdown-toggle"
                      href="account.html"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="true"
                      key="account"
                    >
                      <i className="fa fa-user-circle-o navbar-nav-item-user-icon"></i>
                      <span className="header-my-account">My Account</span>
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link to="/user/prefrences">Preferences</Link>
                      </li>
                      <li>
                        <Link to="/user/travelers">Travellers</Link>
                      </li>
                      <li>
                        <Link to="/user/history">History</Link>
                      </li>
                      <li>
                        <a style={{ cursor: "pointer" }} onClick={handleLogout}>
                          Logout
                        </a>
                      </li>
                    </ul>
                  </>
                ) : (
                  <Login type="login" />
                )}
              </li>
              {!isLoggedIn && (
                <li key={"signup-btn"} className="navbar-nav-item-register">
                  <Register type="signup" />
                </li>
              )} */}
            </ul>
          </div>
          <div className="center_icons_mobile">
            <img src="/assets/ficon/atol_logo1.png" alt="no-fee" />
            <a
              href="https://uk.trustpilot.com/review/flightstravel.co.uk"
              target="_blank"
            >
              <img src="/assets/ficon/trustpilot-logo.png" alt="no-fee"></img>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
};
export default withRouter(Header);
