import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const ArrowTooltips = (props) => {
    // return props.children
    return (
        <Tooltip placement="top" title={props.text} arrow>
            {props.children}
         </Tooltip>
    );
}

export default ArrowTooltips;