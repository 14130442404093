import React, { useContext, useState } from "react";
import Delayed from "react-delayed";
import { withRouter } from "react-router-dom";
import Context from "../../Context/Context";
import PhoneOnlyDealPopup from "../Popup/PhoneOnlyDealPopup";
import StaticPopup from "../Popup/StaticPopup";
import { getAirlineName as stripAirlineName } from "./../../Helper/helper";
import RoundFlightItem from "./New/Components/RoundFlightItem";

const RoundTrip = ({ getAirlineName, getAirportName, ...props }) => {
  const [openPopup, setOpenPopup] = useState(false);
  let BookedContext = useContext(Context);

  const checkAdultFare = (price) => {
    if (price) {
      return price.AdultFares && price.AdultFares.perAdultFare
        ? price.AdultFares.perAdultFare
        : price.totalFare && price.totalFare.amount;
    }

    return 0;
  };
 const checkCurrency = (price) => {
    if (price) {
        return price.totalFare && price.totalFare.currency === 'USD' ? '$':'£';
      }
      return "£"
  };
  const handleBookButton = (e, el) => {
    BookedContext.isFromFlightDetails(true)
    BookedContext.handleBookedFlight({
      data: [el],
      iataAirlineCodes: props.flightLists.iataAirlineCodes,
      airlineName: props.airlineName,
      airportName: props.airportName,
    });
    e.stopPropagation();
  };

  return (
    <>
      <PhoneOnlyDealPopup
        title={stripAirlineName(BookedContext.searchReasult.to)}
        contactNo={BookedContext.SettingList.contact_phone}
        openPopup={openPopup}
        handleClose={() => setOpenPopup(false)}
      />
      <div id="flight-div" className="theme-search-results">
        {props.flightLists.data &&
          props.flightLists.data.map((el, i) => {
            let totalLabel;
            if (el.AdultFares.FARE === undefined) {
              totalLabel = "Per Person";
            } else {
              totalLabel = "Total Fare";
            }
            return (
              <Delayed mounted={true} mountAfter={300} unmountAfter={500}>
                <>
                  <RoundFlightItem
                    {...props}
                    {...el}
                    utm_campaign={BookedContext.searchReasult.utm_campaign}
                    isCallNow={BookedContext.campaignDetails.is_callNow}
                    contactNumber={BookedContext.SettingList.contact_phone}
                    dataLength={props.flightLists?.data?.length}
                    getAirlineName={getAirlineName}
                    totalLabel={totalLabel}
                    airportName={props.airportName}
                    getAirportName={getAirportName}
                    checkAdultFare={checkAdultFare}
                    checkCurrency={checkCurrency}
                    setOpenPopup={setOpenPopup}
                    openPopup={openPopup}
                    handleBookButton={handleBookButton}
                    index={i}
                  />
                  {(i + 1) % 3 === 0 ? (
                    <StaticPopup
                      title={BookedContext.SettingList.flight_detail_save_strip}
                      contactNo={BookedContext.SettingList.contact_phone}
                    />
                  ) : null}
                </>
              </Delayed>
            );
          })}
        {/* </DelayedList> */}
      </div>
    </>
  );
};

export default withRouter(RoundTrip);
