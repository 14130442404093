import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { NAMES } from "../constants";

const useFlightsToast = ({ flights = [], setting = {}, toFromData = {} }) => {
  const [showToast, setShowToast] = useState(true);

  const initializeToast = () => {
    setShowToast(false); //
    if (setting?.flight_search_popup1) {
      const str1 =
        (setting.flight_search_popup1 || "")
          .replace("John", NAMES[Math.floor(Math.random() * 14) + 1])
          ?.replace("flight", `${toFromData.to?.cityName} flight`) || "";

      // setTimeout(
      //   () =>
      //     toast(str1, {
      //       position: "bottom-left",
      //       autoClose: 15000,
      //       hideProgressBar: true,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: false,
      //       bodyClassName: "font-black-toast",
      //     }),
      //   10000
      // );

      // setTimeout(
      //   () =>
      //     toast(setting.flight_search_popup2, {
      //       position: "bottom-left",
      //       autoClose: 15000,
      //       hideProgressBar: true,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: false,
      //       bodyClassName: "font-black-toast",
      //     }),
      //   15000
      // );
    }
  };

  useEffect(() => {
    if (flights?.length && showToast) {
      initializeToast();
    }
  }, [flights]);
};

export default useFlightsToast;
