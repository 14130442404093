import React, { useContext, useState } from "react";
import { TripSummary } from "../FlightDetails/TripSummary";
import PassengerDetail from "./PassengerDetail";
import { Redirect } from "react-router-dom";

import Context from "../../Context/Context";
import TravellerDetail from "./travellerDetail";

const PaymentCheckout = (props) => {
  const bookedContext = useContext(Context);
  return (
    <>
            <div class="flight-pax">
              <h4 className="traveller-detail-heading"> Traveller's detail</h4>
              <div class="card passenger-detail-card">
                <div class="card-body">
                  <div class="ng-pristine ng-invalid ng-touched">
                    <div formarrayname="passengers" class="ng-untouched ng-pristine ng-invalid">
                      {/* <PassengerDetail {...props} /> */}
                      <TravellerDetail />
                    </div>
                  </div>
                </div>
              </div>
              <div id="paySection" />
            </div>
{/*           <div class="col-sm-4">
            <TripSummary />
          </div> */}
    </>
  );
};
export default PaymentCheckout;
