import React from "react";
import { Modal, Button } from "react-bootstrap";

const RefreshModal = ({ show, onRefresh }) => {
  
  return (
    <>
      <Modal
        show={show}
        onHide={null}
        backdrop="static"
        keyboard={false}
        role="dialog"
        dialogClassName="call-modal refresh-modal"
      >
        <Modal.Header closeButton={false}></Modal.Header>
        <Modal.Body className="_p-0">
          <div className="call_modal-container">
            <div className="modal-content-box">
              <h2>Refresh your results</h2>
              <div className="">
                <div className="modal-content-popup">
                  <p className="modal-bottom-text">
                  Please note, prices and availability changes frequently due to demand. We want to make sure you always see the best prices.
                  </p>
                </div>
                <div className="modal-call-center">
                  <Button block color="primary" className="btn-square" onClick={onRefresh}>Refresh Now</Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RefreshModal;
