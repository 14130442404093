import util from "../Utils/utils";

export default class FooterService {
  listMenus(data, start, perpage) {
    return util
      .sendApiRequest("/menu/list/" + 0 + "/" + 1000, "POST", true)
      .then(response => {
        return response;
      })
      .catch(err => {
        throw err;
      });
  }
  listMenuLink() {
    return util
      .sendApiRequest("/menu/link/list/" + 0 + "/" + 1000, "POST", true)
      .then(response => {
        return response;
      })
      .catch(err => {
        throw err;
      });
  }
  newsLetterAPI(body) {
    return util
        .sendApiRequest("/email-subscription", "POST", true, body)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
  }
}
