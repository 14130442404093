import util from "../Utils/utils";

export default class UserService {
  login(login) {
    return util
      .sendApiRequest("/user/login", "POST", true, login)
      .then(response => {
        return response;
      })
      .catch(err => {
        throw err;
      });
  }

  logout() {
    localStorage.clear();
    window.user = null;
  }
  editUser(user, newPassword, verifyPassword) {
    return util
      .sendApiRequest("/user", "PUT", true, user, newPassword, verifyPassword)
      .then(
        response => {
          return response;
        },
        error => {
          throw new Error(error);
        }
      );
  }
  addUser(user) {
    return util
      .sendApiRequest("/user", "POST", true, user)
      .then(response => {
        return response;
      })
      .catch(err => {
        throw err;
      });
  }
  deleteUser(_id) {
    let id = { _id };
    return util.sendApiRequest("/user/delete", "POST", true, id).then(
      response => {
        return response;
      },
      error => {
        throw new Error(error);
      }
    );
  }
  reset_password(data) {
    return util.sendApiRequest("/user/resetpassword/", "POST", true, data).then(
      response => {
        return response;
      },
      error => {
        throw error;
      }
    );
  }
  forget_password(email) {
    return util
      .sendApiRequest("/user/forgotpassword", "POST", true, email)
      .then(
        response => {
          return response;
        },
        error => {
          throw error;
        }
      );
  }
  changePassword(data) {
    return util.sendApiRequest("/user/changepassword", "POST", true, data).then(
      response => {
        return response;
      },
      error => {
        throw error;
      }
    );
  }
}
