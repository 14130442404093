import React from 'react'

const BaggageNotification = ({
    baggageNoti,
    addBaggage,
    onClose,
    marginTop
}) => {
    return (
        <>
            {baggageNoti ?
            <div className={`baggage-notification ${marginTop ? "_mt-10" : ""}`}>
                <img src="/assets/img/info.png" />
                <div className="baggage-add-remove-text">{`Baggage ${addBaggage ? "Added" : "Removed"}`}</div>
                <i className="fa fa-close baggage-remove" onClick={() => onClose()} />
            </div> : ""}
        </>
    )
}

export default BaggageNotification