import React from 'react'

const Baggage = ({
    bagge
}) => {

    let result = bagge === 0 ? "Hand Luggage Only" : (bagge > 0) ? `Baggage ${bagge} ${window.innerWidth > 600 ? "Piece(s)" : "Pc(s)"}` : bagge ? `Baggage ${bagge}` : ""
    return (
        <div className='baggage'>
            <span className='baggage-details'>
                <i className="fa fa-suitcase baggage-text" aria-hidden="true" />
                {result}
            </span>
        </div>
    )
}

export default Baggage
