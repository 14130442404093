import React, {useContext} from "react";
import LoginContext from "../../Context/Context";

const PayOnlineSuccess = () => {
  const context = useContext(LoginContext);
  return (
    <div class="theme-page-section theme-page-section-xl theme-page-section-gray">
      <div class="container">
        <div class="row">
          <div style={{ width: '100%' }}>
            <div class="theme-payment-success">
              <div class="theme-payment-success-header">
                <i class="fa fa-times-circle theme-payment-success-header-icon"></i>
                <h1 class="theme-payment-success-title">Payment Failed</h1>
                <p>{`Please contact our customer service at ${context.SettingList.contact_phone} for further assistance.`}</p>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PayOnlineSuccess;
