import qs from "qs";
import React, { memo } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import Checkbox from "../../../../DefaultComponent/CheckBox";
import { CABIN_OPTIONS, HALT_DURATIONS } from "./../constants";
import { getCurrency } from "../../../../Utils/utils";
const FilterSidebar = ({
  filterOptions = {},
  filterSelected = {},
  airlineNames = [],
  onFiltersChange,
  searchResult
}) => {
  // const [filtered, setFiltered] = useState(false)
  const location = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(location.search);

  const { price, stops } = filterOptions;

  const handleCheck = async (e) => {
    const { name, value, checked } = e.target;
    let formObj = {};
    if (name === "stops") {
      if (checked) {
        // push selected value in list
        formObj = { ...filterSelected };
        formObj[name].push(value);
      } else {
        // remove unchecked value from the list
        formObj = {
          ...filterSelected,
          [name]: filterSelected[name].filter((x) => {
            return x !== value;
          }),
        };
      }
      window.history.pushState(
        {},
        document.title,
        `/search?${qs.stringify(searchResult)}`
      );

      onFiltersChange(formObj, name);
    } else if (name === "airlines") {
      if (checked) {
        // push selected value in list
        formObj = { ...filterSelected };
        formObj[name].push(value);
      } else {
        // remove unchecked value from the list
        formObj = {
          ...filterSelected,
          [name]: filterSelected[name].filter((x) => {
            return x !== value;
          }),
        };
      }
      window.history.pushState(
        {},
        document.title,
        `/search?${qs.stringify(searchResult)}`
      );

      onFiltersChange(formObj, name);
    } else if (name === "cabin") {
      if (checked) {
        // push selected value in list
        formObj = {
          ...filterSelected,
          [name]: value,
        };
        let obj = {};
        for (let p of urlParams) {
          obj[p[0]] = p[1];
        }
        if (window.location.pathname.includes("book/")) {
          obj = searchResult;
        }
        const str = qs.stringify({ ...searchResult, ...obj, cabin: value });
        window.location.href = `/search?${str}`;
      }
    } else if (name === "halts") {
      if (checked) {
        // push selected value in list
        formObj = { ...filterSelected };
        formObj[name].push(value);
      } else {
        // remove unchecked value from the list
        formObj = {
          ...filterSelected,
          [name]: filterSelected[name].filter((x) => {
            return x !== value;
          }),
        };
      }
      window.history.pushState(
        {},
        document.title,
        `/search?${qs.stringify(searchResult)}`
      );
      onFiltersChange(formObj, name);
    }
  };

  const clearFilter = (filterType) => {
    let newFilters = {};
    switch (filterType) {
      case "all": {
        newFilters = {
          price: { min: price.min, max: price.max },
          stops: [],
          time: { inbound: { max: 24, min: 0 }, outbound: { max: 24, min: 0 } },
          airlines: [],
          halts: [],
          cabin: CABIN_OPTIONS[0].value,
        };
        var slider = window.$("#price-slider").data("ionRangeSlider");
        if (slider) {
          slider.update({
            min: price.min,
            max: price.max,
            from: price.min,
            to: price.max,
          });
        }
        onFiltersChange(newFilters);
        let obj = {};
        for (let p of urlParams) {
          obj[p[0]] = p[1];
        }
        if (window.location.pathname.includes("book/")) {
          obj = searchResult;
        }
        // No need to refresh page while only clearing filters except cabin
        if (filterSelected.cabin != CABIN_OPTIONS[0].value) {
          const str = qs.stringify({ ...searchResult, ...obj, cabin: CABIN_OPTIONS[0].value });
          window.location.replace(`/search?${str}`);
          // history.replace({ pathname: location.pathname, search: str });
        }
        break;
      }
      case "stops": {
        newFilters = {
          ...filterSelected,
          stops: [],
        };
        onFiltersChange(newFilters);
        break;
      }
      case "price": {
        newFilters = {
          ...filterSelected,
          price: { min: price.min, max: price.min },
        };
        onFiltersChange(newFilters);
        break;
      }
      case "outbound": {
        filterSelected.time.outbound = { max: 24, min: 0 };
        newFilters = { ...filterSelected };
        onFiltersChange(newFilters);
        break;
      }
      case "inbound": {
        newFilters = filterSelected;
        newFilters.time.inbound = { max: 24, min: 0 };
        onFiltersChange(newFilters);
        break;
      }
      case "airlines": {
        newFilters = {
          ...filterSelected,
          airlines: [],
        };
        onFiltersChange(newFilters);
        break;
      }
      case "halts": {
        newFilters = {
          ...filterSelected,
          halts: [],
        };
        onFiltersChange(newFilters);
        break;
      }
    }
  };

  const selectAllFilter = (filterType) => {
    const airlines = airlineNames.reduce((acc, curr) => {
      acc.push(curr.iata);
      return acc;
    }, []);

    switch (filterType) {
      case "all": {
        const newFilters = {
          ...filterSelected,
          price: { min: price?.min, max: price?.max },
          stops: ["1stop", "nonstop"],
          halts: [],
          time: { inbound: { max: 24, min: 0 }, outbound: { max: 24, min: 0 } },
          airlines,
        };
        onFiltersChange(newFilters, filterType);
        return;
      }
      case "airlines": {
        if (filterSelected.airlines.length !== airlines.length) {
          const newFilters = {
            ...filterSelected,
            airlines,
          };
          onFiltersChange(newFilters, filterType);
        }
        return;
      }
    }
  };

  // useEffect(() => {
  //   if (!isSameObj(filterSelected, filtersObj)) {
  //     setFiltersObj(filterSelected);
  //   }
  // }, [filterSelected]);

  // useEffect(() => {
  //   if (urlParams?.get('isDirect') === "true" && flightList?.data?.length && !filtered) {
  //     setFiltered(true)
  //     const newData = {
  //       ...filterSelected,
  //       stops: ["nonstop"],
  //     };
  //     onFiltersChange(newData, "nonstop")
  //   }
  // }, [urlParams?.get('isDirect'), flightList?.data?.length])
  return (
    <>
      <div id="sidebar" className="custom-sidebar">
        <div className="custom-side mob-custom-sidebar">
          <div className="custom-side-content">
            <p className="custom-side-title">Filters</p>
            <span
              className="header-block filters-clear-btn"
              onClick={() => clearFilter("all")}
            >
              Clear All
            </span>
            <div className="theme-search-results-sidebar">
              <div className="theme-search-results-sidebar-sections _mb-20 _br-2">
                <div className="theme-search-results-sidebar-section">
                  <h5 className="theme-search-results-sidebar-section-title filter-title-color">
                    Stops
                  </h5>
                  <div className="theme-search-results-sidebar-section-checkbox-list">
                    <div className="theme-search-results-sidebar-section-checkbox-list-items">
                      {stops?.map((el) => {
                        return (
                          <div className="theme-search-results-sidebar-section-checkbox-list-item">
                            <div>
                              <Row>
                                <Col md={10} className="col-xs-10">
                                  <label>
                                    <Checkbox
                                      className="checkbox-input"
                                      name="stops"
                                      onChange={handleCheck}
                                      value={el.value}
                                      checked={filterSelected.stops?.includes(
                                        el.value
                                      )}
                                    />
                                    <span
                                      style={{
                                        marginLeft: 8,
                                        fontWeight: "normal",
                                      }}
                                    >
                                      {el.label}
                                    </span>
                                  </label>
                                </Col>
                                <Col md={2} className="col-xs-2">
                                  <span
                                    style={{
                                      float: "right",
                                      color: "#333",
                                      opacity: 0.65,
                                      fontSize: "11px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    {getCurrency() === 'USD' ? '$' : '£'}{Math.floor(el.minprice)}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="theme-search-results-sidebar-section">
                  <h5 className="theme-search-results-sidebar-section-title filter-title-color">
                    Price
                  </h5>
                  <div className="theme-search-results-sidebar-section-price">
                    <in
                      id="price-slider"
                      name="price-sliderr"
                      data-min={price.min}
                      data-max={price.max}
                      type="text"
                    />
                  </div>
                </div>

                <div className="theme-search-results-sidebar-section">
                  <h5 className="theme-search-results-sidebar-section-title filter-title-color">
                    Outbound Departure
                  </h5>
                  <div className="theme-search-results-sidebar-section-price">
                    <input
                      id="OUTBOUND-slider"
                      name="price-sliderr"
                      data-min="100"
                      data-max="500"
                      type="text"
                    />
                  </div>
                </div>
                <div className="theme-search-results-sidebar-section">
                  <h5 className="theme-search-results-sidebar-section-title filter-title-color">
                    Inbound Departure
                  </h5>
                  <div className="theme-search-results-sidebar-section-price">
                    <input
                      id="INBOUND-slider"
                      name="price-sliderr"
                      data-min="100"
                      data-max="500"
                      type="text"
                    />
                  </div>
                </div>
                <div className="theme-search-results-sidebar-section">
                  <h5 className="theme-search-results-sidebar-section-title filter-title-color">
                    Connection Length
                  </h5>
                  <div className="theme-search-results-sidebar-section-checkbox-list">
                    <div className="theme-search-results-sidebar-section-checkbox-list-items">
                      {Object.entries(HALT_DURATIONS).map(([key, value]) => {
                        return (
                          <div className=" theme-search-results-sidebar-section-checkbox-list-item">
                            <div>
                              <Row>
                                <Col md={12} className="col-xs-12">
                                  <label>
                                    <Checkbox
                                      className="checkbox-input"
                                      onChange={handleCheck}
                                      checked={filterSelected.halts?.includes(
                                        key
                                      )}
                                      value={key}
                                      name="halts"
                                    />
                                    <span
                                      style={{
                                        marginLeft: 8,
                                        fontWeight: "400",
                                      }}
                                    >
                                      {value.label} {value.sublabel}
                                    </span>
                                  </label>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <span
                    className="filters-clear-btn"
                    onClick={() => clearFilter("halts")}
                  >
                    Clear All
                  </span>
                </div>
                <div className="theme-search-results-sidebar-section">
                  <h5 className="theme-search-results-sidebar-section-title filter-title-color">
                    Cabins
                  </h5>
                  <div className="theme-search-results-sidebar-section-checkbox-list">
                    <div className="theme-search-results-sidebar-section-checkbox-list-items">
                      {CABIN_OPTIONS.map((el) => {
                        return (
                          <div className=" theme-search-results-sidebar-section-checkbox-list-item">
                            <div>
                              <Row>
                                <Col md={10} className="col-xs-10">
                                  <label>
                                    <Checkbox
                                      className="checkbox-input"
                                      onChange={
                                        Number(filterSelected.cabin) === Number(el.value)
                                          ? () => false
                                          : handleCheck
                                      }
                                      checked={Number(filterSelected.cabin) === Number(el.value)}
                                      value={el.value}
                                      label={el.label}
                                      name="cabin"
                                    />
                                    <span
                                      style={{
                                        marginLeft: 8,
                                        fontWeight: "400",
                                      }}
                                    >
                                      {el.label}
                                    </span>
                                  </label>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="theme-search-results-sidebar-section last">
                  <h5 className="theme-search-results-sidebar-section-title filter-title-color">
                    Airlines
                  </h5>
                  <div className="theme-search-results-sidebar-section-checkbox-list">
                    <div className="theme-search-results-sidebar-section-checkbox-list-items">
                      {airlineNames.map((el) => {
                        return (
                          <div className=" theme-search-results-sidebar-section-checkbox-list-item">
                            <div>
                              <Row>
                                <Col md={10} className="col-xs-10">
                                  <label>
                                    <Checkbox
                                      className="checkbox-input"
                                      onChange={handleCheck}
                                      checked={filterSelected.airlines?.includes(
                                        el.iata
                                      )}
                                      value={el.iata}
                                      name="airlines"
                                    />
                                    <span
                                      style={{
                                        marginLeft: 8,
                                        fontWeight: "400",
                                      }}
                                    >
                                      {el.name}
                                      {/* {el.name.replace("Airlines", "")} */}
                                    </span>
                                  </label>
                                </Col>
                                <Col md={2} className="col-xs-2">
                                  <span
                                    style={{
                                      fontWeight: "normal",
                                      float: "right",
                                      color: "#333",
                                      opacity: 0.65,
                                      fontSize: "11px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    {getCurrency() === 'USD' ? '$' : '£'}{Math.floor(el.minprice)}
                                  </span>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <span
                    className="filters-clear-btn"
                    onClick={() => clearFilter("airlines")}
                  >
                    Clear All
                  </span>
                  <span
                    className="filters-clear-btn"
                    style={{ right: 60 }}
                    onClick={() => selectAllFilter("airlines")}
                  >
                    Select All
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky-filter-button visible-sm-block visible-xs-block">
            <button
              className="btn btn-lg btn-shadow filter-button"
              onClick={() => {
                window.$("#sidebar").toggle();
                /* window.$(document.body).toggleClass('overflow-hidden', false) */
              }}
            >
              {" "}
              Apply Filters{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(withRouter(FilterSidebar));
