import React, { memo } from "react";

const BottomStaticCard = (props) => {
  return (
    <>
      <div
        className="popup-card static-card"
        style={{
          border: props.coloredCard
            ? `3px solid ${props.coloredCard}`
            : "1px solid #2dace3",
        }}
      >
        <div
          className="card-body text-primary"
          style={{ padding: "4.25rem 2.25rem" }}
        >
          <h5 className="card-title">Still looking for right flight ?</h5>
          <div className="bottom-static-card-content">
            <div className="inner-card-content">
              <p
                className="card-text mob-card-text"
                dangerouslySetInnerHTML={{ __html: props.couponCodeText }}
              ></p>
              <div className="phone d-flex">
                <img
                  className="call-icon card-call-icon"
                  src={"/assets/ficon/call-icon.svg"}
                  alt="call-icon"
                />
                <div className="_ml-10">
                  <div>
                    <a href={`tel:${props.contactNo}`} className="call-us">
                      {props.contactNo}
                    </a>
                  </div>
                  <p className="call-below-text">
                    Call us on now to grab a SUPER SAVER DEAL.
                  </p>
                </div>
              </div>
            </div>
            <div className="inner-contact-image">
              {props.couponPopup && (
                <div className="coupon-container">
                  <h3>Use Coupon Code</h3>
                  <h2>{props.couponCode}</h2>
                  <div className="floating-div">
                    {props.couponCodeBottomText}
                  </div>
                </div>
              )}
              <img
                className="contact-girl-img"
                src={"/assets/ficon/images/contact-girl.png"}
                alt="call-icon"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default memo(BottomStaticCard);
